import { Storage } from '@lightningjs/sdk';
import AuthenticationSingleton from '../authentication/Authentication';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
import { BRAND, STORAGE_KEYS } from '../constants';
import { getBrandName, getDefaultChannelId, getLiveCallSignByStreamAccessName } from '../helpers';
import PlayerStoreSingleton from '../store/PlayerStore/PlayerStore';
import { setEpgChannel } from '../store/PlayerStore/actions/epg';
import { CHANNEL_IDS } from '../api/Live';
class LiveStreamManagerClass {
    constructor() {
        this._channelId = '';
        this._accessName = '';
        this._callSign = '';
    }
    get saveAllowed() {
        var _a, _b, _c;
        return (_c = (_b = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.live) === null || _b === void 0 ? void 0 : _b.savePreviousSelection) === null || _c === void 0 ? void 0 : _c.enabled;
    }
    get() {
        var _a, _b, _c;
        let channelId = this._channelId;
        let accessName = this._accessName;
        let callSign = this._callSign;
        const isMvpdLinked = AuthenticationSingleton.isAuthenticated();
        const liveConfig = (_a = AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.live;
        const defaultStreamAllowed = (_b = liveConfig === null || liveConfig === void 0 ? void 0 : liveConfig.defaultStream) === null || _b === void 0 ? void 0 : _b.enabled;
        const defaultStreamAccessName = (_c = liveConfig === null || liveConfig === void 0 ? void 0 : liveConfig.defaultStream) === null || _c === void 0 ? void 0 : _c.streamAccessName;
        const defaultCallSign = getLiveCallSignByStreamAccessName(defaultStreamAccessName);
        if ((!this._channelId || this._channelId === CHANNEL_IDS.nbcnews) && this.saveAllowed) {
            channelId = Storage.get(STORAGE_KEYS.PREVIOUS_CHANNEL);
            if (!channelId && defaultStreamAllowed) {
                // If mvpd is linked use the app brand as the default stream.
                // Set to default channelId
                channelId = isMvpdLinked ? getDefaultChannelId() : defaultStreamAccessName;
            }
        }
        if (!this._accessName && this.saveAllowed) {
            accessName = Storage.get(STORAGE_KEYS.PREVIOUS_STREAM);
            if (!accessName && defaultStreamAllowed) {
                accessName = isMvpdLinked ? '' : defaultStreamAccessName;
            }
        }
        if (!this._callSign && this.saveAllowed) {
            callSign = Storage.get(STORAGE_KEYS.PREVIOUS_CALLSIGN);
            if (!callSign && defaultStreamAllowed) {
                callSign = isMvpdLinked
                    ? (accessName && getLiveCallSignByStreamAccessName(accessName)) || ''
                    : defaultCallSign;
            }
        }
        return {
            channelId: channelId || this._channelId || '',
            accessName: this._accessName || accessName || '',
            callSign: callSign || this._callSign,
            isGlobalNavigation: this._isGlobalNavigation,
        };
    }
    set(channelId, accessName, callSign) {
        this._channelId = channelId;
        this._accessName = accessName;
        this._callSign = callSign;
        if (this.saveAllowed) {
            Storage.set(STORAGE_KEYS.PREVIOUS_CHANNEL, channelId);
            Storage.set(STORAGE_KEYS.PREVIOUS_STREAM, accessName);
            Storage.set(STORAGE_KEYS.PREVIOUS_CALLSIGN, callSign);
        }
    }
    async update(retry = true) {
        const { channelId, accessName } = this.get();
        try {
            return await PlayerStoreSingleton.dispatch(setEpgChannel(channelId, accessName));
        }
        catch (_a) {
            if (retry) {
                this.reset();
                return this.update(false);
            }
        }
        return true;
    }
    reset() {
        // Hardcode NBC Sports Now for the NBC Sports App and NBC News Now for other apps.
        this._channelId = getBrandName() !== BRAND.nbcsports
            ? CHANNEL_IDS.nbcnews
            : CHANNEL_IDS.nbcsportspeacock;
        this._accessName = '';
        this._callSign = '';
        this._isGlobalNavigation = false;
        Storage.remove(STORAGE_KEYS.PREVIOUS_CHANNEL);
        Storage.remove(STORAGE_KEYS.PREVIOUS_STREAM);
        Storage.remove(STORAGE_KEYS.PREVIOUS_CALLSIGN);
    }
    setIsGlobalNavigation(isGlobalNavigation) {
        this._isGlobalNavigation = isGlobalNavigation;
    }
    validate() {
        const isMvpdLinked = AuthenticationSingleton.isAuthenticated();
        if (!isMvpdLinked)
            this.reset();
    }
}
export const LiveStreamManager = new LiveStreamManagerClass();
