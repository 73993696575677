import { Language } from '@lightningjs/sdk';
import BaseComponent from '../../../../../base';
import { COLORS, FLEX_DIRECTION } from '../../../../../../constants';
import { throttle } from 'lodash';
import { SecondaryControlButton } from './SecondaryControlButton';
export var SecondaryControlButtons;
(function (SecondaryControlButtons) {
    SecondaryControlButtons["BackToLiveButton"] = "BackToLiveButton";
    SecondaryControlButtons["LiveGuideButton"] = "LiveGuideButton";
    SecondaryControlButtons["MediaOptions"] = "MediaOptions";
    SecondaryControlButtons["LiveToVod"] = "LiveToVod";
})(SecondaryControlButtons || (SecondaryControlButtons = {}));
const buttonMappings = {
    [SecondaryControlButtons.BackToLiveButton]: {
        defaultVisibility: false,
        label: 'go_to_live_v2',
        icon: 'images/player/live.png',
        ancestor: '$backToLive',
        bgColor: COLORS.red,
    },
    [SecondaryControlButtons.LiveGuideButton]: {
        label: 'view_live_guide_v2',
        icon: 'images/player/guide.png',
        ancestor: '$openLiveGuide',
    },
    [SecondaryControlButtons.MediaOptions]: {
        label: 'media_options',
        icon: 'images/player/cc2.png',
        ancestor: '$showMediaOptionsMenu',
    },
    [SecondaryControlButtons.LiveToVod]: {
        defaultVisibility: false,
        label: '',
        ancestor: '$onLiveToVodButtonEnter',
    },
};
export default class SecondaryControls extends BaseComponent {
    constructor() {
        super(...arguments);
        this._buttonsOrder = [];
        this._exclusions = [];
        this._focusedIndex = 0;
        this.toggle = throttle((tag, visible) => {
            // Avoid updating the button too frequently
            const el = this.tag(tag);
            if (!el)
                return;
            const currentVisibility = el.visible;
            if (currentVisibility === visible)
                return;
            el.visible = visible;
            if (!visible) {
                const currentlyFocused = this._buttonsOrder.findIndex((i) => i === tag) === this._focusedIndex;
                if (currentlyFocused)
                    this._recalculateFocusIndex(0);
            }
        }, 1000, { leading: true });
    }
    setLabel(button, label) {
        var _a;
        (_a = this.tag(button)) === null || _a === void 0 ? void 0 : _a.patch({ label });
    }
    _setTranslation() {
        this._buttonsOrder.forEach((button) => {
            this.tag(button).patch({
                label: Language.translate(buttonMappings[button].label),
            });
        });
    }
    set buttons(buttons) {
        this._buttonsOrder = buttons;
        this.patch(buttons.reduce((acc, i) => {
            var _a;
            return (Object.assign(Object.assign({}, acc), { [i]: {
                    visible: (_a = buttonMappings[i].defaultVisibility) !== null && _a !== void 0 ? _a : true,
                    flexItem: {
                        marginLeft: 20,
                    },
                    label: Language.translate(buttonMappings[i].label),
                    icon: buttonMappings[i].icon,
                    type: SecondaryControlButton,
                    bgColor: buttonMappings[i].bgColor,
                } }));
        }, {}));
    }
    static _template() {
        return {
            flex: {
                direction: FLEX_DIRECTION.row,
            },
        };
    }
    _handleLeft() {
        if (this._focusedIndex === 0)
            return;
        this._recalculateFocusIndex(this._focusedIndex - 1, true);
    }
    _handleRight() {
        if (this._focusedIndex === this._buttonsOrder.length - 1)
            return;
        this._recalculateFocusIndex(this._focusedIndex + 1);
    }
    _handleEnter() {
        const tag = this._buttonsOrder[this._focusedIndex];
        const ancestor = tag && buttonMappings[tag].ancestor;
        if (ancestor)
            this.fireAncestors(ancestor);
    }
    _focus() {
        this._recalculateFocusIndex(0);
    }
    _getFocused() {
        const tag = this._buttonsOrder[this._focusedIndex];
        return tag ? this.tag(tag) : this;
    }
    _recalculateFocusIndex(startIndex, reverse) {
        const els = this.childList.get();
        for (let i = startIndex, n = reverse ? 0 : els.length; reverse ? i >= n : i < n; reverse ? i-- : i++) {
            const el = els[i];
            if ((el === null || el === void 0 ? void 0 : el.visible) && (el === null || el === void 0 ? void 0 : el.label)) {
                this._focusedIndex = i;
                break;
            }
        }
    }
}
