export var ROUTE;
(function (ROUTE) {
    ROUTE["boot"] = "$";
    ROUTE["splash"] = "splash";
    ROUTE["home"] = "home";
    ROUTE["shows"] = "shows";
    ROUTE["showsWithBrand"] = "shows/:brand/:category?";
    ROUTE["networks"] = "networks";
    ROUTE["settings"] = "settings";
    ROUTE["profile"] = "settings/myprofile";
    ROUTE["appSettings"] = "settings/appsettings";
    ROUTE["language"] = "settings/applanguage";
    ROUTE["closedCaptioning"] = "settings/closedcaptioning";
    ROUTE["contactUs"] = "settings/contactus";
    ROUTE["about"] = "settings/about";
    ROUTE["privacy"] = "settings/privacy";
    ROUTE["faq"] = "settings/faq";
    ROUTE["longtext"] = "longtext";
    ROUTE["showHome"] = "show/:urlAlias";
    ROUTE["movieHome"] = "movie/:itemName";
    ROUTE["search"] = "search/:query?";
    ROUTE["video"] = "video/:videoId";
    ROUTE["credits"] = "credits/:videoId";
    ROUTE["networkLanding"] = "networks/:machineName";
    ROUTE["live"] = "live";
    ROUTE["liveWithParams"] = "live/:channelId/:streamAccessName?";
    ROUTE["watch"] = "watch/:pid";
    ROUTE["liveUpcoming"] = "live-upcoming/:mmddyyyy?";
    ROUTE["replays"] = "replays/:mmddyyyy?";
    ROUTE["sports"] = "sports";
    ROUTE["debug"] = "debug";
    ROUTE["episodeGuide"] = "EpisodeGuide";
    ROUTE["notFound"] = "*";
    ROUTE["error"] = "!";
    ROUTE["olympics"] = "olympics";
    ROUTE["paralympics"] = "paralympics";
    ROUTE["olympicsReplays"] = "oly-replays";
    ROUTE["olympicsHighlights"] = "oly-highlights";
})(ROUTE || (ROUTE = {}));
export const ROUTE_ENTITY = {
    pid: ':pid',
    videoId: ':videoId',
    machineName: ':machineName',
    urlAlias: ':urlAlias',
    itemName: ':itemName',
};
