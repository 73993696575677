import getVODAttributes from './getVodAttributes';
import getLinearAttributes, { getLinearDuration } from './getLinearAttributes';
import { setDefaultValue } from '../../../../helpers/analytics';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
const getAdAttributes = ({ video = {}, ad = {}, ccLanguage = '', previousVideo = '', previousVideoType = '', isLive, }) => {
    const extensions = ad.extensions || {};
    const shelf = (video === null || video === void 0 ? void 0 : video.shelf) || {};
    const ccLanguageValidated = ccLanguage || (video === null || video === void 0 ? void 0 : video.ccLanguage) || 'off';
    return setDefaultValue(Object.assign(Object.assign({}, (isLive
        ? getLinearAttributes(video)
        : getVODAttributes(video, ccLanguageValidated, previousVideo, previousVideoType))), { 'Video Duration': isLive ? getLinearDuration(video === null || video === void 0 ? void 0 : video.duration) : video === null || video === void 0 ? void 0 : video.durationInMilliseconds, resellerName: extensions.resellerName, mrmResellerID: extensions.adNetworkID || extensions.mrmResellerID, creativeName: extensions.creativeName, mrmCreativeID: extensions.creativeId || extensions.mrmCreativeID, creativeDuration: ad.duration, thirdPartyTagProvider: ad.adSystem || 'FreeWheel', creativeInstanceStartDate: extensions.creativeInstanceStartDate, creativeInstanceEndDate: extensions.creativeInstanceEndDate, mrmCreativeRenditionID: extensions.renditionID || extensions.mrmCreativeRenditionID, adUnit: extensions.position || extensions.adUnit, mrmAdUnitID: extensions.id || extensions.mrmAdUnitID, campaignName: extensions.campaignName, mrmCampaignID: extensions.campaignId, campaignStartDate: extensions.campaignStartDate, campaignEndDate: extensions.campaignEndDate, placementName: extensions.placementName, mrmPlacementID: extensions.placementId || extensions.mrmPlacementID, placementStartDate: extensions.placementStartDate, placementEndDate: extensions.placementEndDate, advertiserName: extensions.advertiser || extensions.advertiserName, mrmAdvertiserID: extensions.advertiserId || extensions.mrmAdvertiserID, siteSectionName: extensions.sitesection || extensions.siteSectionName, mrmSiteSectionID: extensions.csid || extensions.mrmSiteSectionID, siteSectionTag: extensions.csid, Casting: MPARTICLE_DEFAULT_ATTR_VALUE, Sponsor: shelf === null || shelf === void 0 ? void 0 : shelf.sponsorName, 'Playlist Name': shelf === null || shelf === void 0 ? void 0 : shelf.playlistMachineName, 'Playlist Position': shelf === null || shelf === void 0 ? void 0 : shelf.playlistPosition }), MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getAdAttributes;
