import { Lightning } from '@lightningjs/sdk';
import { ALIGN_CONTENT, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT, SCREEN_SIZE, TEXT_ALIGN, } from '../constants';
const defaultFontParams = {
    text: '',
    textAlign: TEXT_ALIGN.center,
    fontFace: FONT_FACE.light,
    wordWrapWidth: 1900,
    fontSize: 42,
};
export default class Message extends Lightning.Component {
    static _template() {
        return {
            w: SCREEN_SIZE.width,
            y: 910,
            flex: {
                direction: FLEX_DIRECTION.column,
                alignItems: ALIGN_CONTENT.center,
                justifyContent: JUSTIFY_CONTENT.center,
            },
            FirstRow: {
                text: Object.assign(Object.assign({}, defaultFontParams), { maxLines: 1 }),
            },
            SecondRow: {
                text: Object.assign(Object.assign({}, defaultFontParams), { maxLines: 2 }),
            },
        };
    }
    set componentProps(props) {
        this.tag('FirstRow').patch({ text: { text: props.firstRow } });
        this.tag('SecondRow').patch({ text: { text: props.secondRow } });
    }
    set positionY(positionY) {
        this.patch({ y: positionY || 910 });
    }
    _init() {
        this.fireAncestors('$contentIsUnavailable');
    }
}
