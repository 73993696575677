import { Language } from '@lightningjs/sdk';
import { formatDate, getPassedHoursFromNow } from '../../../helpers/date';
import { pipeSeparate } from '../../../helpers/string';
import { isAClip } from '../../../helpers/template';
import { PROGRAMMING_TYPES } from '../../../constants';
import Tile from './Tile';
export default class VideoTile extends Tile {
    get showMetadata() {
        const { seasonNumber, episodeNumber, airDate } = this.data;
        return pipeSeparate(`S${seasonNumber} E${episodeNumber} | ${formatDate(airDate)}`);
    }
    get title() {
        return pipeSeparate(this.data.title);
    }
    get description() {
        return this.data.description || '';
    }
    get image() {
        return this.data.image || null;
    }
    get date() {
        return formatDate(this.data.airDate);
    }
    get logo() {
        return this.data.whiteBrandLogo;
    }
    get shortTitle() {
        return this.data.seriesShortTitle || '';
    }
    get secondaryTitle() {
        return pipeSeparate(this.data.secondaryTitle);
    }
    get rating() {
        return this.data.rating;
    }
    get showClipData() {
        return isAClip(this.programmingType);
    }
    get showMovieData() {
        return this.data.programmingType === PROGRAMMING_TYPES.MOVIE;
    }
    get showSfvodData() {
        return this.data.programmingType === PROGRAMMING_TYPES.SFVOD;
    }
    get programmingType() {
        return this.data.programmingType;
    }
    get mpxGuid() {
        return this.data.mpxGuid;
    }
    get isLocked() {
        return this.data.locked;
    }
    get labelBadge() {
        return this.data.labelBadge;
    }
    get durationBadge() {
        return this.data.durationBadge;
    }
    get tertiaryTitle() {
        const hoursThatPassed = getPassedHoursFromNow(this.data.airDate);
        if (hoursThatPassed < 1) {
            return Language.translate('just_added');
        }
        return this.data.tertiaryTitle;
    }
    get gradientStart() {
        return this.data.gradientStart ? this.data.gradientStart.replace('#', '') : 'b78623';
    }
    get gradientEnd() {
        return this.data.gradientEnd ? this.data.gradientEnd.replace('#', '') : 'b78623';
    }
    get percentViewed() {
        return this.data.percentViewed || 0;
    }
    get playlistMachineName() {
        return this.data.playlistMachineName;
    }
    get HARDCODEDSTREAM() {
        return this.data.HARDCODEDSTREAM;
    }
}
