import { getBrandName, getDefaultCallSign } from './config';
import { BRAND, CALL_SIGNS } from '../constants';
import AuthenticationSingleton from '../authentication/Authentication';
import { CHANNEL_IDS, nbcAffiliateName, telemundoAffiliateName } from '../api/Live';
export const getSportsPageName = () => {
    const brand = getBrandName();
    switch (brand) {
        case BRAND.nbc:
        case BRAND.usa:
        case BRAND.bravo:
        case BRAND.syfy:
        case BRAND.oxygen:
        case BRAND.e:
        case BRAND.msnbc:
        case BRAND.nbcsports:
            return 'nbc-sports';
        case BRAND.telemundo:
            return 'telemundo-deportes';
        default:
            return '';
    }
};
export const getLiveCallSignByStreamAccessName = (streamAccessName) => {
    // Check if streamAccessName is not empty
    if (streamAccessName.length) {
        if (streamAccessName === CHANNEL_IDS.telemundo && telemundoAffiliateName())
            return telemundoAffiliateName();
        // Try to get call sign by stream access name
        const callSign = CALL_SIGNS[streamAccessName];
        if (callSign) {
            if (callSign === CALL_SIGNS.nbc && nbcAffiliateName())
                return nbcAffiliateName();
            else {
                return callSign;
            }
        }
    }
    // if user is authenticated try to use default call sign for each brand
    if (AuthenticationSingleton.isAuthenticated() && !AuthenticationSingleton.isMvpdTempPass()) {
        const callSign = getDefaultCallSign();
        if (callSign) {
            if (callSign === CALL_SIGNS.nbc && nbcAffiliateName())
                return nbcAffiliateName();
            else {
                return callSign;
            }
        }
    }
    // Default to nbcnews call sign
    return CALL_SIGNS.nbcnews;
};
export const topLogoPathMapper = (brand) => {
    switch (brand) {
        case BRAND.nbc:
        case BRAND.bravo:
        case BRAND.usa:
            return `images/onboarding/top-logo-${brand}.png`;
        default:
            return `images/logos/logo-${brand}.png`;
    }
};
