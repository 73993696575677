import { Storage } from '@lightningjs/sdk';
import trimStart from 'lodash/trimStart';
import mParticleInterface from '../lib/analytics/mParticle/mParticleInterface';
import { getWhiteLabelBrand } from './env';
import { COMPONENT_TYPES, MPARTICLE_CLICK_STATES, MPARTICLE_DEFAULT_ATTR_VALUE, PAGE_TYPE, PROGRAMMING_TYPES, SIGN_IN_TYPE, STORAGE_KEYS, } from '../constants';
import { getCurrentPageType } from '../lib/analytics/mParticle/attributes/getPageAttributes';
import { getUnixDate } from './date';
import { AppLaunchTypes } from '../lib/analytics/types';
export const getProduct = () => getWhiteLabelBrand().app;
// The app was initially launched with a hardcoded mParticleId.
export const removeHardcodedMPID = () => {
    const hardcodedMpid = '5727672205073229480';
    const mpid = Storage.get(STORAGE_KEYS.MPARTICLE);
    if (mpid && mpid === hardcodedMpid) {
        // Reset users localStorage for this app.
        Storage.clear();
    }
};
export const setMpid = (mpid = null, trim = false) => {
    if (trim && mpid)
        mpid = trimStart(mpid, '-');
    if (!!mpid)
        Storage.set(STORAGE_KEYS.MPARTICLE, mpid);
    return mpid;
};
export const getMpid = (trim = false, forceReset = false) => {
    // try to get from storage
    if (!forceReset) {
        const storedMpid = Storage.get(STORAGE_KEYS.MPARTICLE);
        if (storedMpid) {
            return trim ? trimStart(storedMpid, '-') : storedMpid;
        }
    }
    const newMpid = mParticleInterface.getMpid();
    return setMpid(newMpid, trim);
};
/**
 * Check user profile object for sso id's
 * @param {Profile} Identity profile data
 * @return {string} SSO value, if any
 */
export const getAuthSSO = (userProfile = {}) => {
    // @ts-expect-error TS(2339): Property 'googleId' does not exist on type '{}'.
    if (userProfile.googleId)
        return 'Google SSO';
    // @ts-expect-error TS(2339): Property 'facebookId' does not exist on type '{}'.
    if (userProfile.facebookId)
        return 'Facebook SSO';
    // @ts-expect-error TS(2339): Property 'appleId' does not exist on type '{}'.
    if (userProfile.appleId)
        return 'Apple SSO';
    return '';
};
/**
 * Set default values for null and undefined analytics values
 * @param {object} Object of analytics key/values
 * @param {string} Default value
 * @return {object} Analytics params with null/undefined values set to defaultValue
 */
export const setDefaultValue = (params, defaultValue) => {
    const attributes = params;
    for (const [key, value] of Object.entries(attributes)) {
        if (!value && value !== 0)
            attributes[key] = defaultValue;
    }
    return attributes;
};
export const getAnalyticsObjectByComponent = (item) => {
    switch (item === null || item === void 0 ? void 0 : item.component) {
        case COMPONENT_TYPES.UPCOMING_LIVE_SLIDE:
            return item === null || item === void 0 ? void 0 : item.analytics;
        default:
            return Object.assign(Object.assign({}, item === null || item === void 0 ? void 0 : item.ctaAnalytics), { title: item === null || item === void 0 ? void 0 : item.title, contentType: item === null || item === void 0 ? void 0 : item.contentType });
    }
};
export const getTileState = (tile) => (tile.isUpcoming && MPARTICLE_CLICK_STATES.UPCOMING) ||
    (tile.isLive && MPARTICLE_CLICK_STATES.LIVE) ||
    (tile.programmingType === PROGRAMMING_TYPES.FER && MPARTICLE_CLICK_STATES.FER) ||
    MPARTICLE_DEFAULT_ATTR_VALUE;
export const getEndCardAnalytics = (recommendation) => {
    var _a;
    const { analytics } = (recommendation === null || recommendation === void 0 ? void 0 : recommendation.item) || {};
    return {
        show: analytics === null || analytics === void 0 ? void 0 : analytics.series,
        sport: analytics === null || analytics === void 0 ? void 0 : analytics.destinationSport,
        league: analytics === null || analytics === void 0 ? void 0 : analytics.destinationLeague,
        mpxGuid: analytics === null || analytics === void 0 ? void 0 : analytics.mpxGuid,
        videoType: analytics === null || analytics === void 0 ? void 0 : analytics.programmingType,
        episodeTitle: analytics === null || analytics === void 0 ? void 0 : analytics.title,
        locked: analytics === null || analytics === void 0 ? void 0 : analytics.locked,
        brand: (_a = analytics === null || analytics === void 0 ? void 0 : analytics.brand) === null || _a === void 0 ? void 0 : _a.title,
        recommendationType: analytics === null || analytics === void 0 ? void 0 : analytics.recommendationType,
    };
};
export const setSLEDefaultParams = (params) => {
    return getCurrentPageType() === PAGE_TYPE.linearVideoPlayer
        ? Object.assign(params, {
            ['Page Type']: PAGE_TYPE.live,
            'Referring Page': 'Live Guide',
        })
        : params;
};
export const setAppLaunchTime = () => {
    Storage.set(STORAGE_KEYS.APP_LOADED_TIME, getUnixDate());
};
export const getDurationSinceLaunch = () => {
    return getUnixDate() - Storage.get(STORAGE_KEYS.APP_LOADED_TIME);
};
const getShowName = (program) => {
    switch (program.programmingType) {
        case PROGRAMMING_TYPES.MOVIE:
            return program.movie;
        default:
            return program.series;
    }
};
export const getAssetAuthAnalytics = (program) => {
    var _a;
    if (!program)
        return {};
    return {
        Season: program.seasonNumber,
        Show: getShowName(program),
        Brand: (_a = program.brand) === null || _a === void 0 ? void 0 : _a.title,
    };
};
export const getSignInType = (userProfile) => {
    switch (true) {
        case !!userProfile.googleId:
            return SIGN_IN_TYPE.google;
        case !!userProfile.facebookId:
            return SIGN_IN_TYPE.facebook;
        case !!userProfile.appleId:
            return SIGN_IN_TYPE.apple;
        default:
            return SIGN_IN_TYPE.email;
    }
};
/**
 * Set app launch type
 * @param type
 */
export const setAppLaunchType = (type) => {
    Storage.set(STORAGE_KEYS.APP_LAUNCH_TYPE, type);
};
/**
 * Get current app launch type
 * @returns {*|string}
 */
export const getAppLaunchType = () => {
    return Storage.get(STORAGE_KEYS.APP_LAUNCH_TYPE) || AppLaunchTypes.default;
};
/**
 * Checks programming type for Olympics and returns that if true, otherwise returns default value
 */
export const getIsOlympics = (analytics) => {
    return (analytics === null || analytics === void 0 ? void 0 : analytics.isOlympics) || (analytics === null || analytics === void 0 ? void 0 : analytics.programmingType) === PROGRAMMING_TYPES.OLYMPICS;
};
