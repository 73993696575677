import { Language, Router } from '@lightningjs/sdk';
import { COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE, ROUTE_ENTITY, VERTICAL_ALIGN, TEXT_ALIGN, } from '../../../../constants';
import { TempPassSingleton } from '../../../../lib/TempPass';
import RouterUtil from '../../../../util/RouterUtil';
import { ACTIVATION_LANDING, ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER, ACTIVATION_TYPE, } from '../constants';
import { ActivationStrategy } from './ActivationStrategy';
import LaunchDarklySingleton from '../../../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { parseMarkdownBold } from '../../../../helpers';
import { DebugControllerSingleton } from '../../../../util/debug/DebugController';
import ModalManager, { CloseReason } from '../../../../lib/ModalManager';
var RedirectState;
(function (RedirectState) {
    RedirectState[RedirectState["IDLE"] = 0] = "IDLE";
    RedirectState[RedirectState["CONTEXT_UPDATED"] = 1] = "CONTEXT_UPDATED";
    RedirectState[RedirectState["REDIRECTED"] = 2] = "REDIRECTED";
})(RedirectState || (RedirectState = {}));
export class TempPassActivationStrategy extends ActivationStrategy {
    constructor() {
        super(...arguments);
        this._ldRedirectState = RedirectState.IDLE;
    }
    get isSecondaryTempPass() {
        return this._ctx._landing === ACTIVATION_LANDING.NBC;
    }
    drawText() {
        var _a, _b;
        let texts = [];
        if (this.isSecondaryTempPass) {
            const debugSecondary = (_a = DebugControllerSingleton.tempPassName) === null || _a === void 0 ? void 0 : _a[1];
            const { initialTempPassName = '', secondaryTempPassName = '' } = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass);
            const tempPassDuration = ((_b = (debugSecondary || secondaryTempPassName || initialTempPassName)) === null || _b === void 0 ? void 0 : _b.replace(/\D/g, '')) || 30;
            texts = ['tempPass-activation-header', 'tempPass-activation-description'].map((t) => Language.translate(t, tempPassDuration));
            const containerWidth = 1500;
            const titleOverflowStartLength = 60;
            const subtitleOverflowStartLength = 100;
            const title = texts[0];
            const subtitle = texts[1];
            const titleOverflows = ((title === null || title === void 0 ? void 0 : title.length) || 0) >= titleOverflowStartLength;
            const subtitleOverflows = ((subtitle === null || subtitle === void 0 ? void 0 : subtitle.length) || 0) > subtitleOverflowStartLength;
            const subtitleMargin = subtitleOverflows ? 5 : 8;
            const commonStyles = {
                fontSize: subtitleOverflows
                    ? (containerWidth / Math.round((subtitle === null || subtitle === void 0 ? void 0 : subtitle.length) || 0)) *
                        ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER
                    : 42,
                textColor: COLORS.white,
                verticalAlign: VERTICAL_ALIGN.middle,
            };
            const regularObject = {
                flexItem: { marginRight: subtitleMargin },
                text: Object.assign(Object.assign({}, commonStyles), { fontFace: FONT_FACE.light }),
            };
            const boldObject = {
                flexItem: { marginRight: subtitleMargin },
                text: Object.assign(Object.assign({}, commonStyles), { fontFace: FONT_FACE.semiBold }),
            };
            const baseObject = {
                x: containerWidth / 2 + 10,
                mountX: 0.5,
                y: 96,
                color: COLORS.white,
                flex: { direction: FLEX_DIRECTION.row },
            };
            this._ctx.tag('ActivationScreen').patch({
                Title: {
                    x: containerWidth / 2,
                    mountX: 0.5,
                    text: Object.assign(Object.assign({}, (titleOverflows
                        ? {
                            fontSize: (containerWidth / Math.round((title === null || title === void 0 ? void 0 : title.length) || 0)) *
                                ACTIVATION_SCREEN_FONT_OVERFLOW_MULTIPLIER,
                            maxLines: 1,
                            wordWrapWidth: 0,
                        }
                        : {})), { text: title, textAlign: TEXT_ALIGN.center }),
                },
                Subtitle: parseMarkdownBold(texts[1], regularObject, boldObject, baseObject),
            });
            texts[1].replace('**', ''); // Replace markdown markers for announce
        }
        else {
            const title = Language.translate('live_stream_link_msg');
            this._ctx.tag('Title').text.text = title;
            texts = [title];
        }
        return texts;
    }
    destroy() {
        super.destroy();
        this._cleanListeners();
    }
    _cleanListeners() {
        if (this._ldSubscription) {
            this._ldSubscription.unsubscribe();
            this._ldSubscription = undefined;
        }
    }
    _setContextUpdated() {
        this._ldRedirectState = RedirectState.CONTEXT_UPDATED;
    }
    _listenToLaunchDarkly() {
        this._ldSubscription = LaunchDarklySingleton.events.subscribe(() => {
            this._setContextUpdated();
            this._redirect();
        });
    }
    _endNotification() {
        if (this.isSecondaryTempPass) {
            const flag = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass);
            const contextAlreadyUpdated = flag && !flag.secondaryTempPassName;
            if (contextAlreadyUpdated) {
                this._setContextUpdated();
            }
            else {
                this._listenToLaunchDarkly();
            }
        }
        super._endNotification();
    }
    _redirect() {
        if (this._activationType &&
            ![ACTIVATION_TYPE.NBC, ACTIVATION_TYPE.MVPD].includes(this._activationType)) {
            return;
        }
        ModalManager.close(CloseReason.COMPLETED);
        TempPassSingleton.endSession();
        if (this.isSecondaryTempPass && this._ldRedirectState === RedirectState.CONTEXT_UPDATED) {
            this._cleanListeners();
            const page = Router.getActivePage();
            if (page === null || page === void 0 ? void 0 : page.restartTempPass) {
                page.restartTempPass();
                return;
            }
        }
        if (this._ctx._streamId) {
            RouterUtil.navigateToRoute(ROUTE.watch, {
                entity: ROUTE_ENTITY.pid,
                value: this._ctx._streamId,
            });
            return;
        }
        RouterUtil.navigateToRoute(ROUTE.home, {});
    }
}
