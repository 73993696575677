import { Language } from '@lightningjs/sdk';
import { getMpid } from '../../helpers';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
/**
 * Determines if the data download should include profile data or not.
 * By default if no fetch type is passed, we use APP_DATA_ONLY.
 * If cross device is in scope, pass in APP_DATA_AND_SYNC_PROFILE
 */
var FetchType;
(function (FetchType) {
    FetchType["APP_DATA_ONLY"] = "APP_DATA_ONLY";
    FetchType["APP_DATA_AND_PROFILE"] = "APP_DATA_AND_PROFILE";
    FetchType["APP_DATA_AND_SYNC_PROFILE"] = "APP_DATA_AND_SYNC_PROFILE";
})(FetchType || (FetchType = {}));
const getBaseHeaders = (consentString = '') => ({
    'OT-CDN-Location': 'cdn.cookielaw.org',
    'OT-App-Id': AppConfigFactorySingleton.config.one_trust_id,
    'OT-SDK-Version': AppConfigFactorySingleton.config.one_trust_version,
    'OT-Device-Type': 'ctv',
    'OT-Consent-String': consentString,
    'OT-Identifier': getMpid(),
    'OT-Sync-Profile-Auth': '',
    'OT-Fetch-Type': FetchType.APP_DATA_AND_SYNC_PROFILE,
    'OT-Language': (Language.get() || 'EN').toLowerCase(),
    'OT-Country-Code': 'US',
    'OT-Region-Code': '',
    'OT-Tcf-Eu2v2-Consent-String': consentString,
});
export const getSaveApiHeaders = (consentString = '') => new Headers(Object.assign(Object.assign({}, getBaseHeaders(consentString)), { 'Content-Type': 'application/json', 'OT-Identifier-Type': '', 'OT-GPP-String': '' }));
export const getBannerPreferenceHeaders = (consentString = '') => new Headers(Object.assign(Object.assign({}, getBaseHeaders(consentString)), { 'OT-Force-Fetch': 'false' }));
