import { Router } from '@lightningjs/sdk';
import RouterUtil from '../../../util/RouterUtil';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { pushHash } from '../../../helpers/navigation';
import { EVENTS } from '../../../lib/analytics/types';
import { ENTITY_TYPES, ROUTE, ShowsStates } from '../../../constants';
import ContentPosition from '../../../util/contentPosition';
export const BrandsLabelsStateFactory = (base) => class BrandLabelsList extends base {
    _handleUp() {
        Router.focusWidget('Menu');
    }
    _handleBack(e) {
        Router.focusWidget('Menu');
        e.preventDefault();
        e.stopPropagation();
    }
    _handleDown() {
        this._setState(ShowsStates.LinksSelectableGroup);
    }
    _getFocused() {
        return this.brandLabelsList || this;
    }
    trackContentClick(newIndex) {
        const analytics = { brand: {} };
        const params = {
            entity: { analytics: analytics, entityType: ENTITY_TYPES.BRAND },
            shelf: {
                position: newIndex + 1,
                customPosition: 1,
                analytics: {},
            },
        };
        sendMetric(EVENTS.CONTENT_CLICK, params);
    }
    _handleEnter() {
        var _a, _b, _c;
        const newIndex = (_a = this.brandLabelsList) === null || _a === void 0 ? void 0 : _a.index;
        if (typeof newIndex === 'number' && newIndex !== this._selectedBrandIndex) {
            const hash = Router.getActiveHash() || '';
            const [base = ''] = hash.split('/');
            if (base) {
                pushHash(`${base}/${this.currentBrand}`);
                ContentPosition.clearPositionForCurrentPage();
            }
            // Store a copy because it'll get overwritten with the new index.
            this.trackContentClick(newIndex);
            const prevIndex = this._selectedBrandIndex;
            this._selectedBrandIndex = newIndex;
            this._setState(ShowsStates.LoadSelectedBrandData);
            // Fire mparticle event as if it were a page view.
            const brand = (_b = this._brandSelectableSpawner) === null || _b === void 0 ? void 0 : _b.getBrandLabel(newIndex);
            const prevBrand = (_c = this._brandSelectableSpawner) === null || _c === void 0 ? void 0 : _c.getBrandLabel(prevIndex);
            sendMetric(EVENTS.PAGE_LOAD, Object.assign(Object.assign({}, RouterUtil.showsBrandRoutingData(brand, prevBrand)), { path: ROUTE.shows }));
            sendMetric(EVENTS.CLICK, {
                name: 'Network Logo Click',
                brand: brand,
            });
        }
    }
};
