import ParkApi, { VERSION } from './ParkApi';
import { getVideoId, videoQuery } from './ParkUtils';
const post = async ({ mpid, attributes, guid }) => ParkApi.post(`users/${mpid}/watches`, 'POST', {
    data: {
        type: 'watches',
        attributes,
        relationships: {
            user: { data: { type: 'users', id: mpid } },
            video: {
                data: { type: 'videos', id: getVideoId(guid) },
            },
        },
    },
    include: 'video.keyframes,video.series.clientProperties.primaryImages,video.series.clientProperties.secondaryImages,video.episode',
}, { version: VERSION });
const patch = async (mpid, watchId, attributes, guid) => ParkApi.post(`users/${mpid}/watches/${watchId}`, 'PATCH', {
    data: {
        type: 'watches',
        attributes: Object.assign({}, attributes),
        relationships: {
            user: { data: { type: 'users', id: mpid } },
            video: {
                data: { type: 'videos', id: getVideoId(guid) },
            },
        },
    },
}, { version: VERSION });
const get = async (mpid) => ParkApi.get(`users/${mpid}/watches`, {
    filter: {
        dateTimeWatched: {
            condition: {
                path: 'dateTimeWatched',
                operator: '>',
                value: new Date(Date.now() - 7776000000).toISOString(),
            },
        },
    },
    sort: '-dateTimeWatched',
    page: {
        size: 25,
    },
    include: 'video.keyframes,video.series.clientProperties.primaryImages,video.series.clientProperties.secondaryImages,video.episode.season,video.episode.next',
    derivatives: videoQuery.derivatives,
}, { version: VERSION });
export default { post, patch, get };
