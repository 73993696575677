import { GuideBrand } from '..';
import BaseComponent from '../../../base';
import ItemWrapper from '../../../items/ItemWrapper';
export default class GuideBrandHolder extends BaseComponent {
    static _template() {
        const template = super._template();
        return Object.assign(Object.assign({}, template), { x: 81, y: 59, h: 2712, w: 154, clipping: true, Streams: {
                boundsMargin: [500, 100, 500, 100],
            } });
    }
    _init() {
        this._streams = this.tag('Streams');
        this._streams.children = [];
    }
    changeBrandIndex(index) {
        /* Set the focus of channel every index change */
        this._streams.children.forEach((stream, streamIndex) => {
            stream.setChildProps({
                focused: streamIndex === index,
            });
        });
    }
    set streams(v) {
        if (!v)
            return;
        if (!this._streams) {
            this._streams = this.tag('Streams');
        }
        this._streams.children = v.map((stream, index) => {
            return {
                w: this.itemHeight,
                h: this.itemHeight,
                y: index * (this.itemHeight + this.itemPadding),
                type: ItemWrapper,
                itemType: GuideBrand,
                item: stream,
                index,
            };
        });
    }
    get streams() {
        return this._streams;
    }
}
