'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
var icon_font_1 = require('./icon-constants')
var ruler_1 = require('./ruler')
var FONT_TEST_DELAY = 50
var DEFAULT_TIMEOUT_VALUE = 15000
var FONT_SETTING_REGEX = /(?:(.*)\s+)?(?:.+px)\s+(.*)/i
var RULER_TEXT_OVERRIDE_MAP = {
  icon: icon_font_1.Icons.Account,
}
var DEFAULT_RULER_TEXT = 'BESbswy'
function getRulerText(family) {
  return RULER_TEXT_OVERRIDE_MAP[family] || DEFAULT_RULER_TEXT
}
function computeHasWebkitFallbackBug() {
  var match = /AppleWebKit\/([0-9]+)(?:\.([0-9]+))/.exec(window.navigator.userAgent)
  return (
    !!match &&
    (parseInt(match[1], 10) < 536 ||
      (parseInt(match[1], 10) === 536 && parseInt(match[2], 10) <= 11))
  )
}
var hasWebkitFallbackBug = computeHasWebkitFallbackBug()
function getStyleForFamily(family, weight) {
  return ''.concat(weight, ' 16px ').concat(family)
}
function waitForFontLoaded(family, weight) {
  var timeoutId
  var timeoutValue = DEFAULT_TIMEOUT_VALUE
  return new Promise(function(resolve, reject) {
    var text = getRulerText(family)
    var rulerA = new ruler_1.Ruler(text)
    var rulerB = new ruler_1.Ruler(text)
    var rulerC = new ruler_1.Ruler(text)
    var webkitBugReferenceA
    var webkitBugReferenceB
    var webkitBugReferenceC
    var container = document.createElement('div')
    function stop() {
      window.clearInterval(timeoutId)
      if (container.parentNode !== null) {
        container.parentNode.removeChild(container)
      }
    }
    var expirationTime = Date.now() + timeoutValue
    var checkLoad = function() {
      var now = Date.now()
      if (now >= expirationTime) {
        stop()
        reject(
          new Error(
            'FontFace.load('
              .concat(family, '/')
              .concat(weight, '): ')
              .concat(timeoutValue, 'ms timeout exceeded')
          )
        )
      } else {
        var widthA = rulerA.getWidth()
        var widthB = rulerB.getWidth()
        var widthC = rulerC.getWidth()
        if (widthA === widthB || widthA === widthC || widthB === widthC) {
          if (
            hasWebkitFallbackBug &&
            widthA === widthB &&
            widthB === widthC &&
            (widthA === webkitBugReferenceA ||
              widthB === webkitBugReferenceB ||
              widthC === webkitBugReferenceC)
          ) {
            // This is the webkit fallback scenario (while loading the same fallback font is being used for all font settings), so we're not ready yet!
            return
          }
          // Got matching dimensions which means the browser has loaded the font (we hope!)
          stop()
          resolve()
        }
      }
    }
    if (hasWebkitFallbackBug) {
      rulerA.setFont(getStyleForFamily('sans-serif', weight))
      rulerB.setFont(getStyleForFamily('serif', weight))
      rulerC.setFont(getStyleForFamily('monospace', weight))
    }
    container.appendChild(rulerA.getElement())
    container.appendChild(rulerB.getElement())
    container.appendChild(rulerC.getElement())
    document.body.appendChild(container)
    if (hasWebkitFallbackBug) {
      webkitBugReferenceA = rulerA.getWidth()
      webkitBugReferenceB = rulerB.getWidth()
      webkitBugReferenceC = rulerC.getWidth()
    }
    rulerA.setFont(getStyleForFamily('"'.concat(family, '",sans-serif'), weight))
    rulerB.setFont(getStyleForFamily('"'.concat(family, '",serif'), weight))
    rulerC.setFont(getStyleForFamily('"'.concat(family, '",monospace'), weight))
    timeoutId = window.setInterval(checkLoad, FONT_TEST_DELAY)
  })
}
var FontFacePolyfill = /** @class */ (function() {
  function FontFacePolyfill(family, source, descriptors) {
    this.family = family
    // This is a very custom-tailored implementation
    this.weight =
      (descriptors === null || descriptors === void 0 ? void 0 : descriptors.weight) || 'normal'
    this.status = 'unloaded'
    // Register the font in the document
    var style = "@font-face { font-family: '"
      .concat(this.family, "'; src: ")
      .concat(source, '; font-weight: ')
      .concat(this.weight, '}')
    var styleSheet = document.createElement('style')
    styleSheet.type = 'text/css'
    styleSheet.innerText = style
    document.head.appendChild(styleSheet)
  }
  FontFacePolyfill.prototype.load = function() {
    var _this = this
    // console.log('Loading FontFace', this.family);
    if (!this.loaded) {
      this.status = 'loading'
      return (this.loaded = waitForFontLoaded(this.family, this.weight)
        .then(function() {
          _this.status = 'loaded'
          return _this
        })
        .catch(function(error) {
          _this.status = 'error'
          throw error
        }))
    }
    return this.loaded
  }
  return FontFacePolyfill
})()
window.FontFace = FontFacePolyfill
// On browsers that support `document.fonts` we can't override that object...
// ... but we can override its methods
if (!document.fonts) {
  // So we'll just ensure that object exist where needed, and always override the default
  // implementations when the polyfill is imported
  document.fonts = {}
}
// We're not respecting the API here, but Lightning doesn't use the result of this call
// so we're keeping it simple
document.fonts.add = function(_fontFace) {
  return undefined
}
// Since we'll be blocking the app's startup until the fonts are loaded, when this is called
// we can simply return `true`
document.fonts.check = function(_fontSetting, _text) {
  return true
}
// This will simply call the font loading wait algorithm
// Please note that the `fontSetting` parsing is very limited and tailored to our current needs
// so it will only accept:
// * "<weight> <size-in-px> <font-families>"
// * "<size-in-px> <font-families>"
//
// As with the `document.fonts.add method, we'll not be respecting the API here,
// but since Lightning doesn't use the result of this call we're keeping it simple
document.fonts.load = function(font, _text) {
  var match = font.match(FONT_SETTING_REGEX)
  if (match) {
    var promise =
      match.length === 3
        ? waitForFontLoaded(match[2], 'normal')
        : waitForFontLoaded(match[2], match[3])
    return promise
  }
  throw new Error('Could not parse fontSetting: '.concat(font))
}
