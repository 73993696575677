'use strict'
/**
 * Based on https://github.com/bramstein/fontfaceobserver
 */
Object.defineProperty(exports, '__esModule', { value: true })
exports.Ruler = void 0
var Ruler = /** @class */ (function() {
  function Ruler(text) {
    this.element = document.createElement('div')
    this.element.setAttribute('aria-hidden', 'true')
    var style = this.element.style
    style.maxWidth = 'none'
    style.minWidth = '20px'
    style.minHeight = '20px'
    style.display = 'inline-block'
    style.overflow = 'hidden'
    style.position = 'absolute'
    style.width = 'auto'
    style.margin = '0'
    style.padding = '0'
    style.top = '-999px'
    style.whiteSpace = 'nowrap'
    style.fontSynthesis = 'none'
    this.element.innerText = text
  }
  Ruler.prototype.getElement = function() {
    return this.element
  }
  Ruler.prototype.setFont = function(font) {
    this.element.style.font = font
  }
  Ruler.prototype.getWidth = function() {
    return this.element.offsetWidth
  }
  return Ruler
})()
exports.Ruler = Ruler
