var LaunchDarklyFeatureFlags;
(function (LaunchDarklyFeatureFlags) {
    LaunchDarklyFeatureFlags["removePersonalization"] = "remove-personalization";
    LaunchDarklyFeatureFlags["skipTimer"] = "skip-timer";
    LaunchDarklyFeatureFlags["sleLiveGuide"] = "sle-live-guide";
    LaunchDarklyFeatureFlags["sleLiveGuideShelf"] = "sle-live-guide-shelf";
    LaunchDarklyFeatureFlags["enableFERContent"] = "enable-fer-content";
    LaunchDarklyFeatureFlags["enableFERSearch"] = "enable-fer-search";
    LaunchDarklyFeatureFlags["tempPass"] = "temp-pass";
    LaunchDarklyFeatureFlags["externalAppLinking"] = "temp-pass-modal-cta";
    LaunchDarklyFeatureFlags["enablePauseAdsRefresh"] = "pause-ad-enable";
    LaunchDarklyFeatureFlags["pauseAdsRefreshCount"] = "pause-ad-refresh";
    LaunchDarklyFeatureFlags["liveCheckModal"] = "live-check-modal";
    LaunchDarklyFeatureFlags["enableMarketingModule"] = "enable-marketing-module";
    LaunchDarklyFeatureFlags["enableSleBinge"] = "enable-sle-binge";
    LaunchDarklyFeatureFlags["sleBingeTimer"] = "sle-binge-timer";
    LaunchDarklyFeatureFlags["marketingModulePreview"] = "marketing-module-preview";
    LaunchDarklyFeatureFlags["marketingModuleAudio"] = "marketing-module-audio";
    LaunchDarklyFeatureFlags["livePlayerWatchDuration"] = "live-player-watch-duration";
    LaunchDarklyFeatureFlags["liveGuide"] = "live-guide";
    LaunchDarklyFeatureFlags["newPlayerTest"] = "new-player-test";
    LaunchDarklyFeatureFlags["ctvOnboarding"] = "ctv-onboarding";
})(LaunchDarklyFeatureFlags || (LaunchDarklyFeatureFlags = {}));
export default LaunchDarklyFeatureFlags;
