import { Log } from '@lightningjs/sdk';
import AuthenticationSingleton from '../../authentication/Authentication';
import { transformResources } from '../../helpers';
export const filterEPGStreamsByRSN = (streams) => {
    var _a, _b;
    const resources = (_b = (_a = AuthenticationSingleton.getMvpdData()) === null || _a === void 0 ? void 0 : _a.preAuthData) === null || _b === void 0 ? void 0 : _b.resources;
    if (!resources)
        return streams;
    return streams.filter((stream) => stream);
};
export const filterScheduleByRSN = (schedules) => {
    var _a, _b;
    const resources = (_b = (_a = AuthenticationSingleton.getMvpdData()) === null || _a === void 0 ? void 0 : _a.preAuthData) === null || _b === void 0 ? void 0 : _b.resources;
    if (!resources)
        return schedules;
    return schedules
        .map(({ programs }) => ({
        programs: programs.filter((program) => program),
    }))
        .filter(({ programs }) => programs.length);
};
const rsnFilter = (page) => {
    var _a, _b;
    const resources = (_b = (_a = AuthenticationSingleton.getMvpdData()) === null || _a === void 0 ? void 0 : _a.preAuthData) === null || _b === void 0 ? void 0 : _b.resources;
    // If we don't have our preauth data, just return the page right away. No filtering needed.
    if (!resources || !page) {
        return page;
    }
    // Check both sections and featured
    // @ts-expect-error Add types
    const { sections, featured } = page;
    const resourceAuthorization = transformResources(resources);
    // Filter the data for both sections and featured
    const filteredSections = filterData(sections, resourceAuthorization);
    // Handling lazy loader - placeholder components for featured DLS
    let filteredFeatured;
    //@ts-expect-error TS(2339): component is not in property
    if ((page === null || page === void 0 ? void 0 : page.component) === 'Slideshow' && !featured) {
        filteredFeatured = filterData([page], resourceAuthorization);
    }
    else {
        filteredFeatured = filterData(featured, resourceAuthorization);
    }
    //Return the page with new sections and featured
    return Object.assign(Object.assign({}, page), { featured: filteredFeatured, sections: filteredSections });
};
// TODO: replace any for the real type.
const filterData = (sections, resourceAuthorization) => {
    var _a;
    return (((_a = sections === null || sections === void 0 ? void 0 : sections.map) === null || _a === void 0 ? void 0 : _a.call(sections, (section) => {
        var _a, _b, _c;
        const filtered = (_b = (_a = section === null || section === void 0 ? void 0 : section.data) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b.filter((item) => {
            var _a;
            //TODO: Check mapping, see if we can get this to always be data rather than these other data types
            const data = (item === null || item === void 0 ? void 0 : item.data) ||
                (item === null || item === void 0 ? void 0 : item.replayTileData) ||
                (item === null || item === void 0 ? void 0 : item.smartTileData) ||
                (item === null || item === void 0 ? void 0 : item.eventTileData) ||
                ((_a = item === null || item === void 0 ? void 0 : item.item) === null || _a === void 0 ? void 0 : _a.data) ||
                (item === null || item === void 0 ? void 0 : item.onAirNowTileData);
            if (!data) {
                Log.info('rsnFilter missing data:', item);
                return false;
            }
            return true;
        });
        //Placeholder sections don't have items
        if ((_c = section === null || section === void 0 ? void 0 : section.data) === null || _c === void 0 ? void 0 : _c.items) {
            section.data.items = filtered;
        }
        return section;
    })) || sections);
};
export default rsnFilter;
