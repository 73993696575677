import { Language, Lightning } from '@lightningjs/sdk';
import { SectionsSpawner } from '../api/spawners';
import TransparentShader from '../shaders/TransparentShader';
import { COLORS, END_CARD_TYPES, FONT_FACE, SCREEN_SIZE, TEXT_ALIGN, VERTICAL_ALIGN, } from '../constants';
import { FastImg, getEndCardAnalytics } from '../helpers';
import { useRequest } from '../lib/useRequest';
import { EndCardConfig, EndCardSleConfig } from '../helpers/request';
import TextButton from './buttons/TextButton';
const getEndCardTypeFromProgrammingType = (type) => type in END_CARD_TYPES
    ? END_CARD_TYPES[type]
    : END_CARD_TYPES.DEFAULT;
export default class EndCard extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._useBlankEndcard = false;
        this.videoConcludedText = Language.translate('video_concluded_endcard');
        this.videoConcludedTextSLE = Language.translate('video_concluded_endcard_sle');
        this.videoConcludedCTASLE = Language.translate('video_concluded_endcard_sle_cta');
        /**
         * Handle EndCardRequest response
         * @param data EndCardRequest response
         * @returns true if end cards are available
         */
        this._handleResponse = async (data) => {
            try {
                if (!(data === null || data === void 0 ? void 0 : data.endCardData))
                    return false;
                const sections = await SectionsSpawner(this.stage, [data === null || data === void 0 ? void 0 : data.endCardData]);
                if (sections) {
                    this.endCardsData = sections[0];
                    return true;
                }
            }
            catch (_a) {
                // fail silently
            }
            return false;
        };
    }
    static _template() {
        return {
            ImageHolder: {
                Image: {},
                Gradient: {
                    w: SCREEN_SIZE.width,
                    h: SCREEN_SIZE.height,
                    rect: true,
                    colorLeft: this.bindProp('colorLeft'),
                    colorRight: this.bindProp('colorRight'),
                },
            },
            TransparentRect: {
                x: 101,
                y: 90,
                rect: true,
                color: COLORS.transparent,
                w: 419,
                h: 235,
                alpha: 0,
            },
            TransparentBGRect: {
                x: 0,
                y: 0,
                rect: true,
                colorTop: COLORS.transparent,
                colorBottom: COLORS.black,
                w: 1920,
                h: 1080,
                alpha: 0,
            },
            SFVOD_BGRect: {
                x: 0,
                y: 0,
                rect: true,
                colorTop: COLORS.dark,
                colorBottom: COLORS.black,
                w: 1920,
                h: 1080,
                alpha: 0,
                Description: {
                    alpha: 1,
                    x: 960,
                    y: 540,
                    mount: 0.5,
                    text: {
                        textColor: COLORS.white,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        textAlign: TEXT_ALIGN.center,
                        fontSize: 40,
                        fontFace: FONT_FACE.light,
                        color: COLORS.white,
                        text: this.bindProp('videoConcludedText'),
                    },
                },
            },
            EMPTY_SLE_BGRect: {
                x: 0,
                y: 0,
                rect: true,
                colorTop: COLORS.dark,
                colorBottom: COLORS.black,
                w: 1920,
                h: 1080,
                alpha: 0,
                Description: {
                    alpha: 1,
                    x: 960,
                    y: 540,
                    mount: 0.5,
                    text: {
                        textColor: COLORS.white,
                        verticalAlign: VERTICAL_ALIGN.middle,
                        textAlign: TEXT_ALIGN.center,
                        lineHeight: 60,
                        fontSize: 40,
                        fontFace: FONT_FACE.light,
                        color: COLORS.white,
                        text: this.bindProp('videoConcludedTextSLE'),
                    },
                },
                ButtonHolder: {
                    w: 260,
                    h: 60,
                    x: 960,
                    y: 640,
                    mount: 0.5,
                    ButtonPrimary: {
                        h: 58,
                        type: TextButton,
                        radius: 29,
                        fontSize: 28,
                        fontFace: FONT_FACE.regular,
                        focusFontColor: COLORS.black,
                        unfocusFontColor: COLORS.black,
                        focusBackGroundColor: COLORS.white,
                        unfocusBackgroundColor: COLORS.white,
                        strokeWidth: 1,
                        strokeColor: COLORS.white,
                        autoWidth: true,
                        padding: 22,
                        label: this.bindProp('videoConcludedCTASLE'),
                    },
                },
            },
            EndCards: {},
        };
    }
    _detach() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = undefined;
    }
    _init() {
        super._init();
        this.videoConcludedText = Language.translate('video_concluded_endcard');
        this.videoConcludedTextSLE = Language.translate('video_concluded_endcard_sle');
        this.videoConcludedCTASLE = Language.translate('video_concluded_endcard_sle_cta');
    }
    async queryEndCards(videoId, programmingType) {
        const type = getEndCardTypeFromProgrammingType(programmingType);
        return useRequest(EndCardConfig(type, videoId))
            .fetch()
            .then(this._handleResponse);
    }
    async queryEndCardsSLE(videoId, programmingType, source) {
        const type = getEndCardTypeFromProgrammingType(programmingType);
        return useRequest(EndCardSleConfig(type, videoId, source))
            .fetch()
            .then(this._handleResponse);
    }
    set endCardsData(v) {
        if (v) {
            this._endCardsData = v.items;
            v.x = v.noOfItems === 2 ? 900 : 1380;
            v.y = 640;
            this.patch({
                EndCards: v,
            });
        }
        else {
            this.patch({ EndCards: null });
        }
    }
    get endCardsData() {
        return this._endCardsData;
    }
    getAnalyticsData() {
        if (this._endCardsData === undefined)
            return;
        return {
            recommendation: getEndCardAnalytics(this._endCardsData[0]),
            recommendationAlternate1: getEndCardAnalytics(this._endCardsData[1]),
        };
    }
    _reset() {
        this._setState('');
        this._endCardsData = null;
    }
    set transparent(v) {
        this.patch({
            TransparentRect: {
                alpha: v ? 1 : 0,
                shader: v ? { type: TransparentShader } : null,
            },
        });
    }
    set transparentBG(v) {
        this.patch({
            TransparentBGRect: {
                alpha: v ? 1 : 0,
                shader: v ? { type: TransparentShader } : null,
            },
        });
    }
    set SFVOD_BG(v) {
        this.patch({
            SFVOD_BGRect: {
                alpha: v ? 1 : 0,
            },
        });
    }
    set EMPTY_SLE_BG(v) {
        this.patch({
            EMPTY_SLE_BGRect: {
                alpha: v ? 1 : 0,
            },
        });
    }
    set SHOW_HIDE_TILES(shouldShow) {
        this.patch({
            EndCards: {
                alpha: shouldShow ? 1 : 0,
            },
        });
    }
    _focus() {
        this._setState('EndCards');
    }
    static _states() {
        return [
            class EndCards extends this {
                $enter() {
                    this._setImage(0);
                }
                $exit() {
                    this.tag('ImageHolder').patch({
                        Image: {
                            texture: null,
                        },
                    });
                }
                _getFocused() {
                    return this.tag('EndCards') || this;
                }
                $scrolledListItemIndex({ index }) {
                    this._setImage(index);
                }
                _setImage(index) {
                    var _a, _b, _c, _d;
                    const item = (_d = (_c = (_b = (_a = this.tag('EndCards.Items')) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.item) === null || _d === void 0 ? void 0 : _d.tile;
                    if (item) {
                        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
                        this.colorLeft = `0xE6${item.gradientStart}`;
                        // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'number'.
                        this.colorRight = `0xE6${item.gradientEnd}`;
                        this.tag('ImageHolder').patch({
                            Image: {
                                texture: FastImg(item.image).contain(SCREEN_SIZE.width, SCREEN_SIZE.height),
                            },
                        });
                    }
                }
            },
        ];
    }
}
