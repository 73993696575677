import { Lightning } from '@lightningjs/sdk';
import isUndefined from 'lodash/isUndefined';
import { COLORS, FONT_FACE, TEXT_ALIGN, VERTICAL_ALIGN } from '../../../constants';
const HORIZONTAL_BUFFER_SPACE = 32;
export default class LabelItem extends Lightning.Component {
    constructor() {
        super(...arguments);
        this.autoWidth = true;
        this.isDivider = false;
    }
    static _template() {
        return {
            h: 112,
            Focus2: {
                rtt: true,
                rect: true,
                color: COLORS.white1,
                shader: {
                    type: Lightning.shaders.RoundedRectangle,
                    radius: 6,
                },
                y: 0,
                h: 72,
                mountX: 0.5,
            },
            Item: {
                mountY: 0.5,
                y: 39,
                x: HORIZONTAL_BUFFER_SPACE / 2,
                text: {
                    textColor: COLORS.warmGray,
                    verticalAlign: VERTICAL_ALIGN.middle,
                    textAlign: TEXT_ALIGN.center,
                },
            },
        };
    }
    set focusFontFace(focusFontFace) {
        this._focusFontFace = focusFontFace;
    }
    get focusFontFace() {
        return this._focusFontFace;
    }
    get label() {
        return this._item.label;
    }
    set item(v) {
        this._item = v;
        this.autoWidth = !isUndefined(v.autoWidth) ? v.autoWidth : this.autoWidth;
        this._isActive = v.active;
        if (v.label) {
            const fontSize = v.fontSize
                ? v.fontSize
                : typeof v.label === 'number' && isNaN(v.label)
                    ? 17.5
                    : 36;
            const focusBackgroundColor = v.focusBackgroundColor || COLORS.white1;
            this.patch({
                Focus2: {
                    color: focusBackgroundColor,
                },
                Item: {
                    text: {
                        text: v.label,
                        fontSize: fontSize,
                        fontFace: FONT_FACE.semiBold,
                    },
                },
            });
            const item = this.tag('Item');
            item.loadTexture();
            this._height = item.finalH;
            this._width = this.autoWidth ? item.finalW : this.w;
            this.announce = v.ariaLabel || v.label;
        }
        this.isHighlighted = false;
        this._focusAnimation();
    }
    get width() {
        return this._width;
    }
    get position() {
        var _a, _b;
        const i = ((_b = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.children) === null || _b === void 0 ? void 0 : _b.findIndex((value) => this.label === (value === null || value === void 0 ? void 0 : value.label))) || 0;
        return i + 1;
    }
    get itemsCount() {
        var _a;
        return ((_a = this.parent) === null || _a === void 0 ? void 0 : _a.children.length) || 0;
    }
    // @ts-expect-error TS(2611): 'active' is defined as a property in class 'Compon... Remove this comment to see the full error message
    set active(v) {
        if (this._isActive !== v) {
            this._isActive = v;
            this._focusAnimation();
        }
    }
    _focus() {
        this.announce = [
            `${this.label}, Button`,
            'press OK to select',
            `${this.position} of ${this.itemsCount}`,
            ...(this.isVertical ? ['navigate up and down for menu options'] : []),
        ];
        this._setHighlighted(true, this.width);
        this._focusAnimation();
    }
    _unfocus() {
        this._focusAnimation();
    }
    _focusAnimation() {
        this.tag('Item').text = {
            fontFace: this.hasFocus() || this._isActive ? this.focusFontFace : FONT_FACE.light,
            textColor: (() => {
                var _a, _b;
                if (((_a = this._item) === null || _a === void 0 ? void 0 : _a.isDivider) && ((_b = this._item) === null || _b === void 0 ? void 0 : _b.fontColor)) {
                    return this._item.fontColor;
                }
                if (this.hasFocus()) {
                    return COLORS.dark2;
                }
                if (this._isActive) {
                    return COLORS.white;
                }
                return COLORS.warmGray;
            })(),
        };
        // Show hide highlight when unfocusing/focusing
        this.tag('Focus2').alpha = this.hasFocus() && this.isHighlighted ? 1 : 0;
    }
    _setHighlighted(v, width) {
        if (this.hasFocus()) {
            this.tag('Focus2').patch(Object.assign({ alpha: v ? 1 : 0 }, (typeof width === 'number'
                ? {
                    x: width / 2 + HORIZONTAL_BUFFER_SPACE / 2,
                    w: width + HORIZONTAL_BUFFER_SPACE,
                }
                : {})));
        }
        // Track highlight so when exiting and returning to this focused list
        // it can be turned off and on.
        this.isHighlighted = !!v;
    }
    static get tag() {
        return 'LabelItem';
    }
}
