import moment from 'moment-timezone';
import { Lightning } from '@lightningjs/sdk';
import { COLORS } from '../../../../constants';
import { getAdjustedReferringShelf } from '../../../../helpers';
import EndCard from '../../../../components/EndCard';
import { PlayerStates } from '../../../../constants';
import BasePlayer from '../../BasePlayer';
import ModalManager, { ModalTypes } from '../../../../lib/ModalManager';
export class HasEndCard extends BasePlayer {
}
export const WithEndCard = (component) => class extends component {
    static _template() {
        return Object.assign(Object.assign({}, super._template()), { Image: {
                alpha: 0.01,
                x: 100,
                y: 90,
            }, MiniPlayerFocusRect: {
                alpha: 0,
                x: 86,
                y: 76,
                w: 450,
                h: 266,
                rect: true,
                shader: {
                    type: Lightning.shaders.RoundedRectangle,
                    stroke: 6,
                    fillColor: COLORS.transparent,
                    strokeColor: COLORS.white,
                },
                zIndex: 500,
            }, PlayerEndCard: {
                alpha: 0,
                type: EndCard,
            } });
    }
    _init() {
        super._init();
        this._endCard = this.tag('PlayerEndCard');
        this._image = this.tag('Image');
        this._miniPlayerFocusRect = this.tag('MiniPlayerFocusRect');
    }
    $trackEndCardSelection(endCardSelected, isDefault, isAutoPlay) {
        this._trackEndCard({
            destination: endCardSelected,
            isDefault,
            isAutoPlay,
            isEndCardSelected: true,
        });
    }
    _trackEndCard({ destination = null, isDefault = false, isAutoPlay = false, isRestarting = false, isEndCardSelected = false, }) {
        var _a, _b;
        const endCardSelectionTimeStamp = moment();
        (_b = (_a = this._analyticsDelegate) === null || _a === void 0 ? void 0 : _a.fireEndCard) === null || _b === void 0 ? void 0 : _b.call(_a, Object.assign(Object.assign({ endCardTime: this._autoplayTime, destination: destination, isEndCardSelected: isEndCardSelected, isAutoPlay,
            isDefault,
            isRestarting, duration: endCardSelectionTimeStamp.diff(this._endCardImpressionTimeStamp, 'seconds') }, this._endCard.getAnalyticsData()), { shelf: getAdjustedReferringShelf() }));
    }
    _trackBack(_) {
        this._closeMediaPlayer();
        this._endCard.transparent = false;
        this._trackEndCard({ isRestarting: false });
    }
    _closeEndCard() {
        ModalManager.update(ModalTypes.PLAYER_LOADER, {}, true);
        this._endCard.alpha = 0;
        this._endCardsShown = false;
        this._endCard.transparent = false;
        this._endCard.transparentBG = false;
        this._endCard.SFVOD_BG = false;
        this._trackEndCard({ isRestarting: false });
        if (this._player)
            this._setState(PlayerStates.LPPlayer);
    }
    _additionalUIUpdates({ endCardTransparency = false, endCardBGTransparency = false, endCardOlyTransparency = false, endCardEmptyBGTransparency = false, }) {
        this._endCard.transparent = endCardTransparency;
        this._endCard.transparentBG = endCardBGTransparency;
        this._endCard.SFVOD_BG = endCardOlyTransparency;
        this._endCard.EMPTY_SLE_BG = endCardEmptyBGTransparency;
        if (endCardEmptyBGTransparency) {
            this._endCard.SHOW_HIDE_TILES = false;
        }
        if (this === null || this === void 0 ? void 0 : this._skipButton)
            this._skipButton.alpha = 0;
        this._endCard.alpha = 1;
        this._endCardsShown = true;
        this._controls.alpha = 0;
        this._captions.alpha = 0;
        this._mediaOptionsPanel._reset();
        this._mediaOptionsPanel._showMediaOptionsMenu(false);
    }
};
