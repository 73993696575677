import { isEmpty, cloneDeep } from 'lodash';
import { isSingleStream, PlayerStoreActions, updateProgram, updateStream, } from './actions';
import BaseStore from '../BaseStore';
import { filterByChannelId } from './actions/epg';
import { filterEPGStreamsByRSN, filterScheduleByRSN } from '../../graphql/filter/rsnFilter';
const NOT_ALLOWED_CHANNELS = new Set(['nbcswashington']);
const defaultState = {
    epg: {
        index: 0,
        programIndex: 0,
        start: '',
        slots: [],
        streams: [],
        schedules: [],
        events: [],
        upcoming: null,
    },
    stream: {},
    program: {},
    lemonade: undefined,
    geo: {},
    horizontalScrollIndex: 0,
    preCheckData: {
        lemonade: undefined,
        stream: undefined,
        program: undefined,
    },
};
export var PlayerStoreEvents;
(function (PlayerStoreEvents) {
    PlayerStoreEvents["STREAM_OK"] = "streamOk";
    PlayerStoreEvents["EPG_OK"] = "epgOk";
    PlayerStoreEvents["PRE_CHECK_DATA_OK"] = "preCheckDataOk";
    PlayerStoreEvents["EPG_ERROR"] = "epgError";
    PlayerStoreEvents["EPG_CHANNEL_UPDATED"] = "epgChannelUpdated";
    PlayerStoreEvents["LEMONADE_OK"] = "lemonadeOk";
    PlayerStoreEvents["ERROR"] = "error";
})(PlayerStoreEvents || (PlayerStoreEvents = {}));
class PlayerStore extends BaseStore {
    constructor() {
        super(...arguments);
        this.state = cloneDeep(defaultState);
    }
    get stream() {
        const singleStream = this.state.stream;
        const epgStream = this.state.epg.streams[this.state.epg.index];
        return isSingleStream(singleStream) ? singleStream : epgStream;
    }
    epgData(streamAccessName) {
        var _a, _b, _c, _d;
        const index = this.state.epg.streams.findIndex((stream) => (stream === null || stream === void 0 ? void 0 : stream.streamAccessName) === streamAccessName);
        return {
            stream: ((_a = this.state.epg.streams) === null || _a === void 0 ? void 0 : _a[index]) || {},
            program: ((_d = (_c = (_b = this.state.epg.schedules) === null || _b === void 0 ? void 0 : _b[index]) === null || _c === void 0 ? void 0 : _c.programs) === null || _d === void 0 ? void 0 : _d[this.state.epg.programIndex]) || {},
        };
    }
    get streamIndex() {
        return this.state.epg.index || 0;
    }
    get program() {
        return this.state.program;
    }
    get programIndex() {
        return this.state.epg.programIndex || 0;
    }
    get lemonade() {
        return this.state.lemonade;
    }
    get preCheckData() {
        return this.state.preCheckData;
    }
    get geo() {
        return this.state.geo;
    }
    get epg() {
        return this.state.epg;
    }
    async dispatch(action) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s;
        try {
            const result = await this._getActionResult(action);
            const { type, payload } = result;
            switch (type) {
                case PlayerStoreActions.CLEAR_STATE: {
                    const preCheckData = this.state.preCheckData;
                    this.state = cloneDeep(defaultState);
                    this.state.preCheckData = preCheckData;
                    break;
                }
                case PlayerStoreActions.SET_PRE_CHECK_LEMONADE:
                    this.state.preCheckData.lemonade = payload;
                    break;
                case PlayerStoreActions.CLEAR_PRE_CHECK_LEMONADE:
                    delete this.state.preCheckData.lemonade;
                    break;
                case PlayerStoreActions.SET_GEO:
                    this.state.geo = payload;
                    return await this.dispatch(updateStream({
                        authKill: !((_b = (_a = payload === null || payload === void 0 ? void 0 : payload.assetInfo) === null || _a === void 0 ? void 0 : _a.authRequired) !== null && _b !== void 0 ? _b : true),
                    }));
                case PlayerStoreActions.SET_PRE_CHECK_DATA:
                    this.state.preCheckData.stream = payload.stream;
                    this.state.preCheckData.program = payload.program;
                    break;
                case PlayerStoreActions.CLEAR_PRE_CHECK_DATA:
                    delete this.state.preCheckData.stream;
                    delete this.state.preCheckData.program;
                    break;
                case PlayerStoreActions.SET_EPG: {
                    if (payload.streams.length && payload.start !== this.state.epg.start) {
                        const streams = filterEPGStreamsByRSN(payload.streams.filter((stream) => !NOT_ALLOWED_CHANNELS.has(stream.channelId)));
                        const schedules = filterScheduleByRSN(payload.schedules.filter(
                        // @ts-expect-error TS(2339): Property 'channelId' does not exist on type 'never... Remove this comment to see the full error message
                        ({ programs = [] }) => { var _a; return !NOT_ALLOWED_CHANNELS.has((_a = programs[0]) === null || _a === void 0 ? void 0 : _a.channelId); }));
                        if (streams.length && schedules.length) {
                            this.state.epg = Object.assign(Object.assign({}, payload), { streams,
                                schedules, index: ((_c = this.state.epg) === null || _c === void 0 ? void 0 : _c.index) || payload.index });
                            if (!this.state.epg.index) {
                                if (streams.length && schedules.length) {
                                    this.state.epg.index = ((_d = this.state.epg) === null || _d === void 0 ? void 0 : _d.streams.findIndex(filterByChannelId(this.state.stream.channelId, this.state.stream.streamAccessName, this.state.stream.machineName || '')));
                                    this.events.next({
                                        type: PlayerStoreEvents.EPG_CHANNEL_UPDATED,
                                        payload: {
                                            stream: this.stream,
                                            program: this.program,
                                            streamIndex: this.state.epg.index,
                                        },
                                    });
                                }
                            }
                            this.events.next({
                                type: PlayerStoreEvents.EPG_OK,
                                payload,
                            });
                        }
                        else {
                            this.events.next({
                                type: PlayerStoreEvents.EPG_ERROR,
                                payload: [],
                            });
                        }
                    }
                    else if (!payload.streams.length) {
                        this.events.next({
                            type: PlayerStoreEvents.EPG_ERROR,
                            payload: [],
                        });
                    }
                    break;
                }
                case PlayerStoreActions.SET_SINGLE_STREAM: {
                    this.state.stream = payload.stream;
                    if (payload.stream.secondaryTitle !== undefined) {
                        this.state.stream.streamSecondaryTitle = payload.stream.secondaryTitle;
                    }
                    this.state.program = payload.program;
                    if ((_f = (_e = this.state.epg) === null || _e === void 0 ? void 0 : _e.streams) === null || _f === void 0 ? void 0 : _f.length) {
                        const streamIndexFromResponse = ((_g = this.state.epg) === null || _g === void 0 ? void 0 : _g.streams.findIndex(filterByChannelId(this.state.stream.channelId, this.state.stream.streamAccessName, this.state.stream.machineName || '')));
                        if (streamIndexFromResponse !== this.state.epg.index) {
                            this.state.epg.index = streamIndexFromResponse;
                            this.events.next({
                                type: PlayerStoreEvents.EPG_CHANNEL_UPDATED,
                                payload: {
                                    stream: this.stream,
                                    program: this.program,
                                    streamIndex: this.state.epg.index,
                                },
                            });
                        }
                    }
                    this.events.next({
                        type: PlayerStoreEvents.STREAM_OK,
                        payload,
                    });
                    break;
                }
                case PlayerStoreActions.SET_LEMONADE:
                    this.state.lemonade = payload;
                    if (payload.adCompatibilityEncodingProfile) {
                        await this.dispatch(updateProgram({
                            adCompatibilityEncodingProfile: payload.adCompatibilityEncodingProfile,
                        }));
                    }
                    this.events.next({
                        type: PlayerStoreEvents.LEMONADE_OK,
                        payload,
                    });
                    break;
                case PlayerStoreActions.CLEAR_LEMONADE:
                    delete this.state.lemonade;
                    break;
                case PlayerStoreActions.ERROR:
                    return Promise.reject(result);
                case PlayerStoreActions.UPDATE_STREAM:
                    if (!isEmpty(this.state.stream)) {
                        this.state.stream = Object.assign(Object.assign({}, this.state.stream), payload);
                    }
                    else if (!isEmpty(this.state.epg.streams[this.state.epg.index])) {
                        this.state.epg.streams[this.state.epg.index] = Object.assign(Object.assign({}, this.state.epg.streams[this.state.epg.index]), payload);
                    }
                    break;
                case PlayerStoreActions.UPDATE_PROGRAM:
                    if (!isEmpty(this.state.program)) {
                        this.state.program = Object.assign(Object.assign({}, this.state.program), payload);
                    }
                    else if (!isEmpty((_j = (_h = this.state.epg.schedules[this.state.epg.index]) === null || _h === void 0 ? void 0 : _h.programs) === null || _j === void 0 ? void 0 : _j[this.state.epg.programIndex])) {
                        const programs = (_m = (_l = (_k = this.state.epg) === null || _k === void 0 ? void 0 : _k.schedules) === null || _l === void 0 ? void 0 : _l[this.state.epg.index]) === null || _m === void 0 ? void 0 : _m.programs;
                        if (programs) {
                            programs[this.state.epg.programIndex] = Object.assign(Object.assign({}, programs === null || programs === void 0 ? void 0 : programs[this.state.epg.programIndex]), payload);
                        }
                    }
                    break;
                case PlayerStoreActions.UPDATE_EPG_CHANNEL_INDEX:
                    this.state.epg.index = payload;
                    this.state.program = Object.assign({}, (_p = (_o = this.state.epg.schedules[payload]) === null || _o === void 0 ? void 0 : _o.programs) === null || _p === void 0 ? void 0 : _p[this.state.epg.programIndex]);
                    this.state.stream = Object.assign(Object.assign({}, this.state.epg.streams[payload]), this.state.program);
                    this.events.next({
                        type: PlayerStoreEvents.EPG_CHANNEL_UPDATED,
                        payload: {
                            stream: this.stream,
                            program: this.program,
                            streamIndex: this.state.epg.index,
                        },
                    });
                    break;
                case PlayerStoreActions.NEXT_EPG_SCHEDULE_INDEX: {
                    const schedules = this.state.epg.schedules[this.state.epg.index];
                    if ((((_q = schedules === null || schedules === void 0 ? void 0 : schedules.programs) === null || _q === void 0 ? void 0 : _q.length) || 0) > this.state.epg.programIndex + 1) {
                        this.state.epg.programIndex += 1;
                        this.state.program = Object.assign({}, (_r = schedules === null || schedules === void 0 ? void 0 : schedules.programs) === null || _r === void 0 ? void 0 : _r[this.state.epg.programIndex]);
                        this.state.stream = Object.assign(Object.assign({}, (_s = this.state.epg.streams) === null || _s === void 0 ? void 0 : _s[this.state.epg.index]), this.state.program);
                        return this.program;
                    }
                    break;
                }
                default:
                    break;
            }
            return result;
        }
        catch (e) {
            return Promise.reject(e);
        }
    }
}
const PlayerStoreSingleton = new PlayerStore();
export default PlayerStoreSingleton;
