import { Subject } from 'rxjs';
import { AdobeAuthenticationService } from './adobe/AdobeAuthenticationService';
import { clearPollingTimeOuts, pollIDM } from '../api/Identity';
import UserInteractionsStoreSingleton from '../store/UserInteractions';
import { fetchUserInteractions } from '../store/UserInteractions/actions';
import { ACTIVATION_TYPE } from '../widgets/Modals/activation/constants';
const AuthenticationSingleton = new AdobeAuthenticationService();
class AuthenticationEventsManager {
    constructor() {
        this._totalPolls = 0;
        this._subject = new Subject();
    }
    get totalPolls() {
        return this._totalPolls;
    }
    set totalPolls(value) {
        this._totalPolls = value < 0 ? 0 : value;
    }
    async pollIDM(deviceCode) {
        try {
            this.totalPolls++;
            await pollIDM(deviceCode, this._totalPolls > 1);
            this.totalPolls--;
            await UserInteractionsStoreSingleton.dispatch(fetchUserInteractions(true));
            this._subject.next(ACTIVATION_TYPE.NBC);
        }
        catch (error) {
            this.totalPolls--;
            console.warn('error', error);
        }
    }
    async pollAdobe() {
        try {
            this.totalPolls++;
            await AuthenticationSingleton.pollStatus();
            this._subject.next(AuthenticationSingleton.isMvpdTempPass() ? ACTIVATION_TYPE.TEMPPASS : ACTIVATION_TYPE.MVPD);
        }
        catch (error) {
            this.totalPolls--;
            console.warn('error', error);
        }
    }
    stopPolling(type) {
        if (!type || type === ACTIVATION_TYPE.NBC) {
            clearPollingTimeOuts();
            this.totalPolls--;
        }
        if (!type || type === ACTIVATION_TYPE.MVPD) {
            AuthenticationSingleton._clearPollingTimeOuts();
            this.totalPolls--;
        }
    }
    subscribe(cb) {
        return this._subject.subscribe(cb);
    }
}
export const AuthenticationEvents = new AuthenticationEventsManager();
export default AuthenticationSingleton;
