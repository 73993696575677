import { Lightning, Router } from '@lightningjs/sdk';
import RouterUtil from '../../util/RouterUtil';
import LinksSelectableGroup from '../../components/LinksSelectableGroup';
import { BrandSelectableSpawner } from '../../api/spawners';
import { sendMetric } from '../../lib/analytics/Analytics';
import { pushHash } from '../../helpers';
import { setSmooth } from '../../helpers';
import { EVENTS } from '../../lib/analytics/types';
import { COLORS, ROUTE, ShowsStates } from '../../constants';
// states
import { BrandsLabelsStateFactory } from './states/BrandsLabelsStateFactory';
import { LinksSelectableStateFactory } from './states/LinksSelectableStateFactory';
import { LoadSelectedBrandStateFactory } from './states/LoadSelectedBrandStateFactory';
import { useRequest } from '../../lib/useRequest';
import { ShowsPageRequestConfig } from './request';
import { WithRequestError, WithRequestErrorState } from '../hoc/withRequestError';
class Shows extends Lightning.Component {
    constructor() {
        super(...arguments);
        this._selectedBrandIndex = 0;
        this.sections = [];
    }
    static _template() {
        return {
            BrandLabelsList: {},
            LinksSelectableGroup: {
                x: 75,
                y: 386,
                type: LinksSelectableGroup,
                alpha: 0,
            },
        };
    }
    _active() {
        this.stage.setClearColor(COLORS.dark);
        if (this.sections) {
            setSmooth(this.widgets.loader, 'visible', 0);
        }
        if (!this._initialBrand) {
            const hash = Router.getActiveHash() || '';
            const [base = ''] = hash.split('/');
            pushHash(`${base}/all`);
        }
    }
    async load() {
        setSmooth(this.widgets.loader, 'visible', 1);
        try {
            const data = (await useRequest(ShowsPageRequestConfig()).fetch());
            this._brandSelectableSpawner = new BrandSelectableSpawner(data.sections);
            this.brandLabelsList = this._brandSelectableSpawner.createBrandLabelsList(this.stage, this._initialBrand);
            if (this._brandSelectableSpawner.initiallySelected !== 0)
                this.brandLabelsList.setIndex(this._brandSelectableSpawner.initiallySelected);
            this._selectedBrandIndex = this._brandSelectableSpawner.initiallySelected;
            this.sections = data.sections;
            this._setState(ShowsStates.LoadSelectedBrandData);
        }
        catch (e) {
            this._setState(WithRequestErrorState);
        }
        finally {
            setSmooth(this.widgets.loader, 'visible', 0);
        }
    }
    set params(params) {
        this._initialBrand = params === null || params === void 0 ? void 0 : params.brand;
        this._initialCategory = params === null || params === void 0 ? void 0 : params.category;
    }
    set brandLabelsList(v) {
        v.x = 75;
        v.y = 143;
        this.patch({
            BrandLabelsList: v,
        });
    }
    get brandLabelsList() {
        return this.tag('BrandLabelsList');
    }
    get linksSelectableGroup() {
        return this.tag('LinksSelectableGroup');
    }
    get currentBrand() {
        var _a, _b, _c, _d, _e, _f, _g;
        const brand = ((_g = (_f = (_e = (_d = (_b = (_a = this.brandLabelsList) === null || _a === void 0 ? void 0 : _a.items) === null || _b === void 0 ? void 0 : _b[((_c = this.brandLabelsList) === null || _c === void 0 ? void 0 : _c._activeIndex) || 0]) === null || _d === void 0 ? void 0 : _d.item) === null || _e === void 0 ? void 0 : _e.obj) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.machineName) || '';
        return brand.replace('-', '').toLowerCase();
    }
    get currentCategory() {
        return this._currentCategory || '';
    }
    set currentCategory(category) {
        this._currentCategory = category;
    }
    _parseCategory(category) {
        return (category || '').replace(/&/g, '-').replace(/\s+/g, '').toLowerCase();
    }
    $selectedLinkChanged(category) {
        var _a;
        const hash = Router.getActiveHash() || '';
        const [base = ''] = hash.split('/');
        if (base) {
            this.currentCategory = category.new;
            pushHash(`${base}/${this.currentBrand}/${this._parseCategory(category.new)}`);
        }
        // Fire mparticle event as if Category change were a page view.
        sendMetric(EVENTS.PAGE_LOAD, Object.assign(Object.assign({}, RouterUtil.showsBrandRoutingData(category.new, category.prev, true, (_a = this._brandSelectableSpawner) === null || _a === void 0 ? void 0 : _a.getBrandLabel(this._selectedBrandIndex))), { path: ROUTE.shows }));
        sendMetric(EVENTS.CLICK, {
            name: `Category Logo Click (${category.new})`,
        });
    }
    historyState(params) {
        if (params) {
            this._initialCategory = params.category;
            this._initialBrand = params.brand;
        }
        else {
            return {
                brand: this.currentBrand,
                category: this._parseCategory(this.currentCategory),
            };
        }
    }
    static _states() {
        return [
            BrandsLabelsStateFactory(this),
            LinksSelectableStateFactory(this),
            LoadSelectedBrandStateFactory(this),
        ];
    }
}
export default WithRequestError(Shows);
