export default class Announce {
    constructor(toSpeak, notification = false) {
        this._notification = false;
        this.reduceSeries = (acc, curr) => {
            let next;
            if (Array.isArray(curr)) {
                next = curr.reduce(this.reduceSeries, acc);
            }
            else if (typeof curr === 'function') {
                const funcExecuted = curr();
                //@ts-expect-error TS2349: this expression is not callable
                next = (Array.isArray(funcExecuted) ? funcExecuted : [funcExecuted]).reduce(this.reduceSeries, acc);
            }
            else {
                next = acc += (acc.length ? ' ' : '') + curr + '\n';
            }
            return next;
        };
        this._notification = notification;
        const seriesArray = Array.isArray(toSpeak) ? toSpeak : [toSpeak];
        this._phrase = seriesArray.reduce(this.reduceSeries, '');
    }
    get phrase() {
        return this._phrase;
    }
    get notification() {
        return this._notification;
    }
}
