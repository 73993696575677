import { Subject } from 'rxjs';
import TVPlatform from '../tv-platform';
import { ErrorType } from '../tv-platform/types';
import { getBannerPreferenceHeaders, getSaveApiHeaders } from './headers';
import { Storage } from '@lightningjs/sdk';
import { STORAGE_KEYS } from '../../constants';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
var CMPEventType;
(function (CMPEventType) {
    CMPEventType["BANNER"] = "banner_ui";
    CMPEventType["PREFERENCE"] = "preference";
    CMPEventType["SAVE"] = "save";
})(CMPEventType || (CMPEventType = {}));
export var CMPInteractionType;
(function (CMPInteractionType) {
    CMPInteractionType["BANNER_ALLOW_ALL"] = "BANNER_ALLOW_ALL";
    CMPInteractionType["BANNER_REJECT_ALL"] = "BANNER_REJECT_ALL";
    CMPInteractionType["BANNER_CLOSE"] = "BANNER_CLOSE";
    CMPInteractionType["BANNER_CONTINUE_WITHOUT_ACCEPTING"] = "BANNER_CONTINUE_WITHOUT_ACCEPTING";
    CMPInteractionType["PREFERENCE_CENTER_ALLOW_ALL"] = "PREFERENCE_CENTER_ALLOW_ALL";
    CMPInteractionType["PREFERENCE_CENTER_REJECT_ALL"] = "PREFERENCE_CENTER_REJECT_ALL";
    CMPInteractionType["PREFERENCE_CENTER_CONFIRM"] = "PREFERENCE_CENTER_CONFIRM";
    CMPInteractionType["PREFERENCE_CENTER_CLOSE"] = "PREFERENCE_CENTER_CLOSE";
    CMPInteractionType["VENDOR_LIST_ALLOW_ALL"] = "VENDOR_LIST_ALLOW_ALL";
    CMPInteractionType["VENDOR_LIST_REJECT_ALL"] = "VENDOR_LIST_REJECT_ALL";
    CMPInteractionType["VENDOR_LIST_CONFIRM"] = "VENDOR_LIST_CONFIRM";
    CMPInteractionType["SDK_LIST_ALLOW_ALL"] = "SDK_LIST_ALLOW_ALL";
    CMPInteractionType["SDK_LIST_REJECT_ALL"] = "SDK_LIST_REJECT_ALL";
    CMPInteractionType["SDK_LIST_CONFIRM"] = "SDK_LIST_CONFIRM";
    CMPInteractionType["SYNC_PROFILE"] = "SYNC_PROFILE";
})(CMPInteractionType || (CMPInteractionType = {}));
class ConsentManagementPlatform {
    constructor() {
        this._subject = new Subject();
    }
    get storageKeys() {
        return Storage.get(STORAGE_KEYS.CMP_STORAGE_KEYS) || {};
    }
    set storageKeys(v) {
        Storage.set(STORAGE_KEYS.CMP_STORAGE_KEYS, v);
    }
    get consentString() {
        return Storage.get(STORAGE_KEYS.CMP_CONSENT_STRING) || '';
    }
    set consentString(v) {
        Storage.set(STORAGE_KEYS.CMP_STORAGE_KEYS, v);
    }
    async sync() {
        try {
            await Promise.all([this._fetchBannerUi(), this._fetchPreferenceCenter()]);
        }
        catch (e) {
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                description: 'Failure loading OneTrust API',
                payload: e,
            });
            // fail silently
        }
    }
    subscribe(cb) {
        return this._subject.subscribe(cb);
    }
    async save(body) {
        try {
            if (!AppConfigFactorySingleton.config.one_trust_id)
                throw new Error('CMP:: undefined app id');
            const response = await fetch(AppConfigFactorySingleton.config.one_trust_save_endpoint, {
                method: 'POST',
                headers: getSaveApiHeaders(),
                body: JSON.stringify(body),
            });
            const json = await response.json();
            this.storageKeys = json.storageKeys;
            this.consentString = json.otConsentString;
            return json;
        }
        catch (e) {
            this.storageKeys = {};
            this.consentString = '';
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                payload: e,
                description: 'Error saving CMP Save and Log consent data',
            });
            throw e;
        }
    }
    async _fetchBannerUi() {
        try {
            if (!AppConfigFactorySingleton.config.one_trust_id)
                throw new Error('CMP:: undefined app id');
            const response = await fetch(AppConfigFactorySingleton.config.one_trust_banner_endpoint, {
                headers: getBannerPreferenceHeaders(this.consentString),
            });
            const json = await response.json();
            this.storageKeys = json.storageKeys;
            this.consentString = json.otConsentString;
            this._subject.next(json);
            // TODO adjust types to actual response (type is modeled from docs data)
            return json;
        }
        catch (e) {
            this.storageKeys = {};
            this.consentString = '';
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                payload: e,
                description: 'Error requesting CMP BannerUI API',
            });
            throw e;
        }
    }
    async _fetchPreferenceCenter() {
        try {
            if (!AppConfigFactorySingleton.config.one_trust_id)
                throw new Error('CMP:: undefined app id');
            const response = await fetch(AppConfigFactorySingleton.config.one_trust_preference_endpoint, {
                headers: getBannerPreferenceHeaders(this.consentString),
            });
            const json = await response.json();
            this.storageKeys = json.storageKeys;
            this.consentString = json.otConsentString;
            this._subject.next(json);
            // TODO adjust types to actual response (type is modeled from docs data)
            return json;
        }
        catch (e) {
            this.storageKeys = {};
            this.consentString = '';
            TVPlatform.reportError({
                type: ErrorType.NETWORK,
                payload: e,
                description: 'Error requesting CMP Preference Center API',
            });
            throw e;
        }
    }
}
export const ConsentManagement = new ConsentManagementPlatform();
