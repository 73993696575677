import { Language, Router, Utils, Storage } from '@lightningjs/sdk';
import SettingsButton from '../../../components/buttons/SettingsButton';
import BaseComponent from '../../../components/base';
import { COLORS, FLEX_DIRECTION, FONT_FACE, ROUTE } from '../../../constants';
import { getPrivacyMenuItemsByBrand } from './privacyItemsHelper';
export default class Privacy extends BaseComponent {
    static _template() {
        return {
            x: 80,
            y: 200,
            w: 1760,
            h: 810,
            rect: true,
            color: COLORS.dark5,
            PageTitleHolder: {
                x: 100,
                y: 78,
                Icon: {
                    h: 40,
                    w: 40,
                    src: Utils.asset('images/settings/privacy_shield.png'),
                },
                Title: {
                    x: 60,
                    text: {
                        text: Language.translate('do_not_sell_my_personal_information'),
                        fontSize: 30,
                        fontFace: FONT_FACE.light,
                    },
                },
            },
            Content: {
                y: 178,
                x: 233,
                CaptionOptionButtons: {
                    flex: { direction: FLEX_DIRECTION.column },
                },
            },
        };
    }
    _init() {
        this.stage.setClearColor(COLORS.dark);
        this.createButtons();
        this._index = Storage.get('PrivacyIndex') || 0;
    }
    _active() {
        this.widgets.menu.collapse({
            subCategoryItem: Language.translate('do_not_sell_my_personal_information'),
            hideProfile: true,
        });
        this._setState('LanguageButton');
    }
    _inactive() {
        this.widgets.menu.expand();
    }
    _setTranslation() {
        const privacyText = Language.translate('do_not_sell_my_personal_information');
        this.tag('PageTitleHolder.Title').patch({ text: { text: privacyText } });
        this.announce = privacyText;
    }
    createButtons() {
        var _a;
        this._items = getPrivacyMenuItemsByBrand();
        this.tag('CaptionOptionButtons').patch({
            children: ((_a = this._items) === null || _a === void 0 ? void 0 : _a.length)
                ? this._items.map((item) => {
                    return {
                        flexItem: { marginTop: item.marginTop ? item.marginTop : 10 },
                        w: 740,
                        h: 70,
                        type: SettingsButton,
                        radius: 0,
                        fontSize: 30,
                        fontFace: FONT_FACE.light,
                        focusFontColor: COLORS.dark,
                        unfocusFontColor: COLORS.white,
                        focusBackGroundColor: COLORS.lightGray3,
                        unfocusBackgroundColor: COLORS.black3,
                        label: Language.translate(item.label),
                        padding: 0,
                        icon: item.icon,
                        privacyIcon: item.privacyIcon,
                    };
                })
                : [],
        });
    }
    get focusItem() {
        return this.getChildItem(this._index);
    }
    getChildItem(index = 0) {
        return this.tag('CaptionOptionButtons').children[index];
    }
    _getFocused() {
        return this.focusItem || this;
    }
    _handleUp() {
        if (this._index > 0) {
            this._index--;
        }
    }
    _handleDown() {
        if (this._index < this.tag('CaptionOptionButtons').children.length - 1) {
            this._index++;
        }
    }
    _handleBack(e) {
        e.preventDefault();
        // destroy the stored index when we leave the About Settings page
        Storage.remove('PrivacyIndex');
        Router.navigate(ROUTE.settings, { reload: true }, false);
    }
    _handleEnter() {
        const item = this._items[this._index];
        // Save index for returning.
        Storage.set('PrivacyIndex', this._index);
        const route = 'longtext';
        Router.navigate(route, {
            url: item.text[Language.get()],
            subcat: item.label,
            bustCache: item.bustCache,
        });
    }
}
