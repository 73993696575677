import { GraphQlClient } from '../../graphql/client';
import featuredSectionMapper from '../../graphql/mappers/featuredSection';
import { getMpid } from '../../helpers';
import rsnFilter from '../../graphql/filter/rsnFilter';
import { QueryName } from './bffQuery';
const featuredRequest = async (variables, options) => {
    return rsnFilter(featuredSectionMapper(await GraphQlClient.query({
        query: QueryName.featuredSection,
        variables: Object.assign(Object.assign({}, variables), (!variables.userId ? { userId: getMpid() } : {})), // todo: maybe merge with default variables for this page
    }, options)));
};
export default featuredRequest;
