import { Registry, Storage } from '@lightningjs/sdk';
import moment from 'moment-timezone';
import { getTimeDiff, getTimeDiffAsMilliseconds } from './date';
import { MAX_TIME_OUT, PROGRAMMING_TYPES, STORAGE_KEYS, STREAM_ON_PEACOCK } from '../constants';
import RouterUtil from '../util/RouterUtil';
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags';
export const getCurrentProgressWidth = ({ startTime, endTime, totalProgressWidth }) => {
    const totalDuration = getTimeDiff(startTime, endTime);
    const diffTime = getTimeDiff(moment(), endTime);
    let currentProgressWidth = ((totalDuration - diffTime) * totalProgressWidth) / totalDuration;
    currentProgressWidth =
        currentProgressWidth > totalProgressWidth ? totalProgressWidth : currentProgressWidth;
    return currentProgressWidth;
};
export const getTimerToEndTile = (startTime, callback) => {
    const timeToLive = getTimeDiffAsMilliseconds(moment(), startTime);
    if (timeToLive <= MAX_TIME_OUT) {
        return Registry.setTimeout(callback, timeToLive);
    }
};
export const getTimerToRemoveItemWhenEnd = (endTime, callback, params) => {
    const endTimeOutDiff = getTimeDiffAsMilliseconds(moment(), endTime);
    if (endTimeOutDiff <= MAX_TIME_OUT) {
        Registry.setTimeout(callback, endTimeOutDiff, params);
    }
};
export const getAdjustedReferringShelf = () => {
    const storageReferringShelf = Storage.get(STORAGE_KEYS.REFERRING_SHELF);
    if (!storageReferringShelf)
        return {};
    const { shelfIndex, tileIndex } = storageReferringShelf;
    return Object.assign(Object.assign({}, storageReferringShelf), { shelfIndex: typeof shelfIndex === 'number' ? shelfIndex + (RouterUtil.isComingFromHomePage() ? 2 : 1) : 0, tileIndex: typeof tileIndex === 'number' ? tileIndex + 1 : 0 });
};
export const isFullEpisode = (programmingType) => {
    return programmingType === PROGRAMMING_TYPES.FULL_EPISODE;
};
export const isExternalLinkingAllowed = () => {
    const value = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.externalAppLinking);
    return value === STREAM_ON_PEACOCK;
};
export const isSleLiveGuideAllowed = () => LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.sleLiveGuide) ||
    LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.sleLiveGuideShelf) ||
    false;
