import { Lightning } from '@lightningjs/sdk';
import { Paragraph } from '../index';
import { updateElementH } from '../../helpers/template';
export default class AboutTextComponent extends Lightning.Component {
    static _template() {
        return {
            Title: {
                type: Paragraph,
                paragraph: {},
                h: 0,
            },
            Body: {
                type: Paragraph,
                paragraph: {},
                h: 0,
            },
            Items: {
                h: 0,
            },
        };
    }
    constructor(stage) {
        super(stage);
    }
    set title(v) {
        const titleStyle = Object.assign({}, this.titleStyle);
        this.tag('Title').paragraph = Object.assign({ text: v, w: this.w }, titleStyle);
        updateElementH(this);
    }
    set content(v) {
        const contentStyle = Object.assign({}, this.contentStyle);
        this.tag('Body').paragraph = Object.assign({ text: v, w: this.w }, contentStyle);
        updateElementH(this);
    }
    set items(v) {
        const items = this.tag('Items');
        if (v === undefined) {
            items.children = [];
            return;
        }
        items.children = v.map((item) => {
            return {
                w: this.w,
                type: AboutTextComponent,
                contentStyle: this.contentStyle,
                content: item,
            };
        });
        updateElementH(items);
        updateElementH(this);
    }
}
