import { Utils } from '@lightningjs/sdk';
import Button from './Button';
export default class ImageButton extends Button {
    static _template() {
        return Object.assign(Object.assign({}, super._template()), { Icon: {
                mount: 0.5,
            } });
    }
    set icon(icon) {
        this.patch({
            Icon: {
                src: Utils.asset(icon),
            },
        });
        if (this._autoWidth) {
            this._explicitWidth = this.tag('Icon').renderWidth;
        }
        else {
            this._explicitWidth = this.w;
        }
        this._calculateButtonWidth();
    }
    _focus() {
        super._focus();
        this.tag('Icon').patch({
            color: this._focusBackGroundColor,
        });
    }
    _unfocus() {
        super._unfocus();
        this.tag('Icon').patch({
            color: this._unfocusBackgroundColor,
        });
    }
}
