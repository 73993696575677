import { ACTIVATION_REFERRER } from '../constants';
import { ActivationStrategy } from './ActivationStrategy';
import { EventActivationStrategy } from './EventActivationStrategy';
import { LinearActivationStrategy } from './LinearActivationStrategy';
import { TempPassActivationStrategy } from './TempPassActivationStrategy';
import { VODActivationStrategy } from './VODActivationStrategy';
export const getActivationStrategy = (ctx, referrerType) => {
    switch (referrerType) {
        case ACTIVATION_REFERRER.EVENT:
            return new EventActivationStrategy(ctx);
        case ACTIVATION_REFERRER.LINEAR:
            return new LinearActivationStrategy(ctx);
        case ACTIVATION_REFERRER.VOD:
            return new VODActivationStrategy(ctx);
        case ACTIVATION_REFERRER.TEMPPASS:
            return new TempPassActivationStrategy(ctx);
        default:
            return new ActivationStrategy(ctx);
    }
};
