import { Lightning } from '@lightningjs/sdk';
import Item from './Item';
import { labelBadgeDecorator } from './decorators';
import { COLORS, ENTITY_TYPES, FONT_FACE, ROUTE, ROUTE_ENTITY } from '../../constants';
import { FastImg } from '../../helpers';
import RouterUtil from '../../util/RouterUtil';
import { OLY_IMPOLICY } from '../../constants/images';
export default class MovieTile extends Item {
    _init() {
        super._init();
        this.containerHeight = 537;
        this.containerWidth = 330;
    }
    static _template() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        const duration = super.duration;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        const timingFunction = super.timingFunction;
        const template = super._template();
        return Object.assign(Object.assign({}, template), { ShadowBlur: {
                mount: 0.5,
                x: 165,
                y: 220,
                w: 430,
                h: 500,
                alpha: 0.8,
                type: Lightning.components.FastBlurComponent,
                amount: 1,
                content: {
                    Shadow: {
                        color: COLORS.black,
                        mount: 0.5,
                        x: 215,
                        y: 250,
                        texture: Lightning.Tools.getRoundRect(330, 440, 0),
                    },
                },
                transitions: {
                    y: { duration, timingFunction },
                    scale: { duration, timingFunction },
                    alpha: { duration, timingFunction },
                    amount: { duration, timingFunction },
                },
            }, ImageHolder: {
                rect: true,
                colorLeft: COLORS.brightGrey,
                colorRight: COLORS.thunder2,
                Image: {
                    pivotX: 0,
                    transitions: {
                        scale: { duration, timingFunction },
                    },
                },
            }, Content: {
                y: 450,
                TitleCard: {
                    ShortTitle: {
                        text: {
                            fontFace: FONT_FACE.semiBold,
                            fontSize: 26,
                            lineHeight: 32,
                            maxLines: 3,
                            wordWrapWidth: 270,
                        },
                    },
                    transitions: {
                        y: { duration, timingFunction },
                    },
                },
                Logo: {
                    y: 4,
                    mountX: 1,
                    x: 330,
                    transitions: {
                        y: { duration, timingFunction },
                    },
                },
            } });
    }
    set item(v) {
        var _a;
        this._item = v;
        this.patch({
            ImageHolder: {
                h: 440,
                w: 330,
                Image: { texture: FastImg(v.posterImage).contain(330, 440, OLY_IMPOLICY.PORTRAIT) },
            },
            Content: {
                TitleCard: {
                    ShortTitle: {
                        text: { text: v.title },
                    },
                },
                Logo: { texture: FastImg(v.logo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216) },
            },
        });
        this._addDecorators([labelBadgeDecorator((_a = this._item) === null || _a === void 0 ? void 0 : _a.labelBadge)]);
    }
    _handleEnter() {
        this.trackContentPosition();
        this.trackContentClick(ENTITY_TYPES.MOVIE);
        RouterUtil.navigateToRoute(ROUTE.movieHome, {
            entity: ROUTE_ENTITY.itemName,
            value: this._item.urlAlias,
        }, { keepAlive: RouterUtil.isShowPage() });
    }
    static get tag() {
        return 'SeriesTile';
    }
}
