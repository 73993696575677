import { Language, Lightning, Storage, Utils } from '@lightningjs/sdk';
import RouterUtil from '../util/RouterUtil';
import Item from '../components/items/Item';
import TextButton from './buttons/TextButton';
import ProgressBar from './progressBar/progressBar';
import SponsorBadge from './badges/SponsorBadge';
import LiveBadge from './badges/LiveBadge';
import ModalManager, { ModalTypes } from '../lib/ModalManager';
import { LiveStreamManager } from '../lib/LiveStreamManager';
import { ALIGN_CONTENT, ALIGN_ITEMS, ALIGN_SELF, COLORS, ENTITY_TYPES, FLEX_DIRECTION, FONT_FACE, JUSTIFY_CONTENT, SCREEN_SIZE, STORAGE_KEYS, VERTICAL_ALIGN, WORD_WRAP_WIDTH, } from '../constants';
import { EVENTS } from '../lib/analytics/types';
import { PLAYER_TYPE } from '../player/core/PlayerFactory';
import { createXumoHoleShader, isSecondaryPlayerLoadObject, SecondaryPlayerSingleton, } from '../lib/SecondaryPlayer';
import { sendMetric } from '../lib/analytics/Analytics';
import { fadeComponent, FastImg, getCurrentProgressWidth, getHexColor, isOlympicsPage, isXclass, setSmooth, } from '../helpers';
import TVPlatform from '../lib/tv-platform';
import { lockDecorator } from './items/decorators';
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { OLY_IMPOLICY } from '../constants/images';
export default class MarketingModule extends Item {
    static _template() {
        const template = super._template();
        const timingFunction = 'cubic-bezier(0.20, 1.00, 0.80, 1.00)';
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        const duration = super.duration;
        return Object.assign(Object.assign({}, template), { w: SCREEN_SIZE.width, h: SCREEN_SIZE.height, transitions: {
                y: { duration, timingFunction },
                scale: { duration, timingFunction },
                alpha: { duration, timingFunction },
                amount: { duration, timingFunction },
            }, ImageHolder: {
                scale: 1.02,
                w: SCREEN_SIZE.width,
                h: SCREEN_SIZE.height,
                transitions: {
                    scale: { duration: 1.2, timingFunction },
                },
                Background: {
                    alpha: 0.001,
                    w: SCREEN_SIZE.width,
                    h: SCREEN_SIZE.height,
                    transitions: {
                        alpha: { duration: 0.6, timingFunction },
                    },
                    linearGradient: 'rgba(255, 0, 0, 0.45)',
                },
                Gradients: {
                    alpha: 0.7,
                    w: SCREEN_SIZE.width,
                    h: SCREEN_SIZE.height,
                    rect: true,
                    color: COLORS.black,
                },
                shader: null,
            } });
    }
    _handleDown() {
        if (!this.fireAncestors('$isLastIndex', this))
            setSmooth(this, 'alpha', 0);
        return false;
    }
    _init() {
        var _a, _b;
        this._addDecorators([lockDecorator]);
        this.tag('Background').on('txLoaded', () => {
            this.tag('Background').setSmooth('alpha', 1);
            this.focusImage(true);
        });
        this.tag('Background').on('txError', () => {
            this.tag('Background').src = Utils.asset('images/background.png');
            this.focusImage(true);
        });
        this.patch({ x: ((_b = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.parent) === null || _b === void 0 ? void 0 : _b.x) ? -this.parent.parent.x : 0 });
        this._subscription = SecondaryPlayerSingleton.subscribe(({ activated, type }) => {
            const element = this.tag(type === PLAYER_TYPE.BACKGROUND ? 'ImageHolder' : 'Image');
            const fadeEndValue = Number(!activated);
            if (element && element.alpha !== fadeEndValue) {
                fadeComponent(element, fadeEndValue);
            }
            if (isXclass() && type === PLAYER_TYPE.PREVIEW) {
                this.tag('ImageHolder').shader = createXumoHoleShader(activated);
            }
        });
    }
    _detach() {
        var _a;
        (_a = this._subscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._subscription = undefined;
    }
    _focus() {
        var _a;
        if (this.alpha === 0)
            this.alpha = 1;
        // Impression called here because Active fires early to
        // trigger the transitions but doesnt reflect true guest impression
        this._fireImpressionMetric();
        const { mainPreview, backgroundPreview } = this._item;
        if (((_a = this.parent) === null || _a === void 0 ? void 0 : _a.parent) && this.parent.parent.y > 0) {
            this._parentY = this.parent.parent.y;
            this.parent.parent.y = 0;
            this.tag('SecondaryElementsHolder').y += this._parentY / 2;
            this.tag('Image').y += this._parentY / 2;
        }
        const players = [
            {
                playbackUrl: mainPreview,
                type: PLAYER_TYPE.PREVIEW,
            },
            {
                playbackUrl: backgroundPreview,
                type: PLAYER_TYPE.BACKGROUND,
            },
        ];
        const items = players.filter((item) => isSecondaryPlayerLoadObject(item));
        if (!TVPlatform.capabilities.concurrentStreams && items.length > 1) {
            items.splice(1);
        }
        items.forEach(async (item) => {
            // Adding a delay so we don't overwhelm the device loading two streams at once
            if (LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.marketingModulePreview)) {
                SecondaryPlayerSingleton.load(item);
            }
        });
        this.fireAncestors('$hideMenuBackground');
    }
    _unfocus() {
        var _a;
        if (((_a = this.parent) === null || _a === void 0 ? void 0 : _a.parent) && this._parentY) {
            this.parent.parent.y = this._parentY;
            this.tag('SecondaryElementsHolder').y -= this._parentY / 2;
            this.tag('Image').y -= this._parentY / 2;
        }
        this.fireAncestors('$showMenuBackground');
        this._setState('ButtonPrimary');
        if (SecondaryPlayerSingleton.isPreviewPlayerActive) {
            this._fireVideoPreview();
        }
        SecondaryPlayerSingleton.unload();
    }
    set analytics(analytics) {
        this._analytics = analytics;
    }
    set items(v) {
        var _a, _b, _c;
        if (this._item)
            return;
        this._item = v;
        const { backgroundFallbackImage, mainImage, logo, logoAltText, primaryCTA, secondaryCTA } = this._item;
        const primaryCtaColor = primaryCTA.data.color
            ? getHexColor(primaryCTA.data.color)
            : COLORS.lightGray10;
        const currentProgressWidth = getCurrentProgressWidth({
            startTime: v.startDate,
            endTime: v.endDate,
            totalProgressWidth: 460,
        });
        this.patch({
            ImageHolder: {
                alpha: 0.7,
                Background: {
                    texture: FastImg(backgroundFallbackImage === null || backgroundFallbackImage === void 0 ? void 0 : backgroundFallbackImage.path).cover(backgroundFallbackImage === null || backgroundFallbackImage === void 0 ? void 0 : backgroundFallbackImage.width, backgroundFallbackImage === null || backgroundFallbackImage === void 0 ? void 0 : backgroundFallbackImage.height, OLY_IMPOLICY.RECT_MEDIUM_1024_576),
                },
            },
            Image: {
                y: 100,
                x: 80,
                w: 960,
                h: 580,
                texture: FastImg(mainImage === null || mainImage === void 0 ? void 0 : mainImage.path).contain(450, 100, OLY_IMPOLICY.RECT_SMEDIUM_640_360),
            },
            SecondaryElementsHolder: {
                y: 105,
                x: 1120,
                w: 700,
                h: 580,
                flex: {
                    direction: FLEX_DIRECTION.column,
                    justifyContent: JUSTIFY_CONTENT.center,
                },
                LogoHolder: {
                    w: 462,
                    h: 154,
                    flex: {
                        direction: FLEX_DIRECTION.row,
                        alignContent: ALIGN_CONTENT.center,
                    },
                    Logo: {
                        flexItem: {
                            alignSelf: (logo === null || logo === void 0 ? void 0 : logo.path) ? ALIGN_SELF.center : ALIGN_SELF.flexEnd,
                        },
                        texture: (logo === null || logo === void 0 ? void 0 : logo.path)
                            ? FastImg(logo.path).contain(462, 154)
                            : {
                                type: Lightning.textures.TextTexture,
                                text: logoAltText,
                                maxLines: 2,
                                wordWrapWidth: WORD_WRAP_WIDTH,
                            },
                    },
                },
                SponsorshipArea: {
                    h: 77,
                    w: 600,
                    visible: v.isSponsored,
                    flex: {
                        direction: FLEX_DIRECTION.row,
                        alignItems: ALIGN_ITEMS.center,
                    },
                    flexItem: { marginTop: 10, marginBottom: 25 },
                    PresentedByText: {
                        text: {
                            text: `${Language.translate('presented_by')}`,
                            fontSize: 26,
                            fontFace: FONT_FACE.light,
                            lineHeight: 30,
                        },
                    },
                    SponsorIcon: {
                        h: 26,
                        flexItem: { marginLeft: 20 },
                        type: SponsorBadge,
                        sponsorLogo: (_a = v.sponsorLogo) === null || _a === void 0 ? void 0 : _a.path,
                        sponsorFallbackText: (_b = v.sponsorLogoAltText) !== null && _b !== void 0 ? _b : v.sponsorName,
                        sponsorLogoHeight: 26,
                        sponsorLogoWidth: 300,
                    },
                },
                LiveBadgeContainer: {
                    flex: {},
                    fontSize: 25,
                    h: 36,
                    label: Language.translate('live'),
                    type: LiveBadge,
                    visible: this.showLiveBadge,
                },
                LockContainer: {
                    flex: {},
                },
                PlaylistContainer: {
                    visible: primaryCTA.data.isPlaylist,
                    flex: {
                        direction: FLEX_DIRECTION.row,
                        alignItems: ALIGN_ITEMS.center,
                    },
                    flexItem: { marginBottom: 10 },
                    PlaylistImage: {
                        w: 52,
                        h: 26,
                        src: Utils.asset('images/playlist-icon.png'),
                    },
                    PlaylistInfo: {
                        flexItem: { marginLeft: 10, marginTop: 2 },
                        text: {
                            text: Language.translate('mm-playlist', (_c = primaryCTA.data) === null || _c === void 0 ? void 0 : _c.playlistCount),
                            fontSize: 24,
                            fontFace: FONT_FACE.semiBold,
                            lineHeight: 30,
                            verticalAlign: VERTICAL_ALIGN.middle,
                        },
                    },
                },
                Title: {
                    flexItem: { marginTop: 10 },
                    alpha: 0.87,
                    w: 600,
                    h: 100,
                    text: {
                        fontSize: 32,
                        fontFace: FONT_FACE.light,
                        lineHeight: 40,
                        maxLines: 2,
                        text: v.description,
                    },
                },
                LiveProgressBarContainer: {
                    y: 610,
                    w: 460,
                    visible: false,
                    LiveProgressBar: {
                        type: ProgressBar,
                        h: 10,
                        totalProgressWidth: 460,
                        totalProgressColor: COLORS.charcoalGrey,
                        currentProgressColor: COLORS.red,
                        currentProgressWidth,
                    },
                },
                ButtonHolder: {
                    w: 600,
                    h: 60,
                    flexItem: { marginTop: 30 },
                    flex: { direction: FLEX_DIRECTION.row },
                    ButtonPrimary: {
                        flexItem: {
                            marginRight: 20,
                        },
                        h: 58,
                        type: TextButton,
                        radius: 29,
                        fontSize: 28,
                        fontFace: FONT_FACE.regular,
                        focusFontColor: COLORS.white,
                        unfocusFontColor: COLORS.white,
                        focusBackGroundColor: primaryCtaColor,
                        unfocusBackgroundColor: COLORS.transparent,
                        strokeWidth: 1,
                        strokeColor: primaryCtaColor,
                        autoWidth: true,
                        padding: 22,
                        label: primaryCTA.data.text,
                    },
                },
            },
        });
        if (secondaryCTA === null || secondaryCTA === void 0 ? void 0 : secondaryCTA.data) {
            const secondaryButtonText = secondaryCTA.data.text.length > 10
                ? `${secondaryCTA.data.text.substring(0, 10)}...`
                : secondaryCTA.data.text;
            const secondaryCtaColor = secondaryCTA.data.color
                ? getHexColor(secondaryCTA.data.color)
                : COLORS.lightGray10;
            this.tag('SecondaryElementsHolder.ButtonHolder').patch({
                ButtonSecondary: {
                    h: 58,
                    type: TextButton,
                    radius: 29,
                    fontSize: 28,
                    fontFace: FONT_FACE.regular,
                    focusFontColor: COLORS.white,
                    unfocusFontColor: COLORS.white,
                    focusBackGroundColor: secondaryCtaColor,
                    unfocusBackgroundColor: COLORS.transparent,
                    strokeWidth: 1,
                    strokeColor: secondaryCtaColor,
                    autoWidth: true,
                    padding: 22,
                    label: secondaryButtonText,
                },
            });
        }
        this._setState('ButtonPrimary');
    }
    focusImage(v) {
        this.patch({
            ImageHolder: {
                smooth: { scale: v ? 1 : 1.02 },
            },
        });
    }
    trackContentClick(item) {
        var _a, _b;
        if (!this._item)
            return;
        this.trackContentPosition();
        const { analytics } = item;
        analytics.itemClickedName = this._getItemClickedName(item);
        const shelf = {
            tileIndex: 1,
            shelfIndex: this._getPosition(),
            listTitle: 'Marketing Module',
            playlistMachineName: analytics.machineName,
            playlistPosition: analytics.playlistPosition,
            machineName: (_a = this._analytics) === null || _a === void 0 ? void 0 : _a.machineName,
            customShelfType: (_b = this._analytics) === null || _b === void 0 ? void 0 : _b.shelfType,
        };
        const params = {
            name: item.data.text,
            entity: {
                entityType: ENTITY_TYPES.MARKETING_MODULE,
                analytics,
            },
            shelf,
        };
        if (isOlympicsPage()) {
            params.entity.analytics.isOlympics = true;
        }
        Storage.set(STORAGE_KEYS.REFERRING_SHELF, shelf);
        // Sending the events
        sendMetric(EVENTS.CLICK, params);
        sendMetric(EVENTS.CONTENT_CLICK, params);
    }
    _getPosition() {
        var _a;
        const index = (_a = this.parent) === null || _a === void 0 ? void 0 : _a.children.indexOf(this);
        return index && index > -1 ? index + 1 : 1;
    }
    _getItemClickedName(cta = this._item.primaryCTA) {
        const { analytics: ctaAnalytics } = cta;
        let item;
        switch (ctaAnalytics.destinationType) {
            case 'series':
                item = ctaAnalytics === null || ctaAnalytics === void 0 ? void 0 : ctaAnalytics.series;
                break;
            case 'video':
                item = ctaAnalytics === null || ctaAnalytics === void 0 ? void 0 : ctaAnalytics.videoTitle;
                break;
            case 'internalPage':
            case 'externalPage':
            case 'playlist':
                item = ctaAnalytics === null || ctaAnalytics === void 0 ? void 0 : ctaAnalytics.ctaTitle;
        }
        return item;
    }
    _getItemClickedType(cta = this._item.primaryCTA) {
        const { analytics: ctaAnalytics } = cta;
        return (ctaAnalytics === null || ctaAnalytics === void 0 ? void 0 : ctaAnalytics.programmingType) || (ctaAnalytics === null || ctaAnalytics === void 0 ? void 0 : ctaAnalytics.programType);
    }
    _getDestinationType(cta = this._item.primaryCTA) {
        const { analytics: ctaAnalytics } = cta;
        return ctaAnalytics.destinationType;
    }
    _fireImpressionMetric() {
        const params = Object.assign({ customShelfPosition: this._getPosition(), itemClickedName: this._getItemClickedName(), itemClickedType: this._getItemClickedType(), destinationType: this._getDestinationType() }, this._analytics);
        if (isOlympicsPage()) {
            params.isOlympics = true;
        }
        sendMetric(EVENTS.MARKETING_MODULE_IMPRESSION, params);
    }
    _fireVideoPreview() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4;
        const metadata = SecondaryPlayerSingleton.getPreviewVideoAnalytics();
        const isLive = (_c = (_b = (_a = this._item) === null || _a === void 0 ? void 0 : _a.primaryCTA) === null || _b === void 0 ? void 0 : _b.analytics) === null || _c === void 0 ? void 0 : _c.isLive;
        const isTrailer = (_f = (_e = (_d = this._item) === null || _d === void 0 ? void 0 : _d.primaryCTA) === null || _e === void 0 ? void 0 : _e.analytics) === null || _f === void 0 ? void 0 : _f.isTrailer;
        const destinationType = ((_j = (_h = (_g = this._item) === null || _g === void 0 ? void 0 : _g.primaryCTA) === null || _h === void 0 ? void 0 : _h.analytics) === null || _j === void 0 ? void 0 : _j.destinationType) || '';
        const video = (isLive && 'Live') || (isTrailer && 'Sneak Peek') || 'Full Episode';
        const previewType = {
            video,
            playlist: video,
            series: 'Show',
            movie: 'Movie',
            internalPage: 'Internal',
            externalPage: 'External',
            externalAppLink: 'External',
            trial: 'External',
            vote: 'External',
        }[destinationType] || 'Internal';
        const params = Object.assign(Object.assign({}, this._analytics), { brand: (_o = (_m = (_l = (_k = this._item) === null || _k === void 0 ? void 0 : _k.primaryCTA) === null || _l === void 0 ? void 0 : _l.analytics) === null || _m === void 0 ? void 0 : _m.brand) === null || _o === void 0 ? void 0 : _o.title, customShelfPosition: this._getPosition(), listTitle: 'Marketing Module', percentComplete: metadata.percentWatched, durationWatched: metadata.position, customShelfType: '', duration: metadata.duration, v4ID: this._item.v4ID, league: (_r = (_q = (_p = this._item) === null || _p === void 0 ? void 0 : _p.primaryCTA) === null || _q === void 0 ? void 0 : _q.analytics) === null || _r === void 0 ? void 0 : _r.league, locked: (_s = this._item) === null || _s === void 0 ? void 0 : _s.locked, episodeTitle: (_v = (_u = (_t = this._item) === null || _t === void 0 ? void 0 : _t.primaryCTA) === null || _u === void 0 ? void 0 : _u.analytics) === null || _v === void 0 ? void 0 : _v.videoTitle, episodeNumber: (_y = (_x = (_w = this._item) === null || _w === void 0 ? void 0 : _w.primaryCTA) === null || _x === void 0 ? void 0 : _x.analytics) === null || _y === void 0 ? void 0 : _y.episodeNumber, seasonNumber: (_1 = (_0 = (_z = this._item) === null || _z === void 0 ? void 0 : _z.primaryCTA) === null || _0 === void 0 ? void 0 : _0.analytics) === null || _1 === void 0 ? void 0 : _1.seasonNumber, previewType, sport: (_4 = (_3 = (_2 = this._item) === null || _2 === void 0 ? void 0 : _2.primaryCTA) === null || _3 === void 0 ? void 0 : _3.analytics) === null || _4 === void 0 ? void 0 : _4.sport });
        sendMetric(EVENTS.MARKETING_MODULE_VIDEO_PREVIEW, params);
    }
    static get tag() {
        return 'MarketingModule';
    }
    get showLiveBadge() {
        var _a;
        return ((_a = this._item) === null || _a === void 0 ? void 0 : _a.badge) === 'LIVE';
    }
    get isLocked() {
        var _a;
        return (_a = this._item) === null || _a === void 0 ? void 0 : _a.locked;
    }
    get lockContainer() {
        return this.tag('LockContainer');
    }
    get lockTransparent() {
        return true;
    }
    get keyBadgePosition() {
        return 60;
    }
    static _states() {
        return [
            class ButtonPrimary extends this {
                _getFocused() {
                    return this.tag('SecondaryElementsHolder.ButtonPrimary');
                }
                _handleRight() {
                    var _a;
                    if ((_a = this._item) === null || _a === void 0 ? void 0 : _a.secondaryCTA)
                        this._setState('ButtonSecondary');
                }
                _handleEnter() {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    this.trackContentClick((_a = this._item) === null || _a === void 0 ? void 0 : _a.primaryCTA);
                    if ((_d = (_c = (_b = this._item) === null || _b === void 0 ? void 0 : _b.primaryCTA) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.qrModal) {
                        const qrModalData = this._item.primaryCTA.data.qrModal;
                        ModalManager.open(ModalTypes.QR_MODAL, qrModalData);
                    }
                    else {
                        if ((_g = (_f = (_e = this._item) === null || _e === void 0 ? void 0 : _e.primaryCTA) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.isLive) {
                            LiveStreamManager.set('', (_k = (_j = (_h = this._item) === null || _h === void 0 ? void 0 : _h.primaryCTA) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.destination, '');
                        }
                        RouterUtil.navigateByDestinationType((_m = (_l = this._item) === null || _l === void 0 ? void 0 : _l.primaryCTA) === null || _m === void 0 ? void 0 : _m.data);
                    }
                    if (SecondaryPlayerSingleton.isPreviewPlayerActive) {
                        this._fireVideoPreview();
                    }
                }
            },
            class ButtonSecondary extends this {
                _getFocused() {
                    return this.tag('SecondaryElementsHolder.ButtonSecondary');
                }
                _handleLeft() {
                    var _a;
                    if ((_a = this._item) === null || _a === void 0 ? void 0 : _a.secondaryCTA)
                        this._setState('ButtonPrimary');
                }
                _handleEnter() {
                    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
                    this.trackContentClick((_a = this._item) === null || _a === void 0 ? void 0 : _a.secondaryCTA);
                    if ((_d = (_c = (_b = this._item) === null || _b === void 0 ? void 0 : _b.secondaryCTA) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.qrModal) {
                        const qrModalData = this._item.secondaryCTA.data.qrModal;
                        ModalManager.open(ModalTypes.QR_MODAL, qrModalData);
                    }
                    else {
                        if ((_g = (_f = (_e = this._item) === null || _e === void 0 ? void 0 : _e.secondaryCTA) === null || _f === void 0 ? void 0 : _f.data) === null || _g === void 0 ? void 0 : _g.isLive) {
                            LiveStreamManager.set('', (_k = (_j = (_h = this._item) === null || _h === void 0 ? void 0 : _h.secondaryCTA) === null || _j === void 0 ? void 0 : _j.data) === null || _k === void 0 ? void 0 : _k.destination, '');
                        }
                        RouterUtil.navigateByDestinationType((_m = (_l = this._item) === null || _l === void 0 ? void 0 : _l.secondaryCTA) === null || _m === void 0 ? void 0 : _m.data);
                    }
                }
            },
        ];
    }
}
