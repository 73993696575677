import { Router, Registry } from '@lightningjs/sdk';
import LaunchDarklyFeatureFlags from '../../../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import LaunchDarklySingleton from '../../../../lib/launchDarkly/LaunchDarkly';
import { WithEndCard } from './WithEndCard';
import moment from 'moment-timezone';
import { getAdjustedReferringShelf } from '../../../../helpers';
import { END_CARD_SLE_AUTOPLAY_TIME, PROGRAMMING_TYPES, ROUTE } from '../../../../constants';
import { BingeEndcardEvent } from '../../../../player/model/event';
import PlayerStoreSingleton from '../../../../store/PlayerStore/PlayerStore';
export const WithEndCardSLE = (component) => class extends WithEndCard(component) {
    constructor() {
        super(...arguments);
        this._autoplayTime = END_CARD_SLE_AUTOPLAY_TIME;
    }
    _construct() {
        super._construct();
        this._autoplayTime =
            LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.sleBingeTimer) ||
                END_CARD_SLE_AUTOPLAY_TIME;
        this._sleEndcardEnabled = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.enableSleBinge);
    }
    _onStreamEnd() {
        this._setState('EmptyPlayerEndCardSLE');
        this._endCard.transparentBG = false;
        this._mediaPlayerEnded = true;
    }
    _playerEventsHandler(event) {
        super._playerEventsHandler(event);
        if (event instanceof BingeEndcardEvent && this._sleEndcardEnabled) {
            const { stream } = PlayerStoreSingleton;
            const videoId = stream && 'pid' in stream && stream.pid;
            if (videoId) {
                this._endCard.queryEndCardsSLE(videoId, PROGRAMMING_TYPES.SLE, 'SLE').then((endCards) => {
                    if (endCards) {
                        this._setState('PlayerEndCardSLE');
                    }
                });
            }
        }
    }
    _trackOpenEndcard() {
        this._endCardImpressionTimeStamp = moment();
        this._analyticsDelegate.fireEndCardImpression(Object.assign(Object.assign({ endCardTime: this._autoplayTime }, this._endCard.getAnalyticsData()), { shelf: getAdjustedReferringShelf() }));
    }
    static _states() {
        return [
            // @ts-expect-error can't type a static method in an interface
            ...super._states(),
            class PlayerEndCardSLE extends this {
                $enter() {
                    this._trackOpenEndcard();
                    this._additionalUIUpdates({
                        endCardBGTransparency: true,
                        endCardTransparency: false,
                    });
                }
                $exit() {
                    this._endCardImpressionTimeStamp = null;
                }
                _getFocused() {
                    return this._endCard || this;
                }
                _captureBack(_) {
                    this._closeEndCard();
                }
                _handleUp() {
                    return true;
                }
                _handleDown() {
                    return true;
                }
            },
            class EmptyPlayerEndCardSLE extends this {
                _closeSLEEndcard() {
                    this._closePlayer();
                    this._endCard.alpha = 0;
                    this._endCardsShown = false;
                    this._endCard.transparent = false;
                    this._endCard.transparentBG = false;
                    this._endCard.SFVOD_BG = false;
                }
                $enter() {
                    this._trackOpenEndcard();
                    this._closePlayer();
                    this._additionalUIUpdates({
                        endCardEmptyBGTransparency: true,
                        endCardBGTransparency: false,
                        endCardTransparency: false,
                    });
                    this._emptyEndcardTimer = Registry.setTimeout(() => this._handleEnter(), this._autoplayTime * 1000);
                }
                $exit() {
                    this._clearTimer();
                    this._endCardImpressionTimeStamp = null;
                }
                _handleEnter() {
                    this._closeSLEEndcard();
                    Router.navigate(ROUTE.sports);
                }
                _handleBack() {
                    Router.back();
                }
                _clearTimer() {
                    if (this._emptyEndcardTimer)
                        Registry.clearTimout(this._emptyEndcardTimer);
                }
            },
        ];
    }
};
