/* eslint-disable no-unsafe-optional-chaining */
import qs from 'qs';
import CryptoJS from 'crypto-js';
import moment from 'moment-timezone';
import IAuthenticationService from '../IAuthenticationService';
import EntitlementConfig from '../../config/EntitlementConfig';
import AppConfigFactorySingleton from '../../config/AppConfigFactory';
import MvpdData from '../../api/models/MvpdData';
import { sendMetric } from '../../lib/analytics/Analytics';
import { EVENTS } from '../../lib/analytics/types';
import { getAppId, getWhiteLabelBrand } from '../../helpers';
import { MAPPED_CHANNELS, TEMP_PASS_ERROR, TOKEN_VERIFIER_URLS, CONTENT_TYPES, } from '../authConstants';
import LaunchDarklySingleton from '../../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import TVPlatform from '../../lib/tv-platform';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
import { getUserProfile } from '../../api/Identity';
import { ErrorType } from '../../lib/tv-platform/types';
const ADOBE_SERVICE_TAG = 'AdobeAuthenticationService';
export class AdobeAuthenticationService extends IAuthenticationService {
    constructor(configuration = EntitlementConfig) {
        super();
        this.getRequestParams = () => ({
            deviceType: TVPlatform.devicePartnerId,
            appId: getAppId(),
            applicationId: getWhiteLabelBrand().name,
            deviceId: TVPlatform.deviceId,
        });
        this.getUserMetadata = async () => {
            try {
                const entitlement = this._configuration;
                const endpoint = '/api/v1/tokens/usermetadata';
                const requestParams = this.getRequestParams();
                const url = `${entitlement.server}${endpoint}?${qs.stringify(Object.assign(Object.assign({}, requestParams), { requestor: entitlement.requestorId }))}`;
                const options = {
                    headers: await this.getHeadersWithDeviceInfo(),
                };
                const response = await fetch(url, options);
                return await response.json();
            }
            catch (err) {
                TVPlatform.reportError({
                    type: ErrorType.OTHER,
                    code: ADOBE_SERVICE_TAG,
                    description: 'getUserMetadata failed',
                    payload: err,
                });
                return err;
            }
        };
        this.getRegCode = async () => {
            try {
                const entitlement = this._configuration;
                const endpoint = `/reggie/v1/${entitlement.requestorId}/regcode`;
                const regCodeUrl = `${entitlement.server}${endpoint}`;
                const queryParams = qs.stringify(Object.assign(Object.assign({}, this.getRequestParams()), { appVersion: '1.0', registrationURL: entitlement.activationUrl }));
                const options = {
                    headers: await this.getBaseHeaders(),
                    method: 'POST',
                };
                const response = await fetch(`${regCodeUrl}?${queryParams}`, options);
                return await response.json();
            }
            catch (err) {
                TVPlatform.reportError({
                    type: ErrorType.OTHER,
                    code: ADOBE_SERVICE_TAG,
                    description: 'getRegCode failed',
                    payload: err,
                });
            }
        };
        this.getClientCredentials = async () => {
            try {
                const entitlement = this._configuration;
                const endpoint = '/o/client/register';
                const url = `${entitlement.server}${endpoint}`;
                const body = JSON.stringify({
                    software_statement: AppConfigFactorySingleton === null || AppConfigFactorySingleton === void 0 ? void 0 : AppConfigFactorySingleton.config.adobe.software_statement,
                });
                const options = {
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                        'X-Device-Info': this.deviceInfo,
                    },
                    method: 'POST',
                    body,
                };
                const response = await fetch(url, options);
                const json = await response.json();
                this._clientCredentials = json;
                return json;
            }
            catch (err) {
                return err;
            }
        };
        this.updateAccessToken = async () => {
            try {
                if (!this._clientCredentials)
                    return Promise.reject('Missing client credentials');
                const entitlement = this._configuration;
                const endpoint = '/o/client/token';
                const url = `${entitlement.server}${endpoint}`;
                const params = qs.stringify({
                    grant_type: 'client_credentials',
                    client_id: this.client_id,
                    client_secret: this.client_secret,
                });
                const options = {
                    headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                    },
                    method: 'POST',
                };
                const response = await fetch(`${url}?${params}`, options);
                const data = await response.json();
                this._accessTokenData = data;
                return data;
            }
            catch (err) {
                return err;
            }
        };
        this.getTempPassFromLD = () => {
            const { initialTempPassName = null, secondaryTempPassName = null, identityRequiredForSecondary = true, } = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass);
            return {
                initialTempPassName,
                secondaryTempPassName,
                identityRequiredForSecondary,
            };
        };
        this.createTempPass = async ({ initialRequest } = { initialRequest: true }) => {
            if (this.isTempPassActive()) {
                throw TEMP_PASS_ERROR.TEMP_PASS_ACTIVE;
            }
            const { initialTempPassName, secondaryTempPassName, identityRequiredForSecondary } = this.getTempPassFromLD();
            let tempPassName = '';
            if ((DebugControllerSingleton === null || DebugControllerSingleton === void 0 ? void 0 : DebugControllerSingleton.tempPassName) &&
                DebugControllerSingleton.tempPassName.every((v) => !!v)) {
                const variations = DebugControllerSingleton.tempPassName;
                if (identityRequiredForSecondary) {
                    tempPassName = variations[getUserProfile() ? 1 : 0];
                }
                else {
                    tempPassName = variations[initialRequest ? 0 : 1];
                }
            }
            else {
                tempPassName = initialRequest ? initialTempPassName : secondaryTempPassName;
            }
            if (!tempPassName || tempPassName === 'error') {
                throw TEMP_PASS_ERROR.LAUNCH_DARKLY_ERROR;
            }
            // prevent infinite loop of TempPass attempts
            if (this._createTempPassAttempts > 2) {
                throw TEMP_PASS_ERROR.ATTEMPTS_EXCEEDED;
            }
            this._createTempPassAttempts = this._createTempPassAttempts++;
            const entitlement = this._configuration;
            const endpoint = '/api/v1/authenticate/freepreview';
            const tempPassUrl = `${entitlement.server}${endpoint}`;
            const { applicationId: domain_name } = this.getRequestParams();
            const params = qs.stringify({
                deviceId: TVPlatform.deviceId,
                deviceType: TVPlatform.devicePartnerId,
                domain_name,
                mso_id: tempPassName,
                requestor_id: entitlement.requestorId,
                appId: getAppId(),
                applicationId: getWhiteLabelBrand().name,
            });
            const options = {
                headers: await this.getHeadersWithDeviceInfo(),
                referrerPolicy: 'no-referrer',
                method: 'POST',
            };
            // @ts-expect-error TS(2345): Argument of type '{ headers: { 'X-Device-Info': an... Remove this comment to see the full error message
            const { status } = await fetch(`${tempPassUrl}?${params}`, options);
            return status !== 204
                ? Promise.reject(status === 403 ? TEMP_PASS_ERROR.SESSION_EXPIRED : TEMP_PASS_ERROR.UNKNOWN)
                : status;
        };
        this.resetTempPass = async (tempPassKey) => {
            var _a, _b, _c;
            if (this._mvpdData) {
                await this.logout();
            }
            // See documentation: https://experienceleague.adobe.com/docs/primetime/authentication/auth-features/temp-pass/temp-pass.html?lang=en#reset-all-tempass
            try {
                if (!tempPassKey) {
                    throw 'No temp pass key provided';
                }
                const { url, apiKey, accessToken: accessTokenForReset, } = ((_c = (_b = (_a = AppConfigFactorySingleton === null || AppConfigFactorySingleton === void 0 ? void 0 : AppConfigFactorySingleton.config) === null || _a === void 0 ? void 0 : _a.adobe) === null || _b === void 0 ? void 0 : _b.tempPass) === null || _c === void 0 ? void 0 : _c.reset) || {};
                const entitlement = this._configuration;
                const options = {
                    headers: {
                        ApiKey: apiKey,
                        Authorization: `Bearer ${accessTokenForReset}`,
                    },
                    method: 'DELETE',
                };
                const queryString = qs.stringify({
                    device_id: TVPlatform.deviceId,
                    requestor_id: entitlement.requestorId,
                    mvpd_id: tempPassKey,
                });
                const resetTempPassUrl = `${url}/reset-tempass/v2.1/reset`;
                const reqUrl = `${resetTempPassUrl}?${queryString}`;
                const { status } = (await fetch(reqUrl, options)) || {};
                return status;
            }
            catch (err) {
                return err;
            }
        };
        this.checkStatus = async () => {
            try {
                const entitlement = this._configuration;
                const endpoint = '/api/v1/tokens/authn';
                const options = {
                    headers: await this.getHeadersWithDeviceInfo(),
                    method: 'GET',
                };
                const urlParams = {
                    deviceId: TVPlatform.deviceId,
                    requestor: entitlement.requestorId,
                    deviceType: TVPlatform.devicePartnerId,
                    appId: getAppId(),
                    applicationId: getWhiteLabelBrand().name,
                };
                const url = `${entitlement.server}${endpoint}?${qs.stringify(urlParams)}`;
                const response = await fetch(url, options);
                if (response.status === 200) {
                    const data = await response.json();
                    await this._saveMvpdData(data);
                    return true;
                }
                else {
                    return false;
                }
            }
            catch (err) {
                return false;
            }
        };
        this.logout = async () => {
            if (!this._mvpdData) {
                return;
            }
            try {
                const entitlement = this._configuration;
                const endpoint = '/api/v1/logout';
                const options = {
                    method: 'delete',
                    headers: await this.getHeadersWithDeviceInfo(),
                };
                const url = `${entitlement.server}${endpoint}?${qs.stringify(this.getRequestParams())}`;
                await fetch(url, options);
                this._mvpdData = null;
                // Update the LD context on logout
                LaunchDarklySingleton.updateUserAuthContext(false);
            }
            catch (err) {
                TVPlatform.reportError({
                    type: ErrorType.OTHER,
                    code: ADOBE_SERVICE_TAG,
                    description: 'signOut failed',
                    payload: err,
                });
                return err;
            }
        };
        this.authorize = async (asset) => {
            try {
                this._onAuthZStart();
                const endpoint = '/api/v1/authorize';
                const { server } = this._configuration;
                const options = { headers: await this.getHeadersWithDeviceInfo() };
                const url = `${server}${endpoint}?${this._getMrss(asset)}`;
                const response = await fetch(url, options);
                const auth = await response.json();
                this._onAuthZComplete();
                if (auth.mvpd && !auth.status) {
                    if (!this.isAuthenticated()) {
                        await this._saveMvpdData(auth);
                    }
                    return await this._getMediaToken(asset);
                }
                return auth;
            }
            catch (err) {
                return err;
            }
        };
        this._onAuthZStart = () => {
            // necessary to keep this scope when tracking is called in Promise.
            sendMetric(EVENTS.AUTHZ_START);
        };
        this._onAuthZComplete = () => {
            // necessary to keep this scope when tracking is called in Promise.
            sendMetric(EVENTS.AUTHZ_COMPLETE);
        };
        this._preAuthorize = async () => {
            try {
                const endpoint = '/api/v1/preauthorize.json';
                const entitlement = this._configuration;
                const { brandResources } = AppConfigFactorySingleton.config.mvpdService;
                const requestParams = this.getRequestParams();
                const options = {
                    headers: await this.getHeadersWithDeviceInfo(),
                    method: 'GET',
                };
                const url = `${entitlement.server}${endpoint}?${qs.stringify(Object.assign(Object.assign({}, requestParams), { requestor: entitlement.requestorId, resource: brandResources }))}`;
                const response = await fetch(url, options);
                return await response.json();
            }
            catch (err) {
                TVPlatform.reportError({
                    type: ErrorType.ENTITLEMENT,
                    code: ADOBE_SERVICE_TAG,
                    description: 'preAuthorize failed',
                    payload: err,
                });
                return err;
            }
        };
        this._saveMvpdData = async (data) => {
            const { mvpd, userId, expires } = data;
            try {
                const { entitlementsBaseUrl, brand, instance, platform } = AppConfigFactorySingleton.config.mvpdService;
                const endpoint = '/localized-mvpd/entitlements';
                const url = `${entitlementsBaseUrl}${endpoint}?${qs.stringify({
                    brand,
                    instance,
                    platform,
                })}`;
                const response = await fetch(url);
                const data = await response.json();
                const [mvpdWhitelist, adobePassErrorMappings] = [
                    data.mvpdWhitelist,
                    data.globalSettings.adobePassErrorMappings,
                ];
                const metaData = await this.getUserMetadata();
                const preAuthData = await this._preAuthorize();
                this._mvpdData = new MvpdData(mvpd, userId, parseInt(expires), adobePassErrorMappings, mvpdWhitelist.find((provider) => provider.mvpd === mvpd), metaData, preAuthData);
                return this._mvpdData;
            }
            catch (err) {
                return err;
            }
        };
        this._getMrss = (video) => {
            const { mpxGuid, title, rating, resourceId, league } = video;
            const entitlement = this._configuration;
            return qs.stringify({
                deviceId: TVPlatform.deviceId,
                requestor: entitlement.requestorId,
                deviceType: TVPlatform.devicePartnerId,
                appId: getAppId(),
                applicationId: getWhiteLabelBrand().name,
                resource: `
		    <rss version="2.0" xmlns:media="http://search.yahoo.com/mrss/">
		      <channel>
            <title>${resourceId}</title>
            <item>
              <title><![CDATA[${encodeURIComponent(title)}]]></title>
              <category>${league}</category>
              <guid>${mpxGuid}</guid>
              <media:rating scheme="urn:v-chip">${rating}</media:rating>
            </item>
		      </channel>
		    </rss>`,
            });
        };
        this._formatRating = (rating) => {
            var _a;
            return (_a = rating === null || rating === void 0 ? void 0 : rating.split(' ')[0]) === null || _a === void 0 ? void 0 : _a.toLowerCase();
        };
        this._getMediaToken = async (asset) => {
            try {
                // const encode = !asset.isLive
                // FIXME: Need to create a interface to determine if asset is live or vod
                // for this
                const encode = false;
                const endpoint = '/api/v1/tokens/media';
                const { server } = this._configuration;
                const url = `${server}${endpoint}?${this._getMrss(asset)}`;
                const options = { headers: await this.getBaseHeaders() };
                const response = await fetch(url, options);
                const data = await response.json();
                return encode ? encodeURIComponent(atob(data.serializedToken)) : data.serializedToken;
            }
            catch (err) {
                return err;
            }
        };
        this._configuration = configuration;
        this._mvpdData = null;
        this._pollingTimeOut = null;
        this._pollingInterval = null;
        this._deviceInfo = null;
        this._tempPassParamsFromRegCodeResponse = null;
        this._tempPass = null;
        this._createTempPassAttempts = 0;
        this._clientCredentials = null;
    }
    async init() {
        await this.getClientCredentials();
        await this.updateAccessToken();
    }
    get client_id() {
        var _a;
        return (_a = this._clientCredentials) === null || _a === void 0 ? void 0 : _a.client_id;
    }
    get client_secret() {
        var _a;
        return (_a = this._clientCredentials) === null || _a === void 0 ? void 0 : _a.client_secret;
    }
    get deviceInfo() {
        if (!this._deviceInfo) {
            this._deviceInfo = this.computeDeviceInfo();
        }
        return this._deviceInfo;
    }
    async getAccessToken() {
        var _a;
        if (!this._accessTokenData || this.accessTokenIsExpired())
            await this.updateAccessToken();
        return (_a = this._accessTokenData) === null || _a === void 0 ? void 0 : _a.access_token;
    }
    async getAuthorizationHeader() {
        return `Bearer ${await this.getAccessToken()}`;
    }
    async getBaseHeaders() {
        return {
            Accept: 'application/json',
            Authorization: await this.getAuthorizationHeader(),
        };
    }
    async getHeadersWithDeviceInfo() {
        return Object.assign(Object.assign({}, (await this.getBaseHeaders())), { 'X-Device-Info': this.deviceInfo });
    }
    accessTokenIsExpired() {
        const currentTime = moment();
        const expireTime = moment(this._accessTokenData.created_at + this._accessTokenData.expires_in * 1000);
        return currentTime.isAfter(expireTime);
    }
    getMvpdData() {
        return this._mvpdData;
    }
    getMvpdDataZip() {
        var _a, _b;
        return ((_a = this._mvpdData) === null || _a === void 0 ? void 0 : _a.zip) || ((_b = this._mvpdData) === null || _b === void 0 ? void 0 : _b.encryptedZip) || '';
    }
    getMvpdDataMvpdId() {
        var _a;
        return ((_a = this._mvpdData) === null || _a === void 0 ? void 0 : _a.mvpd) || '';
    }
    getMvpdTokenExpiration() {
        var _a;
        return (_a = this._mvpdData) === null || _a === void 0 ? void 0 : _a.expires;
    }
    isTempPassActive() {
        var _a, _b;
        return this.isMvpdTempPass() && ((_a = this._mvpdData) === null || _a === void 0 ? void 0 : _a.expires) && ((_b = this._mvpdData) === null || _b === void 0 ? void 0 : _b.expires) > Date.now();
    }
    isMvpdTempPass() {
        return this.getMvpdDataMvpdId().includes('TempPass');
    }
    isAuthenticated() {
        return this._mvpdData !== null && this._mvpdData !== undefined;
    }
    isProgramTempPassEligible(program, stream) {
        var _a, _b, _c;
        // Eligibility: MVPD-restricted Live Non-RSN SLE matching LaunchDarkly matchField: matchValue.
        if (!program)
            return false;
        const { initialTempPassName = null, contentTypes = [], matchField, matchValue, } = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass) || {};
        if (initialTempPassName === null)
            return false;
        const contentType = ((_a = CONTENT_TYPES[stream.programmingType]) === null || _a === void 0 ? void 0 : _a.toUpperCase()) ||
            ((_c = CONTENT_TYPES[(_b = stream.contentType) === null || _b === void 0 ? void 0 : _b.toLowerCase()]) === null || _c === void 0 ? void 0 : _c.toUpperCase());
        return (contentTypes.includes(contentType) &&
            ((DebugControllerSingleton === null || DebugControllerSingleton === void 0 ? void 0 : DebugControllerSingleton.allProgramsTempPassEligible) ||
                (program.regionEntitlementId === null && program[matchField] === matchValue)));
    }
    pollStatus() {
        var _a;
        this._clearPollingTimeOuts();
        const { identity } = (_a = AppConfigFactorySingleton === null || AppConfigFactorySingleton === void 0 ? void 0 : AppConfigFactorySingleton.config) !== null && _a !== void 0 ? _a : {};
        const pollingTimeout = ((identity === null || identity === void 0 ? void 0 : identity.polling_timeout) || 600) * 1000;
        const pollingInterval = ((identity === null || identity === void 0 ? void 0 : identity.polling) || 5) * 1000;
        return new Promise((resolve, reject) => {
            this._pollingTimeOut = setTimeout(() => {
                this._clearPollingTimeOuts();
                reject('Polling Timeout has happened');
            }, pollingTimeout);
            this._pollingInterval = setInterval(() => {
                // FIXME: We should migrate to listener/callback to fix the minor
                // race condition introduced by this code.
                if (!this._mvpdData ||
                    (this._mvpdData && this._mvpdData.expires - 60000 < new Date().getTime()) ||
                    this.isMvpdTempPass()) {
                    this.checkStatus().then((status) => {
                        if (status) {
                            this._clearPollingTimeOuts();
                            resolve();
                        }
                    });
                }
                else {
                    resolve();
                }
            }, pollingInterval);
        });
    }
    verifyMediaToken(mediatoken, channel, contentType) {
        const { streamKeys } = AppConfigFactorySingleton.config.access_vod;
        const { hostKey, endpoint } = TOKEN_VERIFIER_URLS[contentType];
        const url = `${AppConfigFactorySingleton.config.access_vod[hostKey]}/${endpoint}`;
        const { key: accessKey } = streamKeys[channel] || streamKeys[MAPPED_CHANNELS[channel] || 'nbcsports'];
        //const endpoint = '/access/entitlement/verifier'
        const headers = {
            'Content-Type': 'application/json',
            Accept: 'application/json',
        };
        const body = {
            mediatoken,
            channel,
            channelOverride: accessKey,
        };
        return fetch(`${url}`, {
            headers,
            method: 'POST',
            body: JSON.stringify(body),
        })
            .then((response) => response.json())
            .catch((error) => error);
    }
    _clearPollingTimeOuts() {
        if (this._pollingTimeOut)
            clearTimeout(this._pollingTimeOut);
        if (this._pollingInterval)
            clearInterval(this._pollingInterval);
    }
    computeDeviceInfo() {
        return CryptoJS.enc.Base64.stringify(CryptoJS.enc.Utf8.parse(JSON.stringify(TVPlatform.deviceInfo)));
    }
}
