import { Log, Registry, Storage } from '@lightningjs/sdk';
import { CCTypes, ClosedCaptionsUtils } from '../ClosedCaptions/ClosedCaptionsUtils';
import BasePlatform, { PlatformSubscriptionType, SubscriptionWrapper, TV_PLATFORM_TAG, } from './base';
import { KeyCodes, Keys, USER_OPT_OUT_PREFERENCE, PLAYER_SIZE, STORAGE_KEYS } from '../../constants';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
import { APP_IDENTIFIER, ErrorType, LEMONADE_PLATFORM, } from './types';
import { StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js';
import { SupportedPlatforms } from '../../graphql/generated/types';
import { getRenderPrecision } from '../../helpers';
import BaseAnnounce from '../tts/Announces/BaseAnnounce';
import VizioAnnounce from '../tts/Announces/VizioAnnounce';
class VizioSubscriptionWrapper extends SubscriptionWrapper {
    constructor(unsubscribeCb) {
        super();
        this.stale = false;
        this._unsubscribeCb = unsubscribeCb;
    }
    unsubscribe() {
        var _a;
        this.stale = true;
        (_a = this._unsubscribeCb) === null || _a === void 0 ? void 0 : _a.call(this);
    }
}
const CHIPSET_NOT_AVAILABLE = 'CHIPSET_NOT_AVAILABLE';
export default class VizioPlatform extends BasePlatform {
    constructor() {
        super(...arguments);
        this._platformName = 'Vizio';
        this._lemonadePlatform = LEMONADE_PLATFORM.VIZIO;
        this._bffPlatform = SupportedPlatforms.Vizio;
        this._streamingProtocol = StreamingProtocol.DASH;
        this._appIdentifier = APP_IDENTIFIER.VIZIO;
        this._limitedChipsets = [CHIPSET_NOT_AVAILABLE, '5581', '5581P'];
        this.subscribe = (evt, callback) => {
            // Filter stale events
            this._subscriptions = this._subscriptions.filter(({ stale }) => !stale);
            if (evt === PlatformSubscriptionType.VOICE) {
                this._getTTSEnabled().then(callback);
                const ttsEnable = () => callback(true);
                const ttsDisable = () => callback(false);
                Registry.addEventListener(document, 'VIZIO_TTS_ENABLED', ttsEnable);
                Registry.addEventListener(document, 'VIZIO_TTS_DISABLED', ttsDisable);
                return new VizioSubscriptionWrapper(() => {
                    Registry.removeEventListener(document, 'VIZIO_TTS_ENABLED', ttsEnable);
                    Registry.removeEventListener(document, 'VIZIO_TTS_DISABLED', ttsDisable);
                });
            }
            if (evt === PlatformSubscriptionType.CC) {
                if (!window.VIZIO)
                    return;
                window.VIZIO.setClosedCaptionHandler((enabled) => {
                    const ccType = enabled ? Storage.get(STORAGE_KEYS.APP_LANGUAGE) || CCTypes.en : CCTypes.off;
                    ClosedCaptionsUtils.setCCType(ccType);
                    callback(enabled);
                });
            }
            return undefined;
        };
    }
    get capabilities() {
        return {
            externalAppLinking: true,
            concurrentStreams: true,
        };
    }
    async init() {
        this._deviceType = 'Vizio TV';
        if (window.VIZIO) {
            Log.info('window.VIZIO found, adding event listeners');
            this._addVizioEventListeners();
        }
        else {
            Log.info('window.VIZIO not found, loading companion-lib and adding event listener');
            fetch('http://localhost:12345/scfs/cl/js/vizio-companion-lib.js').catch(async () => await super.generateDeviceId());
            Registry.addEventListener(document, 'VIZIO_LIBRARY_DID_LOAD', () => {
                Log.info('window.VIZIO library has loaded, adding event listeners');
                this._addVizioEventListeners();
            });
        }
    }
    _addVizioEventListeners() {
        window.VIZIO.getDeviceInformation().then((deviceInfo) => {
            Log.info('Vizio DeviceInformation Loaded using getDeviceInformation()');
            if (deviceInfo.SYSTEM_INFO.TVAD_ID) {
                Log.info(`Vizio UserOptOut: ${deviceInfo.SYSTEM_INFO.TVAD_ID.LMT}`, `AdvertiserID: ${deviceInfo.SYSTEM_INFO.TVAD_ID.IFA}`);
                this._userOptOut = deviceInfo.SYSTEM_INFO.TVAD_ID.LMT;
                this._advertiserId = deviceInfo.SYSTEM_INFO.TVAD_ID.IFA;
            }
            if (deviceInfo.SYSTEM_INFO.CHIPSET_NAME) {
                this._chipsetName = deviceInfo.SYSTEM_INFO.CHIPSET_NAME;
            }
            else {
                this._chipsetName = CHIPSET_NOT_AVAILABLE;
            }
        });
        window.VIZIO.setAdvertiserIDListener((AdvertiserID) => {
            Log.info(`AdvertiserID.IFA: ${AdvertiserID.IFA}`, `AdvertiserID.LMT: ${AdvertiserID.LMT}`);
            this._userOptOut = AdvertiserID.LMT;
            this._advertiserId = AdvertiserID.IFA;
        });
        window.VIZIO.getDeviceId(async (deviceId) => {
            Log.info(`Unique Device Id: ${deviceId}`);
            if (this.deviceId)
                return;
            if (deviceId) {
                this.deviceId = deviceId;
            }
            else {
                await super.generateDeviceId();
            }
        });
        window.VIZIO.getFirmwareVersion((firmwareVersion) => {
            Log.info(`Device Firmware Version: ${firmwareVersion}`);
            this._firmwareVersion = firmwareVersion;
        });
    }
    getStageSettings() {
        return {
            precision: 0.6666666666666666,
            devicePixelRatio: window.devicePixelRatio || 1,
        };
    }
    getPlatformKeyMapping() {
        return Object.assign(Object.assign({}, this.getAtoZAndNumberKeyMapping(true)), { [KeyCodes.vizio.Exit]: Keys.EXIT, [KeyCodes.vizio.Left]: Keys.ARROW_LEFT, [KeyCodes.vizio.Up]: Keys.ARROW_UP, [KeyCodes.vizio.Right]: Keys.ARROW_RIGHT, [KeyCodes.vizio.Down]: Keys.ARROW_DOWN, [KeyCodes.vizio.Pause]: Keys.MEDIA_PAUSE, [KeyCodes.vizio.Rewind]: Keys.MEDIA_REWIND, [KeyCodes.vizio.Stop]: Keys.MEDIA_STOP, [KeyCodes.vizio.Play]: Keys.MEDIA_PLAY, [KeyCodes.vizio.FastForward]: Keys.MEDIA_FAST_FORWARD, [KeyCodes.vizio.MediaTrackNext]: Keys.MEDIA_TRACK_NEXT, [KeyCodes.vizio.MediaTrackPrevious]: Keys.MEDIA_TRACK_PREVIOUS, [KeyCodes.vizio.AudioVolumeMute]: Keys.VOLUME_MUTE, [KeyCodes.vizio.AudioVolumeDown]: Keys.VOLUME_DOWN, [KeyCodes.vizio.AudioVolumeUp]: Keys.VOLUME_UP, [KeyCodes.vizio.Info]: Keys.INFO });
    }
    getUserOptOut() {
        try {
            const storageOptOut = Number(Storage.get(STORAGE_KEYS.USER_OPT_OUT));
            return storageOptOut === USER_OPT_OUT_PREFERENCE.DISALLOW_SALE ||
                DebugControllerSingleton.userOptOut ||
                this._userOptOut
                ? USER_OPT_OUT_PREFERENCE.DISALLOW_SALE
                : USER_OPT_OUT_PREFERENCE.ALLOW_SALE;
        }
        catch (e) {
            this.reportError({
                type: ErrorType.OTHER,
                code: TV_PLATFORM_TAG,
                description: 'Error retrieving User Opt Out Preference',
                payload: e,
            });
            return super.getUserOptOut();
        }
    }
    getAdvertiserId() {
        return this._advertiserId || super.getAdvertiserId();
    }
    async getModelNumber() {
        if (window.VIZIO && window.VIZIO.isSmartCastDevice) {
            return window.VIZIO.deviceModel;
        }
        return await super.getModelNumber();
    }
    getFirmware() {
        var _a;
        return (_a = this._firmwareVersion) !== null && _a !== void 0 ? _a : super.getFirmware();
    }
    exit() {
        var _a;
        super.exit();
        (_a = window.VIZIO) === null || _a === void 0 ? void 0 : _a.exitApplication();
    }
    exitToPeacock() {
        var _a;
        if (!window.VIZIO) {
            super.exitToPeacock();
            return;
        }
        try {
            if (window.VIZIO) {
                (_a = window.VIZIO) === null || _a === void 0 ? void 0 : _a.launchApplication({
                    NAME_SPACE: 2,
                    APP_ID: '88',
                    MESSAGE: '',
                });
            }
        }
        catch (error) {
            Log.warn(error);
            super.exitToPeacock();
        }
    }
    async _getTTSEnabled() {
        if (window.VIZIO) {
            const deviceManifest = await window.VIZIO.getDeviceManifest();
            if (window.VIZIO.Chromevox) {
                return deviceManifest.tts_enabled;
            }
        }
        return false;
    }
    /**
     * override video scaling
     * for Vizio, we need to scale down the secondary player but ensure that the full screen video is max
     * @returns {number}
     */
    scaleVideoProperty(val, type) {
        return type === PLAYER_SIZE.SECONDARY ? Math.round(val * getRenderPrecision()) : val;
    }
    tts() {
        return {
            speak(toSpeak, notification = false) {
                var _a;
                return ((_a = window.VIZIO) === null || _a === void 0 ? void 0 : _a.Chromevox)
                    ? new VizioAnnounce(toSpeak, notification)
                    : new BaseAnnounce(toSpeak, notification);
            },
            cancel() {
                var _a;
                if ((_a = window.VIZIO) === null || _a === void 0 ? void 0 : _a.Chromevox)
                    return window.VIZIO.Chromevox.cancel();
                window.speechSynthesis.cancel();
            },
        };
    }
    // we are having problems with linear shaders for some chipsets
    getAllowLinearGradient() {
        if (this._limitedChipsets.includes(this._chipsetName)) {
            return false;
        }
        return true;
    }
}
