import { Storage } from '@lightningjs/sdk';
import { get } from 'lodash';
import { evict as evictFromCache } from '../graphql/cache';
export const saveLatestCachedItem = (storageKey, id) => {
    const previousId = Storage.get(storageKey);
    if (previousId && previousId !== id) {
        // Clear previous video data from cache.
        evictFromCache(previousId);
    }
    Storage.set(storageKey, id);
};
export const findSeriesTile = (sections, urlAlias) => {
    for (let i = 0; i < sections.length; i += 1) {
        const seriesTile = (get(sections, `[${i}].data.items`) || []).filter((item) => item.component === 'SeriesTile' && item.data.urlAlias === urlAlias);
        if (seriesTile.length)
            return seriesTile.pop();
    }
    return {};
};
export const getNewFavoritesShelf = (seriesTile) => ({
    analytics: {
        isMixedTiles: false,
        isPlaylist: false,
        isSponsoredContent: null,
        itemsList: [],
        listTitle: 'Favorites',
        machineName: 'favorites',
        playlistMachineName: null,
        sponsorName: null,
        __typename: 'ShelfAnalyticsAttributes',
    },
    component: 'Shelf',
    data: {
        items: [
            Object.assign(Object.assign({}, seriesTile), {
                component: 'SeriesTile',
                meta: null,
                analytics: null,
                treatment: 'default',
                __typename: 'SeriesTile',
            }),
        ],
        moreItems: null,
        instanceID: `${Math.random()}`,
        lastModified: null,
        listTitle: 'Favorites',
        sponsorLogo: null,
        sponsorLogoAltText: null,
        sponsorName: null,
        __typename: 'TileList',
    },
    deepLinkHandle: null,
    logicName: 'favoritesShelf',
    meta: { machineName: 'favorites-shelf' },
    treatments: [],
    __typename: 'Shelf',
});
