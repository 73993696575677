import { Lightning, Registry } from '@lightningjs/sdk';
import { Subject } from 'rxjs';
import { PlaybackType, StreamingProtocol } from '@sky-uk-ott/core-video-sdk-js-core';
import { PLAYER_TYPE, PlayerFactorySingleton } from '../player/core/PlayerFactory';
import { PlayerStatusEvent } from '../player/model/event/PlayerStatusEvent';
import { PlayerStatus } from '../player/model/PlayerStatus';
import { TimeChangeEvent } from '../player/model/event/TimeChangeEvent';
import { PROGRAMMING_TYPES, PLAYER_SIZE } from '../constants';
import LaunchDarklySingleton from '../lib/launchDarkly/LaunchDarkly';
import LaunchDarklyFeatureFlags from '../lib/launchDarkly/LaunchDarklyFeatureFlags';
import TVPlatform from './tv-platform';
var PlayerDOMId;
(function (PlayerDOMId) {
    PlayerDOMId["preview"] = "preview-player";
    PlayerDOMId["background"] = "background-player";
})(PlayerDOMId || (PlayerDOMId = {}));
const SecondaryPlayerBoundaries = {
    x: TVPlatform.scaleVideoProperty(80, PLAYER_SIZE.SECONDARY),
    y: TVPlatform.scaleVideoProperty(190, PLAYER_SIZE.SECONDARY),
    w: TVPlatform.scaleVideoProperty(960, PLAYER_SIZE.SECONDARY),
    h: TVPlatform.scaleVideoProperty(580, PLAYER_SIZE.SECONDARY),
};
export const createXumoHoleShader = (activated) => activated
    ? Object.assign({ type: Lightning.shaders.Hole }, SecondaryPlayerBoundaries) : null;
export const isSecondaryPlayerLoadObject = (input) => {
    var _a, _b;
    return !!(input === null || input === void 0 ? void 0 : input.playbackUrl) &&
        !!((_b = (_a = input === null || input === void 0 ? void 0 : input.playbackUrl) === null || _a === void 0 ? void 0 : _a.match) === null || _b === void 0 ? void 0 : _b.call(_a, /http/)) &&
        [PLAYER_TYPE.BACKGROUND, PLAYER_TYPE.PREVIEW].includes(input.type);
};
var PlayerInstanceState;
(function (PlayerInstanceState) {
    PlayerInstanceState[PlayerInstanceState["IDLE"] = 0] = "IDLE";
    PlayerInstanceState[PlayerInstanceState["ACTIVE"] = 1] = "ACTIVE";
})(PlayerInstanceState || (PlayerInstanceState = {}));
class SecondaryPlayer {
    constructor() {
        this.events = new Subject();
        this._timeouts = [];
        this._players = {};
        this._loadDelay = 2000;
        this._destroyTime = 15000;
        this._loadSource = ({ playbackUrl, type }, playerOptions) => {
            const player = PlayerFactorySingleton.player(type, playerOptions);
            player === null || player === void 0 ? void 0 : player.load(this._createMockPlayerObject(playbackUrl));
            const subscription = player === null || player === void 0 ? void 0 : player.normalizedPlayerEvents.events().subscribe(this._handlePlayerEvent(type));
            this._players[type] = {
                type,
                status: PlayerInstanceState.ACTIVE,
                duration: 0,
                position: 0,
                subscription,
            };
        };
        this.destroy = () => {
            var _a;
            this._clearTimeout();
            for (const key in this._players) {
                const type = key;
                if (((_a = this._players[type]) === null || _a === void 0 ? void 0 : _a.status) === PlayerInstanceState.ACTIVE) {
                    this.events.next({ activated: false, type });
                }
                this._clearSubscription(type);
                PlayerFactorySingleton.clearPlayer(type);
            }
            this._players = {};
        };
    }
    get isPreviewPlayerActive() {
        return !!this._players[PLAYER_TYPE.PREVIEW];
    }
    load({ playbackUrl, type }) {
        var _a, _b;
        this._clearTimeout();
        const playerIndex = Object.values(this._players)
            .filter((player) => player.status === PlayerInstanceState.ACTIVE)
            .findIndex((player) => player.type === type);
        const delay = playerIndex > 0 ? playerIndex * this._loadDelay : 0;
        (_b = (_a = this._players[type]) === null || _a === void 0 ? void 0 : _a.subscription) === null || _b === void 0 ? void 0 : _b.unsubscribe();
        const playerOptions = type === PLAYER_TYPE.PREVIEW
            ? {
                left: SecondaryPlayerBoundaries.x,
                top: SecondaryPlayerBoundaries.y,
                width: SecondaryPlayerBoundaries.w,
                height: SecondaryPlayerBoundaries.h,
                zIndex: 2,
                domId: PlayerDOMId.preview,
            }
            : { domId: PlayerDOMId.background };
        this._timeouts.push(Registry.setTimeout(() => this._loadSource({ playbackUrl, type }, playerOptions), delay));
    }
    getPreviewVideoAnalytics() {
        const player = this._players[PLAYER_TYPE.PREVIEW];
        const position = Math.max(0, (player === null || player === void 0 ? void 0 : player.position) || 0);
        const duration = Math.max(0, (player === null || player === void 0 ? void 0 : player.duration) || position);
        const percentWatched = (position * 100) / duration;
        return {
            duration,
            percentWatched: percentWatched > 100 ? 100 : percentWatched,
            position,
        };
    }
    _handlePlayerEvent(type) {
        const player = PlayerFactorySingleton.getPlayerIfActive(type);
        return (event) => {
            var _a, _b;
            if (event instanceof PlayerStatusEvent) {
                if (event.status === PlayerStatus.FINISHED) {
                    player === null || player === void 0 ? void 0 : player.setVisibility(false);
                    this.events.next({ activated: false, type });
                }
                else if (event.status === PlayerStatus.PLAYING) {
                    if (player) {
                        const isPreview = type === PLAYER_TYPE.PREVIEW;
                        if (isPreview)
                            (_a = player.forceVideoSize) === null || _a === void 0 ? void 0 : _a.call(player);
                        /**
                         * https://nbcdigital.atlassian.net/browse/PL-2326?focusedCommentId=870999
                         * LaunchDarklyFeatureFlags.marketingModuleAudio Flag is a boolean type
                         * When the flag value returns true, enable audio on the Marketing Module foreground video (video preview)
                         * When the flag value returns false, disable audio on the Marketing Module foreground video (video preview)
                         */
                        if (!isPreview ||
                            !LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.marketingModuleAudio)) {
                            player.setMute(true);
                        }
                        player.setVisibility(true);
                    }
                    this.events.next({ activated: true, type });
                }
            }
            else if (event instanceof TimeChangeEvent && this._players[type]) {
                const target = this._players[type];
                target.position = event.time;
                // seekableRange refers to the available seekable time in the manifest.
                // Being optimistic it should equal to duration in short videos,
                // since we don't receive that data from the backend.
                target.duration = (_b = event.seekableRange) === null || _b === void 0 ? void 0 : _b.end;
            }
        };
    }
    _createMockPlayerObject(playbackUrl) {
        const now = Date.now().toString();
        return {
            stream: {
                duration: 0,
                externalAdvertiserId: '123456',
                programmingType: PROGRAMMING_TYPES.CLIP,
                v4ID: '',
                channelId: 'nbc',
                title: '',
                permalink: '',
                secondaryTitle: '',
                pid: 0,
                airDate: now,
                endTime: now,
            },
            program: {
                callSign: '',
                duration: 0,
                airDate: now,
                locked: false,
                league: '',
                sport: '',
                clipCategory: '',
                language: '',
                adobeVideoResearchTitle: '',
                dayPart: '',
                title: '',
                programmingType: PROGRAMMING_TYPES.CLIP,
                videoType: PlaybackType.Preview,
            },
            lemonade: {
                type: playbackUrl.includes('m3u') ? StreamingProtocol.HLS : StreamingProtocol.DASH,
                playbackUrl,
                playbackUrls: [],
            },
        };
    }
    _clearSubscription(type) {
        if (this._players[type]) {
            const target = this._players[type];
            if (target.subscription) {
                target.subscription.unsubscribe();
                target.subscription = undefined;
            }
        }
    }
    unload() {
        for (const key in this._players) {
            const type = key;
            const target = this._players[type];
            this._clearSubscription(type);
            if (target.status === PlayerInstanceState.ACTIVE) {
                this.events.next({ activated: false, type });
                const player = PlayerFactorySingleton.player(type);
                player === null || player === void 0 ? void 0 : player.pause();
                player === null || player === void 0 ? void 0 : player.setVisibility(false);
                target.status = PlayerInstanceState.IDLE;
            }
        }
        this._setDestroyTimeout();
    }
    subscribe(cb) {
        return this.events.subscribe(cb);
    }
    _setDestroyTimeout() {
        this._clearTimeout();
        this._timeouts.push(Registry.setTimeout(this.destroy, this._destroyTime));
    }
    _clearTimeout() {
        if (!this._timeouts.length)
            return;
        this._timeouts.forEach((timeout, index) => {
            Registry.clearTimeout(timeout);
            this._timeouts.splice(index);
        });
    }
}
export const SecondaryPlayerSingleton = new SecondaryPlayer();
