'use strict'
Object.defineProperty(exports, '__esModule', { value: true })
exports.Icons = void 0
var Icons
;(function(Icons) {
  Icons['Account'] = '\uE900'
  Icons['AddedWatchlist'] = '\uE910'
  Icons['AddWatchlist'] = '\uE911'
  Icons['ArrowDown'] = '\uE926'
  Icons['ArrowLeft'] = '\uE90B'
  Icons['ArrowRight'] = '\uE90A'
  Icons['ArrowUp'] = '\uE927'
  Icons['AudioDescription'] = '\uE92A'
  Icons['AudioDescriptionOutline'] = '\uE924'
  Icons['CaretLeft'] = '\uE905'
  Icons['CaretRight'] = '\uE934'
  Icons['Cart'] = '\uE92B'
  Icons['Checkmark'] = '\uE912'
  Icons['CheckmarkFilled'] = '\uE91E'
  Icons['Clock'] = '\uE91C'
  Icons['Delete'] = '\uE90C'
  Icons['DolbyAtmos'] = '\uE92F'
  Icons['DolbyVision'] = '\uE930'
  Icons['DolbyVisionAtmos'] = '\uE931'
  Icons['Edit'] = '\uE915'
  Icons['Ellipsis'] = '\uE90D'
  Icons['Episodes'] = '\uE90F'
  Icons['Exclamation'] = '\uE919'
  Icons['FastForward'] = '\uE902'
  Icons['Fullscreen'] = '\uE908'
  Icons['Hdr'] = '\uE932'
  Icons['HidePassword'] = '\uE91A'
  Icons['Home'] = '\uE920'
  Icons['Info'] = '\uE906'
  Icons['InfoFilled'] = '\uE91D'
  Icons['InfoOutline'] = '\uE935'
  Icons['Keyboard'] = '\uE913'
  Icons['Live'] = '\uE928'
  Icons['Lock'] = '\uE92E'
  Icons['MoreEpisodes'] = '\uE936'
  Icons['New'] = '\uE916'
  Icons['Next'] = '\uE918'
  Icons['Pause'] = '\uE903'
  Icons['Playlist'] = '\uE914'
  Icons['PremiumBadge'] = '\uE925'
  Icons['Previous'] = '\uE921'
  Icons['Reload'] = '\uE922'
  Icons['Restart'] = '\uE929'
  Icons['Rewind'] = '\uE904'
  Icons['Search'] = '\uE901'
  Icons['SeekToLiveEdge'] = '\uE909'
  Icons['Settings'] = '\uE917'
  Icons['ShowPassword'] = '\uE91B'
  Icons['SkipBackward'] = '\uE92C'
  Icons['SkipForward'] = '\uE92D'
  Icons['Sound'] = '\uE923'
  Icons['Space'] = '\uE90E'
  Icons['Subtitles'] = '\uE907'
  Icons['Toggle'] = '\uE937'
  Icons['Uhd'] = '\uE933'
  Icons['UpcomingEvent'] = '\uE91F'
})((Icons = exports.Icons || (exports.Icons = {})))
