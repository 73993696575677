import { isBionicStream } from '../../../api/Live';
import PlayerStoreSingleton from '../../../store/PlayerStore/PlayerStore';
import { getLemonade } from '../../../store/PlayerStore/actions/lemonade';
import { authenticate } from './actions/authenticate';
import { linearSleFerAuthorize, vodAuthorize } from './actions/authorize';
import { geo } from './actions/geo';
import { missingDataError } from './error';
import { StreamAuthorizationType, createLoader } from './request';
const vodStreamLoader = (stream, program, lemonade, bypass) => {
    return createLoader([authenticate, geo, vodAuthorize(bypass)], stream, program, lemonade);
};
const linearSleFerLoader = (stream, program, lemonade, isPreCheck = false) => {
    return createLoader([authenticate, geo, linearSleFerAuthorize], stream, program, lemonade, isPreCheck);
};
const nbcNewsLoader = (stream) => PlayerStoreSingleton.dispatch(getLemonade(stream))
    .then(() => ({ authType: StreamAuthorizationType.UNLOCKED }))
    .catch(() => Promise.reject({ authType: StreamAuthorizationType.UNLOCKED, error: missingDataError('') }));
export const StreamLoader = (stream, program, lemonade, bypass = false, isPreCheck = false) => {
    // Some live stream will also have mpxguid so adding additional check
    if ((stream === null || stream === void 0 ? void 0 : stream.mpxGuid) && !(stream === null || stream === void 0 ? void 0 : stream.stationId)) {
        return vodStreamLoader(stream, program, lemonade, bypass);
    }
    if (isBionicStream(stream === null || stream === void 0 ? void 0 : stream.channelId)) {
        return linearSleFerLoader(stream, program, lemonade, isPreCheck);
    }
    return nbcNewsLoader(stream);
};
