import { Language, Lightning, Router, Storage } from '@lightningjs/sdk';
import Item from './Item';
import { liveBadgeDecorator, liveProgressDecorator, lockDecorator } from './decorators';
import { COLORS, ENTITY_TYPES, FONT_FACE, ROUTE, STORAGE_KEYS } from '../../constants';
import { FastImg } from '../../helpers';
import { DebugControllerSingleton } from '../../util/debug/DebugController';
import { OLY_IMPOLICY } from '../../constants/images';
import { LiveStreamManager } from '../../lib/LiveStreamManager';
export default class OnAirNowTile extends Item {
    _init() {
        super._init();
        this.containerHeight = 330;
        this.containerWidth = 420;
    }
    static _template() {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        const duration = super.duration;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore TS2340: Only public and protected methods of the base class are accessible via the 'super' keyword.
        const timingFunction = super.timingFunction;
        const template = super._template();
        return Object.assign(Object.assign({}, template), { ShadowBlur: {
                mount: 0.5,
                x: 210,
                y: 120,
                w: 520,
                h: 300,
                alpha: 0.8,
                type: Lightning.components.FastBlurComponent,
                amount: 1,
                content: {
                    Shadow: {
                        color: COLORS.black,
                        mount: 0.5,
                        x: 260,
                        y: 150,
                        texture: Lightning.Tools.getRoundRect(420, 235, 0),
                    },
                },
                transitions: {
                    y: { duration, timingFunction },
                    scale: { duration, timingFunction },
                    alpha: { duration, timingFunction },
                    amount: { duration, timingFunction },
                },
            }, ImageHolder: {
                rect: true,
                colorLeft: COLORS.brightGrey,
                colorRight: COLORS.thunder2,
                Image: {
                    pivotX: 0,
                    transitions: {
                        scale: { duration, timingFunction },
                    },
                },
            }, Content: {
                y: 250,
                TitleCard: {
                    ShortTitle: {
                        text: {
                            fontFace: FONT_FACE.semiBold,
                            fontSize: 24,
                            lineHeight: 36,
                            maxLines: 2,
                            wordWrapWidth: 360,
                        },
                    },
                    transitions: {
                        y: { duration, timingFunction },
                    },
                },
                Logo: {
                    y: 4,
                    mountX: 1,
                    x: 420,
                    transitions: {
                        y: { duration, timingFunction },
                    },
                },
            } });
    }
    set item(v) {
        this._item = v;
        this.patch({
            ImageHolder: {
                w: 420,
                h: 235,
                Image: { texture: FastImg(v.image).contain(420, 235, OLY_IMPOLICY.RECT_SMEDIUM_640_360) },
            },
            Content: {
                TitleCard: {
                    ShortTitle: {
                        text: { text: v.title },
                    },
                },
                Logo: {
                    texture: FastImg(v.whiteBrandLogo).contain(60, 40, OLY_IMPOLICY.RECT_SMALL_384_216),
                },
            },
        });
        this._addDecorators([
            liveProgressDecorator(239),
            liveBadgeDecorator(Language.translate('live'), 184),
        ]);
    }
    get announce() {
        return this._item.liveAriaLabel;
    }
    _active() {
        if (this._item && this._item.isLocked) {
            lockDecorator(this);
        }
    }
    _handleEnter() {
        var _a, _b;
        Storage.remove(STORAGE_KEYS.PREVIOUS_STREAM);
        LiveStreamManager.set(this._item.channelId, this._item.streamAccessName || '', this._item.callSign || '');
        this.trackContentPosition();
        this.trackContentClick(ENTITY_TYPES.ON_AIR);
        DebugControllerSingleton.streamUrl = this._item.HARDCODEDSTREAM;
        Router.navigate(ROUTE.live, {
            assetMetadata: (_b = (_a = this._item) === null || _a === void 0 ? void 0 : _a.obj) === null || _b === void 0 ? void 0 : _b.data,
            preventClickMetric: true,
        });
    }
    static get width() {
        return 420;
    }
    static get tag() {
        return 'OnAirNowTile';
    }
}
