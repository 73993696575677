import { EpgGuideV2 } from '../../../components';
import { EPG_SLOT_WIDTH, EPG_SLOT_PADDING, PlayerStates, KeyCodes } from '../../../constants';
import { isSleLiveGuideAllowed, isXbox, setSmooth } from '../../../helpers';
import { EpgStateFactory } from '../states/Epg';
import { SubscriptionBuilder, SubscriptionSources } from '../../../util/SubscriptionBuilder';
import { PlayerStoreEvents } from '../../../store/PlayerStore/PlayerStore';
import { SectionsSpawner } from '../../../api/spawners';
import { sendMetric } from '../../../lib/analytics/Analytics';
import { EVENTS } from '../../../lib/analytics/types';
import { getControlsAnalytics } from '../../../components/player/helpers/metadata';
import { PlayerError } from '../../../components/error/PlayerError';
import { inRange } from 'lodash';
import BasePlayer from '../BasePlayer';
import ModalManager, { ModalTypes } from '../../../lib/ModalManager';
import { FatalErrorEvent } from '../../../player/model/event';
import { PlayerStatus } from '../../../player/model/PlayerStatus';
const V1_LIVE_CHANGE = PlayerStoreEvents.EPG_CHANNEL_UPDATED;
const V2_EPG_ERROR = PlayerStoreEvents.EPG_ERROR;
class HasEpg extends BasePlayer {
    loadEpg() { }
    loadStream() { }
}
export const WithEpg = (component) => class extends component {
    constructor() {
        super(...arguments);
        this._epgOpened = false;
        this._adsPlaying = false;
        this._liveGuideEnabled = true;
    }
    static _states() {
        // @ts-expect-error TODO: fix super not working on HOCs
        return [...super._states(), EpgStateFactory(this)];
    }
    _handleKey(key) {
        // Adding 1 to end because the inRange util doesn't include the end value
        const arrowKeyRange = isXbox()
            ? [KeyCodes.xbox.Up, KeyCodes.xbox.Right + 1]
            : [KeyCodes.vizio.Left, KeyCodes.vizio.Down + 1];
        if (ModalManager._activeModal === ModalTypes.PLAYER_ERROR &&
            inRange(key.keyCode, ...arrowKeyRange)) {
            this.$openLiveGuide(true);
        }
    }
    _onErrorModalLiveGuide() {
        var _a, _b;
        if (((_a = this._player) === null || _a === void 0 ? void 0 : _a.status) !== PlayerStatus.UNKNOWN && !((_b = this._player) === null || _b === void 0 ? void 0 : _b.isPlaying()))
            this._resetStream();
        this.$openLiveGuide(true);
    }
    _init() {
        super._init();
        this.patch({
            Epg: {
                type: EpgGuideV2,
                programImageWidth: 380,
                itemHeight: 190,
                itemWidth: EPG_SLOT_WIDTH,
                itemPadding: EPG_SLOT_PADDING,
                visible: false,
            },
        });
        this._epg = this.tag('Epg');
        this._epgSubscription = new SubscriptionBuilder()
            .with(SubscriptionSources.PLAYER_STORE)
            .subscribe(this._onEpgEvent.bind(this));
    }
    _detach() {
        var _a;
        super._detach();
        (_a = this._epgSubscription) === null || _a === void 0 ? void 0 : _a.unsubscribe();
        this._epgSubscription = undefined;
    }
    async _onEpgEvent(payload) {
        switch (payload.type) {
            case V2_EPG_ERROR:
                this.setStateAsEpg(true);
                break;
            default:
                break;
        }
    }
    // TODO Remove this method when live guide V1 won't be supported
    async _onEpgEventV1(payload) {
        var _a, _b;
        switch (payload.type) {
            case PlayerStoreEvents.EPG_OK:
                try {
                    if (!this._epg)
                        break;
                    const upcoming = await SectionsSpawner(this.stage, [payload.upcoming]);
                    (_a = this._epg) === null || _a === void 0 ? void 0 : _a.sync();
                    (_b = this._epg) === null || _b === void 0 ? void 0 : _b.load();
                    this._epg.visible = true;
                    if ((upcoming === null || upcoming === void 0 ? void 0 : upcoming.length) && isSleLiveGuideAllowed() && 'upcoming' in this._epg)
                        this._epg.upcoming = upcoming[0];
                    if (this.widgets.loader.visible)
                        setSmooth(this.widgets.loader, 'visible', 0);
                    this.loadEpg();
                    this._setState(PlayerStates.Epg);
                }
                catch (_c) {
                    // no op
                }
                break;
            case PlayerStoreEvents.ERROR:
                this._setErrorState(PlayerError.UNKNOWN, new FatalErrorEvent({
                    description: 'Unable to find stream in Episode Guide',
                    code: '404',
                }));
                break;
            case V1_LIVE_CHANGE:
                this.loadStream();
                break;
        }
    }
    _onLoaderExit() {
        if (this._epgOpened) {
            this._epgOpened = false; // This goes first to avoid infinite loop
            this.$openLiveGuide(true);
        }
    }
    _onAdStart(event) {
        super._onAdStart(event);
        this._adsPlaying = true;
    }
    _onAdEnd() {
        super._onAdEnd();
        this._adsPlaying = false;
    }
    async $openLiveGuide(isError) {
        this.setStateAsEpg(isError);
        sendMetric(EVENTS.CLICK, Object.assign({ name: 'EPG' }, getControlsAnalytics()));
    }
    setStateAsEpg(isError) {
        this._setState(PlayerStates.Epg, [isError]);
    }
    _hideUI() {
        super._hideUI();
        if (this._epg)
            this._epg.alpha = 0;
    }
    _disableRouterBack() {
        return this._epgOpened;
    }
};
