import { StreamLoaderErrors } from './error';
import { activateRedirect, activateTempPassRedirect } from './actions/redirect';
import LaunchDarklySingleton from '../../../lib/launchDarkly/LaunchDarkly';
import { TEMP_PASS_REDIRECT } from '../BasePlayer';
import LaunchDarklyFeatureFlags from '../../../lib/launchDarkly/LaunchDarklyFeatureFlags';
import { DebugControllerSingleton } from '../../../util/debug/DebugController';
import { isNBCProfileLinked } from '../../../helpers';
import { openPlayerLoader } from '../../../widgets/Modals/playerLoader/PlayerLoader';
export var StreamAuthorizationType;
(function (StreamAuthorizationType) {
    StreamAuthorizationType["UNLOCKED"] = "None";
    StreamAuthorizationType["CREDIT"] = "NBC Credit";
    StreamAuthorizationType["MVPD"] = "MVPD";
    StreamAuthorizationType["TEMP_PASS"] = "Temp Pass";
})(StreamAuthorizationType || (StreamAuthorizationType = {}));
const isTempPassConcluded = () => {
    const flag = LaunchDarklySingleton.getFeatureFlag(LaunchDarklyFeatureFlags.tempPass);
    const isTempPassPhase1 = flag
        ? !flag.secondaryTempPassName
        : !DebugControllerSingleton.tempPassName[0];
    if (!flag.identityRequiredForSecondary) {
        return true;
    }
    return isTempPassPhase1 || isNBCProfileLinked();
};
export const createLoader = async (requests, stream, program, lemonade, isPreCheck = false) => requests
    .reduce((acc, req) => acc.then((res) => req(res, stream, program, lemonade, isPreCheck)), Promise.resolve({}))
    .then((res) => {
    if (!res.authType)
        throw res;
    openPlayerLoader(stream, program);
    return res;
})
    .catch((e) => {
    var _a;
    if (e.error) {
        const error = (_a = e.error) === null || _a === void 0 ? void 0 : _a.detail;
        switch (error) {
            case StreamLoaderErrors.TEMP_PASS_REQUEST_FAILED:
                activateTempPassRedirect(TEMP_PASS_REDIRECT.TEMP_PASS_MVPD);
                break;
            case StreamLoaderErrors.TEMP_PASS_EXPIRED:
                activateTempPassRedirect(isTempPassConcluded()
                    ? TEMP_PASS_REDIRECT.TEMP_PASS_CONCLUDED
                    : TEMP_PASS_REDIRECT.TEMP_PASS_NBC_ACCOUNT);
                break;
            case StreamLoaderErrors.UNAUTHENTICATED:
            case StreamLoaderErrors.NO_CREDITS:
                activateRedirect(e.error, stream, program);
                break;
            default:
                throw e;
        }
        e.error.handled = true;
    }
    throw e;
});
