import { PlayerStoreActions, setUnknownError, throwStoreError } from '.';
import { LiveStreamManager } from '../../../lib/LiveStreamManager';
import { looseStringCompare } from '../../../util/comparison';
import PlayerStoreSingleton from '../PlayerStore';
export const isEpgProgram = (input) => input && 'programmingType' in input;
export const isEpgStream = (input) => input && 'brandDisplayTitle' in input;
const transformStreams = (streams) => streams ? streams.map((item) => (item === null || item === void 0 ? void 0 : item.data) || null) : [];
const transformSchedules = (schedules) => schedules
    ? schedules.map((item) => ({
        programs: (item === null || item === void 0 ? void 0 : item.data.programs.map((program) => {
            var _a;
            return (Object.assign(Object.assign({ component: program === null || program === void 0 ? void 0 : program.component }, (((_a = program === null || program === void 0 ? void 0 : program.analytics) === null || _a === void 0 ? void 0 : _a.currentVideo) || {})), ((program === null || program === void 0 ? void 0 : program.guideProgramData) || {})));
        })) || [],
    }))
    : [];
// Type guards
const isGuide = (input) => (input === null || input === void 0 ? void 0 : input.slots) && (input === null || input === void 0 ? void 0 : input.streams) && (input === null || input === void 0 ? void 0 : input.schedules);
const isEventSchedule = (input) => input === null || input === void 0 ? void 0 : input.events;
const isShelf = (input) => (input === null || input === void 0 ? void 0 : input.component) === 'Shelf';
const mergeEvents = (models) => {
    if (!models || !models.length)
        return undefined;
    return models.reduce((acc, model) => {
        if (model && 'data' in model) {
            const { data } = model;
            if (isGuide(data)) {
                const streams = transformStreams(data.streams);
                const { stream } = PlayerStoreSingleton;
                const { channelId, accessName } = LiveStreamManager.get();
                const index = streams.findIndex(filterByChannelId((stream === null || stream === void 0 ? void 0 : stream.channelId) || channelId || '', (stream === null || stream === void 0 ? void 0 : stream.streamAccessName) || accessName || '', (stream === null || stream === void 0 ? void 0 : stream.machineName) || ''));
                acc.slots = [...acc.slots, ...(('slots' in data && data.slots) || [])];
                acc.streams = [...acc.streams, ...transformStreams(data.streams)];
                acc.schedules = [...acc.schedules, ...transformSchedules(data.schedules)];
                acc.start = data.start;
                acc.index = index;
            }
            else if (isEventSchedule(data)) {
                acc.events = [...acc.events, ...(data.events || [])];
            }
            else if (isShelf(model)) {
                acc.upcoming = model;
            }
        }
        return acc;
    }, {
        index: 0,
        programIndex: 0,
        start: '',
        slots: [],
        streams: [],
        schedules: [],
        events: [],
        upcoming: null,
        progress: 0,
    });
};
export const filterByChannelId = (channelId = '', streamAccessName, machineName) => (stream) => looseStringCompare(channelId, stream === null || stream === void 0 ? void 0 : stream.channelId) &&
    (!machineName || looseStringCompare(machineName, stream === null || stream === void 0 ? void 0 : stream.machineName)) &&
    (!streamAccessName || (stream === null || stream === void 0 ? void 0 : stream.streamAccessName) === streamAccessName);
export const setEpgChannel = (channelId, streamAccessName, machineName) => (state) => {
    var _a;
    const index = (_a = state.epg) === null || _a === void 0 ? void 0 : _a.streams.findIndex(filterByChannelId(channelId, streamAccessName, machineName || ''));
    if (index > -1) {
        return {
            type: PlayerStoreActions.UPDATE_EPG_CHANNEL_INDEX,
            payload: index,
        };
    }
    else {
        return throwStoreError({
            channelId,
            streamAccessName,
        });
    }
};
export const setEpgData = (data) => () => {
    return data
        ? {
            type: PlayerStoreActions.SET_EPG,
            payload: mergeEvents(data.sections),
        }
        : setUnknownError();
};
export const nextScheduleIndex = () => ({
    type: PlayerStoreActions.NEXT_EPG_SCHEDULE_INDEX,
});
