import moment from 'moment-timezone';
import FavoritesApi from '../../../api/Favorites';
import { getMpid } from '../../../helpers';
import UserInteractionsStoreSingleton from '../index';
import { useRequest } from '../../../lib/useRequest';
import { UserInteractionsRequestConfig } from './request';
export const UserInteractionsStoreActions = Object.freeze({
    SET_USER_INTERACTIONS: 'setUserInteractions',
    SET_WATCHES: 'setWatches',
    SET_WATCH: 'setWatch',
    SET_LIVE_WATCHES: 'setLiveWatches',
    SET_FAVORITES: 'setFavorites',
    SET_FAVORITE: 'setFavorite',
    REMOVE_FAVORITE: 'removeFavorite',
    CLEAR_STATE: 'clearState',
    ERROR: 'error',
    NO_CALL: 'noCall',
});
export const clearState = () => ({ type: UserInteractionsStoreActions.CLEAR_STATE });
const setError = (errorCode) => ({
    type: UserInteractionsStoreActions.ERROR,
    payload: {
        type: errorCode,
        description: 'Unable to fetch data',
        code: errorCode,
    },
});
export const fetchUserInteractions = (forceCall = false) => () => {
    if (UserInteractionsStoreSingleton.lastCall && !forceCall)
        return Promise.resolve({ type: UserInteractionsStoreActions.NO_CALL, payload: {} });
    return useRequest(UserInteractionsRequestConfig())
        .fetch()
        .then((data) => {
        return {
            type: UserInteractionsStoreActions.SET_USER_INTERACTIONS,
            payload: data,
        };
    })
        .catch(setError);
};
export const setWatch = (userWatch) => ({
    type: UserInteractionsStoreActions.SET_WATCH,
    payload: userWatch,
});
export const setLiveWatches = (userLiveWatch) => ({
    type: UserInteractionsStoreActions.SET_LIVE_WATCHES,
    payload: userLiveWatch,
});
export const addFavorite = (userFavorite, dataType) => () => FavoritesApi.post({
    mpid: getMpid(),
    attributes: {
        created: moment().format(),
    },
    dataType,
    v4ID: userFavorite.titleId,
})
    .then((response) => {
    var _a;
    return {
        type: UserInteractionsStoreActions.SET_FAVORITE,
        payload: Object.assign(Object.assign({}, userFavorite), { id: (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.id }),
    };
})
    .catch(setError);
export const removeFavorite = (userFavorite) => () => {
    return FavoritesApi.delete(getMpid(), userFavorite === null || userFavorite === void 0 ? void 0 : userFavorite.id)
        .then(() => ({
        type: UserInteractionsStoreActions.REMOVE_FAVORITE,
        payload: userFavorite === null || userFavorite === void 0 ? void 0 : userFavorite.titleId,
    }))
        .catch(setError);
};
