const isMaybeState = (input) => typeof input === 'object' && 'state' in input;
class Row {
    constructor(left, center, right) {
        this.value = [];
        this._center = 0;
        this.value = [...left, center, ...right];
        this._center = left.length;
    }
    get center() {
        return this.value[this._center];
    }
    get centerIndex() {
        return this._center;
    }
    get length() {
        return this.value.length;
    }
    getIndexMap(row) {
        return this.value.reduce((acc, item, index) => {
            if (item.length) {
                const key = Array.isArray(item) ? item[0] : isMaybeState(item) ? item.state : item;
                acc[key] = [row, index];
            }
            return acc;
        }, {});
    }
}
export class KeyMapState {
    constructor(ctx, rows) {
        this._ctx = ctx;
        this._rows = rows;
        this._createIndexMap();
    }
    get currentRow() {
        return typeof this._rowIndex === 'number' ? this._rows[this._rowIndex] : undefined;
    }
    _createIndexMap() {
        // Creating an index map of the state positions
        // so we avoid recalculating at focus
        this._indexMap = this._rows.reduce((acc, row, index) => (Object.assign(Object.assign({}, acc), row.getIndexMap(index))), {});
    }
    _updateIndexes() {
        const current = this._ctx._getState();
        const data = this._indexMap[current];
        if (data) {
            this._rowIndex = data[0];
            this._itemIndex = data[1];
        }
    }
    _check(state) {
        if (!state)
            return false;
        let result = false;
        if (Array.isArray(state)) {
            for (let i = 0, n = state.length; i < n; i++) {
                const temp = this._check(state[i]);
                if (temp) {
                    result = temp;
                    break;
                }
            }
        }
        else if (typeof state === 'string') {
            const component = this._ctx.tag(state);
            // It doesn't make sense to change the state to another one in which there is no element to focus on, or it is hidden.
            if (component && component.visible && component.active) {
                result = state;
            }
        }
        else if (isMaybeState(state) && state.if()) {
            result = state.state;
        }
        if (result)
            this._ctx._setState(result);
        return result;
    }
    _checkRowIndex() {
        if (this._rowIndex === undefined)
            this._updateIndexes();
    }
    _loop(direction, horizontal) {
        var _a;
        this._checkRowIndex();
        const target = horizontal ? (_a = this.currentRow) === null || _a === void 0 ? void 0 : _a.value : this._rows;
        if (!target)
            return;
        const start = ((horizontal ? this._itemIndex : this._rowIndex) || 0) + direction;
        const end = direction === -1 ? 0 : (target === null || target === void 0 ? void 0 : target.length) || 0;
        const check = (i) => { var _a; return this._check(horizontal ? target === null || target === void 0 ? void 0 : target[i] : (_a = target === null || target === void 0 ? void 0 : target[i]) === null || _a === void 0 ? void 0 : _a.center); };
        const setIndex = (index) => {
            var _a;
            if (!horizontal)
                this._rowIndex = index;
            this._itemIndex = (!horizontal ? (_a = this.currentRow) === null || _a === void 0 ? void 0 : _a.centerIndex : index) || 0;
        };
        for (let i = start, n = end; direction === 1 ? i < n : i >= n; direction === 1 ? i++ : i--) {
            if (check(i))
                return setIndex(i);
        }
    }
    reset() {
        this._rowIndex = undefined;
        this._itemIndex = undefined;
    }
    insertAt(index, row) {
        let insertedAt = index;
        if (index >= 0 && this._rows.length >= index) {
            this._rows.splice(index, 0, new Row(...row));
        }
        else {
            insertedAt = this._rows.push(new Row(...row)) - 1;
        }
        this._createIndexMap();
        return insertedAt;
    }
    removeAt(index) {
        this._rows.splice(index, 1);
        this._createIndexMap();
        this.reset();
    }
    left() {
        this._loop(-1, true);
    }
    right() {
        this._loop(1, true);
    }
    up() {
        this._loop(-1, false);
    }
    down() {
        this._loop(1, false);
    }
}
export const KeyMap = (ctx, rows) => new KeyMapState(ctx, rows.map(([left, center, right]) => new Row(left, center, right)));
