import getSlideAttributes, { getSlideType } from './getSlideAttributes';
import { ALGOLIA_ENTITY_TYPES, getIsOlympics, getTileState, setDefaultValue, setSLEDefaultParams, } from '../../../../helpers';
import { CONTENT_TYPE, CUSTOM_SHELF_TYPE, ENTITLEMENT, ENTITY_TYPES, MPARTICLE_DEFAULT_ATTR_VALUE, OLYMPICS_FALLBACK_VALUE, PROGRAMMING_TYPES, } from '../../../../constants';
const getSmartAttributes = (analytics) => {
    const isSmartTile = !!analytics.smartTile;
    const smartTile = analytics.smartTile || {};
    return {
        'Smart Tile Title': smartTile.smartTileLabel,
        'Smart Tile Episode Title': isSmartTile && analytics.title,
        'Smart Tile Video ID': isSmartTile && analytics.mpxGuid,
        'Smart Tile Scenario': smartTile.smartTileScenario,
        'Smart Tile Logic': smartTile.smartTileLogic,
    };
};
const getBrand = (analytics) => {
    var _a, _b, _c, _d, _e;
    return ((_a = analytics.brand) === null || _a === void 0 ? void 0 : _a.title) ||
        (analytics === null || analytics === void 0 ? void 0 : analytics.brandDisplayTitle) ||
        ((_c = (_b = analytics.referrer) === null || _b === void 0 ? void 0 : _b.routes) === null || _c === void 0 ? void 0 : _c.brand) ||
        ((_e = (_d = analytics.data) === null || _d === void 0 ? void 0 : _d.brand) === null || _e === void 0 ? void 0 : _e.title) ||
        MPARTICLE_DEFAULT_ATTR_VALUE;
};
const getNetworkAttributes = (params) => {
    var _a, _b;
    const brand = (_b = (_a = params.analytics) === null || _a === void 0 ? void 0 : _a.brand) === null || _b === void 0 ? void 0 : _b.title;
    return {
        'Item Clicked Name': brand,
        'Item Clicked Type': 'Brand',
        'Item Clicked Brand': brand,
    };
};
const getTileAttributes = (entityType, analytics, shelf, tileParams) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8;
    const searchResultType = (_a = analytics.algoliaProperties) === null || _a === void 0 ? void 0 : _a.entityType;
    switch (entityType) {
        case ENTITY_TYPES.SLIDE:
        case ENTITY_TYPES.UPCOMING_LIVE_SLIDE:
            return getSlideAttributes(analytics, shelf, tileParams);
        case ENTITY_TYPES.MARKETING_MODULE:
            return {
                'Custom Shelf Title': shelf.listTitle,
                'Custom Shelf Type': shelf.customShelfType || CUSTOM_SHELF_TYPE.regular,
                'Item Clicked Playlist Name': analytics.playlistTitle,
                'Item Clicked Season': analytics.seasonNumber,
                'Item Clicked Show': analytics.series,
                'Item Clicked Sport': analytics.sport,
                'Item Clicked State': getTileState(analytics),
                'Item Clicked Type': getSlideType(analytics),
                'Item Clicked Brand': analytics.brand,
                'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
                'Item Clicked League': analytics.league,
                'Item Clicked Name': analytics.itemClickedName,
                'Item Clicked Video ID': analytics.mpxGuid || analytics.pid,
                'Shelf Machine Name': shelf.machineName,
            };
        case ENTITY_TYPES.SERIES:
        case ENTITY_TYPES.SHOWS:
            return {
                'Item Clicked Name': analytics.series,
                'Item Clicked Type': 'Show',
                'Item Clicked Show': analytics.series,
                'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Brand': (_b = analytics.brand) === null || _b === void 0 ? void 0 : _b.title,
                'Custom Shelf Title': shelf.listTitle,
                'Custom Shelf Type': analytics.shelfType || 'Regular',
                'Shelf Machine Name': shelf.machineName,
            };
        case ENTITY_TYPES.VIDEOS:
        case ENTITY_TYPES.UPCOMING_LIVE_TILE:
        case ENTITY_TYPES.REPLAY:
        case ENTITY_TYPES.VIDEO: {
            const isOlympicsUpcoming = entityType === ENTITY_TYPES.UPCOMING_LIVE_TILE && analytics.isOlympics;
            const playlistAttributes = analytics.playlistMachineName || analytics.treatment === 'playlist'
                ? {
                    'Item Clicked Playlist Name': analytics.playlistMachineName || shelf.listTitle,
                    'Item Clicked Playlist Position': shelf.position,
                }
                : {};
            if (!tileParams.programmingType && analytics.programmingType === PROGRAMMING_TYPES.FER)
                tileParams.programmingType = PROGRAMMING_TYPES.FER;
            return Object.assign(Object.assign({ 'Item Clicked Name': isOlympicsUpcoming ? analytics.secondaryTitle : analytics.title, 'Item Clicked Type': analytics.programmingType ||
                    analytics.programType ||
                    (isOlympicsUpcoming && PROGRAMMING_TYPES.SLE), 'Item Clicked Show': analytics.series || (isOlympicsUpcoming && OLYMPICS_FALLBACK_VALUE), 'Item Clicked Season': analytics.seasonNumber, 'Item Clicked Video ID': analytics.mpxGuid || analytics.pid, 'Item Clicked Entitlement': analytics.programmingType === PROGRAMMING_TYPES.FER
                    ? analytics.liveEntitlement
                    : analytics.locked
                        ? ENTITLEMENT.ENTITLED
                        : ENTITLEMENT.FREE, 'Item Clicked Brand': (_c = analytics.brand) === null || _c === void 0 ? void 0 : _c.title, 'Custom Shelf Title': shelf.listTitle, 'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE, 'Custom Shelf Type': analytics.shelfType || 'Regular', 'Shelf Machine Name': shelf.machineName, 'Item Clicked Sport': analytics.sport, 'Item Clicked League': analytics.league, 'Item Clicked State': getTileState(tileParams), 'Dynamic Lead Logic': MPARTICLE_DEFAULT_ATTR_VALUE }, (isOlympicsUpcoming ? { Show: OLYMPICS_FALLBACK_VALUE } : {})), playlistAttributes);
        }
        case ENTITY_TYPES.MOVIE: {
            const movieName = analytics.movie;
            return {
                'Item Clicked Name': movieName,
                'Item Clicked Type': 'Movie Home',
                'Item Clicked Show': movieName,
                'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Brand': (_d = analytics.brand) === null || _d === void 0 ? void 0 : _d.title,
                'Custom Shelf Title': shelf.listTitle,
                'Custom Shelf Type': analytics.shelfType || 'Regular',
                'Shelf Machine Name': shelf.machineName,
            };
        }
        case ENTITY_TYPES.ON_AIR: {
            const onAirBrand = (_e = analytics.brand) === null || _e === void 0 ? void 0 : _e.title;
            return {
                'Item Clicked Name': analytics.episodeTitle,
                'Item Clicked Type': 'Live',
                'Item Clicked Show': analytics.programTitle,
                'Item Clicked Season': analytics.seasonNumber,
                'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Entitlement': analytics.liveEntitlement,
                'Item Clicked Brand': onAirBrand,
                'Item Clicked Sport': analytics.sport,
                'Item Clicked League': analytics.league,
                'Item Clicked State': 'Live',
                'Item Clicked Playlist Name': analytics.isPlaylist && analytics.playlistMachineName,
                'Dynamic Lead Logic': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Custom Shelf Title': shelf.listTitle,
                'Custom Shelf Type': analytics.shelfType || 'Regular',
                'Smart Tile Logic': analytics.smartTileLogic,
                'Shelf Machine Name': shelf.machineName,
                Brand: onAirBrand,
            };
        }
        case ENTITY_TYPES.SMART_TILES:
        case ENTITY_TYPES.CTA_SMART_TILES:
            return {
                'Item Clicked Name': analytics.title,
                'Item Clicked Type': analytics.programmingType,
                'Item Clicked Show': analytics.series,
                'Item Clicked Season': analytics.seasonNumber,
                'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
                'Item Clicked Brand': (_f = analytics.brand) === null || _f === void 0 ? void 0 : _f.title,
                'Custom Shelf Title': 'Smart Tile',
                'Shelf Machine Name': shelf === null || shelf === void 0 ? void 0 : shelf.machineName,
                'Item Clicked Video ID': analytics.mpxGuid,
                'Custom Shelf Type': analytics.shelfType || 'Regular',
                'Item Clicked Sport': analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked League': analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked State': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Content Position': shelf.position || shelf.tileIndex,
                'Custom Shelf Position': shelf.customPosition || shelf.shelfIndex,
            };
        case ENTITY_TYPES.SEARCH_RESULTS: {
            const commonAttributes = {
                'Custom Shelf Title': 'Search Results',
                'Shelf Machine Name': shelf.machineName,
                Show: ((_h = (_g = analytics.data) === null || _g === void 0 ? void 0 : _g.series) === null || _h === void 0 ? void 0 : _h.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Show': ((_k = (_j = analytics.data) === null || _j === void 0 ? void 0 : _j.series) === null || _k === void 0 ? void 0 : _k.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Name': (_l = analytics.data) === null || _l === void 0 ? void 0 : _l.title,
                'Item Clicked Brand': (_o = (_m = analytics.data) === null || _m === void 0 ? void 0 : _m.brand) === null || _o === void 0 ? void 0 : _o.title,
                'Item Clicked Sport': ((_q = (_p = analytics.data) === null || _p === void 0 ? void 0 : _p.sport) === null || _q === void 0 ? void 0 : _q.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked League': ((_s = (_r = analytics.data) === null || _r === void 0 ? void 0 : _r.league) === null || _s === void 0 ? void 0 : _s.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked State': getTileState(analytics.data) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Season': ((_u = (_t = analytics.data) === null || _t === void 0 ? void 0 : _t.season) === null || _u === void 0 ? void 0 : _u.seasonNumber) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Video ID': ((_v = analytics.video) === null || _v === void 0 ? void 0 : _v.mpxGuid) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Entitlement': ((_w = analytics.data) === null || _w === void 0 ? void 0 : _w.locked)
                    ? ENTITLEMENT.ENTITLED
                    : ENTITLEMENT.FREE,
                'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Custom Shelf Type': MPARTICLE_DEFAULT_ATTR_VALUE,
            };
            if (searchResultType === ALGOLIA_ENTITY_TYPES.SEGMENTS ||
                searchResultType === ALGOLIA_ENTITY_TYPES.EPISODES) {
                const entitled = (_y = (_x = analytics.video) === null || _x === void 0 ? void 0 : _x.authAccess) === null || _y === void 0 ? void 0 : _y.ConnectedTvDevice;
                return Object.assign(Object.assign({}, commonAttributes), { 'Item Clicked Type': ((_z = analytics.video) === null || _z === void 0 ? void 0 : _z.fullEpisode)
                        ? 'Full Episode'
                        : (_1 = (_0 = analytics.data) === null || _0 === void 0 ? void 0 : _0.programmingType) === null || _1 === void 0 ? void 0 : _1[0], 'Item Clicked Entitlement': entitled ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE });
            }
            else if (searchResultType === 'series') {
                return Object.assign(Object.assign({}, commonAttributes), { 'Item Clicked Type': 'Show', 'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE });
            }
            else if (searchResultType === ALGOLIA_ENTITY_TYPES.REPLAYS ||
                searchResultType === ALGOLIA_ENTITY_TYPES.SLE) {
                return Object.assign(Object.assign({}, commonAttributes), { 'Item Clicked Type': (_2 = analytics.data) === null || _2 === void 0 ? void 0 : _2.programmingType, 'Item Clicked Video ID': (_3 = analytics.data) === null || _3 === void 0 ? void 0 : _3.pid });
            }
            else if (searchResultType === ALGOLIA_ENTITY_TYPES.SPORTS_VIDEOS) {
                return Object.assign(Object.assign({}, commonAttributes), { 'Item Clicked Name': (_4 = analytics.data) === null || _4 === void 0 ? void 0 : _4.subTitle, 'Item Clicked Type': (_5 = analytics.data) === null || _5 === void 0 ? void 0 : _5.programmingType });
            }
            return {};
        }
        case ENTITY_TYPES.RECOMMENDED: {
            const seriesName = analytics.series;
            return {
                'Item Clicked Name': seriesName,
                'Item Clicked Type': 'Show',
                'Item Clicked Show': seriesName,
                'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Entitlement': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Brand': (_6 = analytics.brand) === null || _6 === void 0 ? void 0 : _6.title,
                'Custom Shelf Title': shelf.listTitle,
                'Shelf Machine Name': shelf.machineName,
            };
        }
        case ENTITY_TYPES.NETWORK:
            return Object.assign(Object.assign({}, getNetworkAttributes(analytics)), { 'Custom Shelf Title': 'All Brands', 'Shelf Machine Name': shelf.machineName, Brand: MPARTICLE_DEFAULT_ATTR_VALUE });
        case ENTITY_TYPES.BRAND:
            return Object.assign(Object.assign({}, getNetworkAttributes(analytics)), { 'Custom Shelf Title': shelf.listTitle, 'Shelf Machine Name': shelf.machineName, 'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE });
        case ENTITY_TYPES.STREAM:
            return {
                'Item Clicked Show': analytics.programTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Season': analytics.seasonNumber || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Video ID': analytics.tmsId || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
                'Item Clicked Name': analytics.videoTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Type': analytics.contentType === CONTENT_TYPE.SLE ? 'Single Live Event' : 'Live',
                'Item Clicked State': 'Live',
                'Item Clicked Brand': ((_7 = analytics.brand) === null || _7 === void 0 ? void 0 : _7.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Sport': analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked League': analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Custom Shelf Title': analytics.customShelfTitle || 'On Now',
                'Shelf Machine Name': shelf.machineName || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Custom Shelf Type': analytics.shelfType || 'Regular',
                'Item Clicked Playlist Name': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Smart Tile Logic': MPARTICLE_DEFAULT_ATTR_VALUE,
            };
        case ENTITY_TYPES.PLAYLIST:
            return {
                'Item Clicked Playlist Name': analytics.title,
                'Item Clicked Playlist Position': shelf.position,
                'Item Clicked Brand': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Show': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Season': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Video ID': MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Name': analytics.listTitle,
                'Item Clicked Entitlement': analytics.locked ? ENTITLEMENT.ENTITLED : ENTITLEMENT.FREE,
                'Custom Shelf Title': shelf.listTitle,
                'Shelf Machine Name': shelf.machineName,
                'Item Clicked Type': 'Playlist',
            };
        case ENTITY_TYPES.LIVE_TO_VOD:
            return {
                'Item Clicked Name': `LIVE to VOD: ${analytics.textVOD || ''}`,
                'Item Clicked Video ID': analytics.mpxGuid || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Brand': ((_8 = analytics.brand) === null || _8 === void 0 ? void 0 : _8.title) || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Show': analytics.programTitle || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Season': analytics.seasonNumber || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked Sport': analytics.sport || MPARTICLE_DEFAULT_ATTR_VALUE,
                'Item Clicked League': analytics.league || MPARTICLE_DEFAULT_ATTR_VALUE,
            };
        default:
            return {};
    }
};
export const getContentClickAttributes = (params) => {
    var _a, _b, _c;
    const entityType = (_a = params.entity) === null || _a === void 0 ? void 0 : _a.entityType;
    const analytics = ((_b = params.entity) === null || _b === void 0 ? void 0 : _b.analytics) || {};
    const shelf = params.shelf || {};
    const tileParams = params.tileParams || {};
    const show = analytics.series === 'None' ? analytics === null || analytics === void 0 ? void 0 : analytics.programTitle : analytics === null || analytics === void 0 ? void 0 : analytics.series;
    const isOlympics = getIsOlympics(analytics);
    let defaultValueParams = Object.assign(Object.assign(Object.assign({ Brand: getBrand(analytics), Show: isOlympics ? OLYMPICS_FALLBACK_VALUE : show, 'Content Position': shelf.position || shelf.tileIndex, 'Custom Shelf Position': shelf.customPosition || shelf.shelfIndex, Sponsor: 'sponsorName' in analytics ? analytics.sponsorName : '' }, getSmartAttributes(analytics)), (entityType ? getTileAttributes(entityType, analytics, shelf, tileParams) : {})), { 'Dynamic Lead Logic': ((_c = params === null || params === void 0 ? void 0 : params.entity) === null || _c === void 0 ? void 0 : _c.dynamicSmartLeadLogic) ||
            ('dynamicGenerationLogic' in analytics && (analytics === null || analytics === void 0 ? void 0 : analytics.dynamicGenerationLogic)) ||
            '', 'Item Clicked Url': MPARTICLE_DEFAULT_ATTR_VALUE });
    if (isOlympics) {
        defaultValueParams = Object.assign(Object.assign({}, defaultValueParams), { 'Item Clicked Show': OLYMPICS_FALLBACK_VALUE, 'Item Clicked League': OLYMPICS_FALLBACK_VALUE });
    }
    if (analytics.programmingType === PROGRAMMING_TYPES.SLE)
        defaultValueParams = setSLEDefaultParams(defaultValueParams);
    return setDefaultValue(defaultValueParams, MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getContentClickAttributes;
