export default class Cta {
    constructor(obj, listTitle) {
        this.data = Object.assign(Object.assign({}, obj === null || obj === void 0 ? void 0 : obj.data), { listTitle });
    }
    get destination() {
        return this.data.destination;
    }
    get destinationType() {
        return this.data.destinationType;
    }
    get title() {
        return this.data.title;
    }
    get listTitle() {
        return this.data.listTitle;
    }
    get qrModal() {
        var _a;
        return ((_a = this.data) === null || _a === void 0 ? void 0 : _a.qrModal) || {};
    }
}
