import { Lightning } from '@lightningjs/sdk';
const isBlocksArray = (input) => { var _a; return typeof ((_a = input === null || input === void 0 ? void 0 : input[0]) === null || _a === void 0 ? void 0 : _a[0]) === 'object'; };
export default class ShimmerLoader extends Lightning.Component {
    static _template() {
        return {
            Shimmer: {},
        };
    }
    _setup() {
        this._shimmer = this.tag('Shimmer');
        this._loader = this._shimmer.animation({
            duration: 2,
            repeat: -1,
            actions: [{ p: 'alpha', v: { 0: 0.8, 0.5: 0.4, 1: 0.8 } }],
        });
    }
    set item(blocks) {
        const getElement = (w, h, x, y) => ({
            mount: 0,
            x,
            y,
            w,
            h,
            alpha: 0.8,
            amount: 1,
            rect: true,
            shader: { type: Lightning.shaders.RoundedRectangle, fillColor: 0xff32343d, radius: 4 },
        });
        if (isBlocksArray(blocks)) {
            const gap = 2;
            let rowY = 0;
            let itemX = 0;
            this.tag('Shimmer').patch(blocks.reduce((acc, row, index) => (Object.assign(Object.assign({}, acc), { [`Row${index}`]: row.reduce((r, { w, h }, i) => {
                    r[`Item${i}`] = getElement(w, h, itemX, rowY);
                    if (i === row.length - 1) {
                        itemX = 0;
                        rowY += h + gap;
                    }
                    else {
                        itemX += w + gap;
                    }
                    return r;
                }, {}) })), {}));
        }
        else {
            this.tag('Shimmer').patch(getElement(blocks.w, blocks.h, 0, 0));
        }
    }
    _active() {
        this._loader.start();
    }
    _inactive() {
        this._loader.stop();
    }
}
