import Announce from './Announce';
export default class BaseAnnounce extends Announce {
    async speak() {
        return new Promise((resolve, reject) => {
            this._utterance = new SpeechSynthesisUtterance(this._phrase);
            this._utterance.onend = () => resolve();
            this._utterance.onerror = (e) => {
                switch (e.error) {
                    case 'interrupted':
                    case 'canceled':
                        // Cancel or interrupt error (ignore)
                        break;
                    default:
                        reject(e);
                }
            };
            window.speechSynthesis.speak(this._utterance);
        });
    }
}
