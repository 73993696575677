import getAuthAttributes from '../attributes/getAuthAttributes';
import { setDefaultValue, getAuthSSO } from '../../../../helpers';
import { MPARTICLE_DEFAULT_ATTR_VALUE } from '../../../../constants';
import AuthenticationSingleton from '../../../../authentication/Authentication';
const getExpiration = () => {
    var _a;
    const expiration = (_a = AuthenticationSingleton.getMvpdData()) === null || _a === void 0 ? void 0 : _a.expires;
    if (!expiration)
        return 'None';
    return new Date(parseInt(String(expiration), 10)).toISOString();
};
const getMvpdAuthSuccessAttributes = (params = {}) => {
    var _a;
    return setDefaultValue(Object.assign(Object.assign({}, getAuthAttributes(params)), { 'Geo Zip': (_a = params === null || params === void 0 ? void 0 : params.live) === null || _a === void 0 ? void 0 : _a.geoZip, 'MVPD Expiration Date': getExpiration(), 'Auth SSO': params.userProfile && getAuthSSO(params.userProfile), MVPD: AuthenticationSingleton.getMvpdDataMvpdId() }), MPARTICLE_DEFAULT_ATTR_VALUE);
};
export default getMvpdAuthSuccessAttributes;
