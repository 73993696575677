import qs from 'qs';
import isEmpty from 'lodash/isEmpty';
import AppConfigFactorySingleton from '../config/AppConfigFactory';
import { isSamsung } from '../helpers';
import TVPlatform from '../lib/tv-platform';
import { ErrorType } from '../lib/tv-platform/types';
// TODO: Migrate TIMEOUT to Config
const TIMEOUT = 60000;
export const VERSION = 'v4.32.0';
export const USERS_VERSION = 'v3.15';
const compareAlpha = { sort: (a, b) => a.localeCompare(b) };
const parkAPI = {};
const getParkRequestor = () => (isSamsung() ? 'nbcAppSamsung' : 'nbcAppVizio');
parkAPI.getUrl = (resource, params, version) => {
    if (!parkAPI.apiServiceUrl) {
        parkAPI.apiServiceUrl = AppConfigFactorySingleton.config.api_service_url;
    }
    const query = isEmpty(params) ? '' : `?${qs.stringify(params, compareAlpha)}`;
    return `${parkAPI.apiServiceUrl}/${version}/${resource}${query}`;
};
parkAPI.post = (resourceName, method = 'POST', data = {}, options = {}) => {
    if (!data)
        console.warn('Data must be included for POST/PATCH requests.');
    const url = parkAPI.getUrl(resourceName, {}, (options === null || options === void 0 ? void 0 : options.version) || AppConfigFactorySingleton.config.ParkAPIVersion || VERSION);
    const headers = new Headers(Object.assign({ 'Content-Type': 'application/vnd.api+json', 'x-park-requestor': getParkRequestor() }, (method === 'PATCH' ? { 'X-HTTP-Method-Override': 'PATCH' } : {})));
    const timeout = options.timeout || TIMEOUT;
    return fetch(url, {
        timeout,
        headers,
        method,
        referrerPolicy: 'no-referrer',
        body: JSON.stringify(data),
    })
        .then((response) => response.json())
        .catch((err) => TVPlatform.reportError({
        type: ErrorType.NETWORK,
        description: 'PARK API fetch error',
        payload: err,
    }));
};
/**
 * ParkAPI GET method.
 *
 * @param {string} resourceName
 *   The relative path to fetch from the API.
 * @param {object} params
 *   GET arguments to send with the request.
 * @param {object} options
 *   Override options.
 *
 * @return {promise}
 *   Resolves when the request is fulfilled, rejects if there's an error.
 */
parkAPI.get = (resourceName, data = {}, options = {}) => {
    const url = parkAPI.getUrl(resourceName, data, options.version || VERSION);
    const headers = {
        accept: 'application/vnd.api+json' + ((data === null || data === void 0 ? void 0 : data.derivatives) ? '; ext="park/derivatives"' : ''),
        'x-park-requestor': getParkRequestor(),
    };
    return fetch(url, { timeout: options.timeout || TIMEOUT, headers })
        .then((response) => response.json())
        .catch((err) => Promise.reject(err));
};
export default parkAPI;
